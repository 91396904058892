import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useShipmentAddresses, useAgentTypes } from 'hooks'

import { DetailContainer } from 'containers'
import { AddressModal, ContactModal } from 'containers/Modals'
import { InputBS, TextareaBS, SelectField, FormTable } from 'components'

import { initialValues, schema, dataAdapter } from './constants'
import { RoleI, AgentTypesI } from 'interfaces'
import routes from 'app/routing/routes'

const AddShipmentAddress = () => {
  const { currentUser } = useAuth()

  const intl = useIntl()
  const submitRef = useRef(null)
  const navigate = useNavigate();

  const [addressModal, setAddressModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)

  const { loading, handleAddShipmentAddress } = useShipmentAddresses()

  const { handleGetAgentTypes } = useAgentTypes()
  const [agentTypes, setAgentTypes] = useState([])

  const handleSubmit = async (values) => {
    try {
      if (values.contact?.name === '') {
        toast.error('Falta definir un contacto para la dirección')
        return
      }

      const data = dataAdapter(values)

      const hasRestrictedRole = currentUser.roles.some(r => r.id == RoleI.VENTAS)
      const hasAllowedRole = currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR, RoleI.COMPRAS].includes(r.id))

      if (hasRestrictedRole && !hasAllowedRole && Boolean(data.has_traceability)) {
        toast.error(`Su rol no permite la creación de direcciones trazables`);
        return;
      }

      const response = await handleAddShipmentAddress(data)

      toast.success(response.data.message, { theme: "colored" })
      navigate(`${routes.SHIPMENT_ADDRESSES}/${response.data.id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getAgentTypes = useCallback(async () => {
    try {
      const response = await handleGetAgentTypes()

      let agents = response.result
      setAgentTypes(agents)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetAgentTypes])

  useEffect(() => {
    getAgentTypes()
  }, [getAgentTypes])

  return (
    <>
      <DetailContainer
        title={'Crear dirección de entrega'}
        buttons={
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <Button type='button' variant='secondary' onClick={()=>navigate(-1)} className='me-3'><i className="ki-duotone ki-left"/>Volver</Button>
            <Button type='button' variant='primary' onClick={() => submitRef.current.click()} disabled={loading}><i className="bi bi-save me-2" />Guardar</Button>
          </div>
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <div className='row my-8'>
                  <div className={`col-${currentUser.roles.find(r => r.id == RoleI.ADMIN) ? '10' : '12'}`}>
                    <InputBS id='name' name='name' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.NAME' })} />
                  </div>

                  {currentUser.roles.find(r => r.id == RoleI.ADMIN) &&
                    <div className='col-2'>
                      <SelectField
                        id='is_ours'
                        name='is_ours'
                        options={[{ value: true, label: 'SÍ' }, { value: false, label: 'NO' }]}
                        label={'Nuestra'}
                      />
                    </div>
                  }
                </div>

                <div className='separator my-8' />
                <div className='my-8'>
                  <div className='d-flex justify-content-between align-items-center mb-8'>
                    <label className='form-label m-0'>Datos de dirección</label>

                    <div>
                      <Button
                        className='BSButton'
                        variant='outline-primary'
                        onClick={() => setAddressModal(true)}
                      >
                        {values?.address?.route !== '' ? 'Editar dirección' : 'Añadir dirección'}
                      </Button>
                    </div>
                  </div>
                </div>

                <AddressModal
                  show={addressModal}
                  onClose={() => setAddressModal(false)}
                  values={values}
                  onSave={values => {
                    setFieldValue('address', values.address)
                    setAddressModal(false)
                  }}
                  submodal
                />

                {values?.address?.route !== '' &&
                  <FormTable
                    columns={[
                      { header: 'Calle' },
                      { header: 'CP' },
                      { header: 'Localidad' },
                      { header: 'Comuna / Partido' },
                      { header: 'Provincia' },
                      { header: '', styles: 'text-center' },
                    ]}
                  >
                    <tr>
                      <td className='align-middle'>{values.address?.route} {values.address?.street_number}{values.address?.floor !== '' ? ` (${values.address.floor})` : ''}</td>
                      <td className='align-middle'>{values.address?.postal_code}{values.address?.postal_code_suffix}</td>
                      <td className='align-middle'>{values.address?.locality}</td>
                      <td className='align-middle'>{values.address?.administrative_area_level_2}</td>
                      <td className='align-middle'>{values.address?.administrative_area_level_1}</td>
                      <td className='align-middle text-center'>
                        <button type='button' className='btn btn-icon' onClick={() => setAddressModal(true)}>
                          <i className='bi bi-pencil-square text-primary'></i>
                        </button>
                      </td>
                    </tr>
                  </FormTable>
                }

                <div className='separator my-8'></div>
                <h1 className='my-8'>Trazabilidad</h1>

                <div className='row my-8'>
                  <div className='col-2'>
                    <SelectField
                      id='has_traceability'
                      name='has_traceability'
                      options={[{ value: false, label: 'NO' }, { value: true, label: 'SÍ' }]}
                      label={'Trazabilidad'}
                    />
                  </div>

                  {Boolean(values.has_traceability) && (
                    <>
                      <div className='col-4'>
                        <SelectField
                          id='agent_type_id'
                          name='agent_type_id'
                          label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.TYPE' })}
                          options={agentTypes.map(item => { return { value: item.id, label: item.name }})}
                          placeholder='Seleccionar tipo de agente'
                        />
                      </div>

                      <div className='col'>
                        <InputBS id='gln' name='gln' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.HAS_TRACEABILITY.GLN' })} />
                      </div>

                      {/* <div className='col-2'>
                        <SelectField
                          id='integrates_verifarma'
                          name='integrates_verifarma'
                          options={[ { value: true, label: 'SÍ' }, { value: false, label: 'NO' }]}
                          label={'Verifarma'}
                        />
                      </div> */}
                    </>
                  )}
                </div>

                <div className='separator my-8' />
                <div className='my-8'>
                  <div className='d-flex justify-content-between align-items-center mb-8'>
                    <label className='form-label m-0'>Datos de contacto</label>

                    <div>
                      <Button className='BSButton' variant='outline-primary' type='button' onClick={() => setContactModal(true)} >{values.contact.name ? 'Editar datos de contacto' : 'Añadir datos de contacto'}</Button>
                    </div>
                  </div>
                </div>

                <ContactModal
                  show={contactModal}
                  onClose={() => setContactModal(false)}
                  values={values}
                  onSave={values => {
                    setFieldValue('contact', values.contact)
                    setContactModal(false)
                  }}
                  submodal
                />

                {values?.contact?.name !== '' &&
                  <FormTable
                    columns={[
                      { header: 'Nombre' },
                      { header: 'Email' },
                      { header: 'Notificaciones' },
                      { header: 'Teléfono' },
                      { header: 'Celular' },
                      { header: '', styles: 'text-center' },
                    ]}
                  >
                    <tr>
                      <td className='align-middle'>{values.contact?.name !== '' ? values.contact.name : '-'}</td>
                      <td className='align-middle'>{values.contact?.email !== '' ? values.contact.email : '-'}</td>
                      <td className='align-middle'>
                        {values.contact.has_notifications
                          ? <span className="badge badge-success">{intl.formatMessage({ id: 'OPTION.YES' })}</span>
                          : <span className="badge badge-danger">{intl.formatMessage({ id: 'OPTION.NO' })}</span>
                        }
                      </td>
                      <td className='align-middle'>{values.contact.phone && values.contact.phone !== '' ? values.contact.phone : '-'}</td>
                      <td className='align-middle'>{values.contact.mobile_suffix && values.contact.mobile_suffix !== '' ? `${values.contact.mobile_suffix} ` : ''}{values.contact.mobile !== '' ? values.contact.mobile : '-'}</td>
                      <td className='align-middle text-center'>
                        <button type='button' className='btn btn-icon' onClick={() => setContactModal(true)}>
                          <i className='bi bi-pencil-square text-primary'></i>
                        </button>
                      </td>
                    </tr>
                  </FormTable>
                }
                <div className='separator my-8' />

                <div className='row my-8'>
                  <div className='col-12'>
                    <TextareaBS id='observations' name='observations' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES.OBSERVATIONS' })} />
                  </div>
                </div>

                <div className='d-flex flex-row justify-content-end'>
                  <button ref={submitRef} type='submit' className='btn btn-primary' onClick={handleSubmit} disabled={loading}>Guardar</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddShipmentAddress