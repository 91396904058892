import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { usePedidos, useSalesOrders } from 'hooks'

import { useActions } from './services/actions'
import AuthModal from './AuthModal'

import { OutOrderStatusI, RoleI, SalesOrderStatusI, BillingTypesI, OrderTypeI, AutorizacionCreditoI } from 'interfaces'

import routes from 'app/routing/routes'
import { CancelarPedidoModal } from 'pages/Sells/common/components'

const MenuActions = ({ loading, pedido, obtenerPedido, serviceArticulos, serviceDocumentos, serviceOrdenes, serviceComprobantes, serviceComentarios }) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const [especial, setEspecial] = useState(false)
  const [parcial, setParcial] = useState(false)
  const [anticipada, setAnticipada] = useState(false)
  const [cancelarModal, setCancelarModal] = useState(false)

  const { handleUpdateSalesOrderStatus, handleCreateOutOrder } = useSalesOrders()

  const {
    cancelarPedido,
    autorizarRentabilidadPedido,
    autorizarCreditoPedido,
    noAutorizarPedido,
    crearOrdenEgreso,
    crearOrdenEgresoParcial,
    cancelacionEspecialPedido,
    cancelacionParcialPedido,
    imprimirPedido,
    clonarPedido,
    facturaAnticipadaPedido
  } = useActions()

  const { obtenerOrdenes } = serviceOrdenes

  const { handleObtenerDetallePedido, handleObtenerOrdenesEgresoPedido } = usePedidos()
  const [detalle, setDetalle] = useState([])
  const [ordenes, setOrdenes] = useState([])

  const obtenerDetallePedido = useCallback(async () => {
    if (pedido?.id) {
      try {
        const response = await handleObtenerDetallePedido(pedido.id)
        const data = response.data.result

        setDetalle(data)
        return data
      } catch (error) {
        setDetalle([])
        return []
      }
    }

    return []
  }, [pedido, handleObtenerDetallePedido])

  const obtenerOrdenesEgresoPedido = useCallback(async () => {
    if (pedido?.id) {
      try {
        const response = await handleObtenerOrdenesEgresoPedido(pedido.id)
        const data = response.data.result

        setOrdenes(data)
        return data
      } catch (error) {
        setOrdenes([])
        return []
      }
    }

    setOrdenes([])
    return []
  }, [pedido, handleObtenerOrdenesEgresoPedido])

  const [authModal, setAuthModal] = useState(false)

  const availableActions = () => {
    let actions = []

    if (
      pedido?.customer_supplier_id_autorizacion_credito !== AutorizacionCreditoI.DESHABILITADO_CREDITOS &&
      [SalesOrderStatusI.BORRADOR].includes(pedido.sales_order_status_id) && pedido.order_type_id != OrderTypeI.PEDIDO_EGRESO
    ) {
      actions.push({
        label: 'Enviar a autorizar',
        icon: 'bi bi-box-arrow-in-down-right text-success',
        action: async () => {
          try {
            const detalleData = await obtenerDetallePedido()

            if (detalleData.length > 0) {
              setAuthModal(true)
            } else {
              toast.warning(`El pedido #${pedido.id} no tiene artículos`)
            }
          } catch (error) {
            toast.error(error.message)
          }
        }
      })
    }

    if ([SalesOrderStatusI.BORRADOR].includes(pedido.sales_order_status_id) && pedido.order_type_id == OrderTypeI.PEDIDO_EGRESO) {
      actions.push({
        label: 'Enviar a Operaciones',
        icon: 'bi bi-box-arrow-in-down-right text-success',
        action: async () => {
          if (
            pedido.address_id_traza == '' ||
            !pedido.address_id_traza ||
            pedido.address_id_ship == '' ||
            !pedido.address_id_ship
          ) {
            toast.error('El pedido no tiene dirección cargada')
            return
          }

          if (Boolean(pedido?.to_patient) && !pedido?.patient_id) {
            toast.error('No se seleccionó el paciente en el pedido')
            return
          }

          if (Boolean(pedido?.remito_para_terceros) && !pedido?.id_tercero_para_remitir) {
            toast.error('No se seleccionó un tercero para remitir en el pedido')
            return
          }

          try {
            const detalleData = await obtenerDetallePedido()

            if (detalleData.length > 0) {
              const resp = await Swal.fire({
                title: 'Advertencia',
                html: `Enviar el <strong>Pedido #${pedido.id}</strong> a Operaciones?`,
                icon: 'warning',
                showDenyButton: true,
                denyButtonText: 'No, cancelar',
                confirmButtonText: 'Sí, enviar',
                customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
                reverseButtons: true,
              })

              if (resp.isConfirmed) {
                await handleUpdateSalesOrderStatus(pedido?.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA)
                await handleUpdateSalesOrderStatus(pedido?.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
                const response = await handleCreateOutOrder(pedido?.id)

                toast.success(`Orden de egreso #${response.data.id} generada correctamente`)
                navigate(routes.VENTAS_PEDIDOS)
              }
            } else {
              toast.warning(`El pedido #${pedido.id} no tiene artículos`)
            }
          } catch (error) {
            // Nada
          }
        }
      })
    }

    if ([
      SalesOrderStatusI.BORRADOR,
      SalesOrderStatusI.NO_AUTORIZADO,
      SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA,
      SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO,
      SalesOrderStatusI.AUTORIZADO,
    ].includes(pedido.sales_order_status_id)) {
      actions.push({
        label: 'Cancelar',
        icon: 'bi bi-trash text-danger',
        action: () => {
          setEspecial(false)
          setParcial(false)
          setAnticipada(false)
          setCancelarModal(true)
        }
      })
    }

    if ([SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA].includes(pedido.sales_order_status_id)) {
      let allowedRoles = [RoleI.ADMIN, RoleI.CONTROL_RENTAS]

      if (currentUser.roles.some(r => allowedRoles.includes(r.id))) {
        actions.push({
          label: 'Autorizar rentabilidad',
          icon: 'bi bi-check-all text-success',
          action: async () => {
            await autorizarRentabilidadPedido(pedido.id)

            if (
              pedido.customer_supplier_id_autorizacion_credito == AutorizacionCreditoI.AUTORIZADO_SIEMPRE ||
              (
                pedido.customer_supplier_id_autorizacion_credito == AutorizacionCreditoI.AUTOMATICA_CONDICION_PAGO &&
                pedido.payment_condition_id == pedido.customer_supplier_payment_condition_id
              )
            ) {
              await handleCreateOutOrder(pedido.id)
            }

            await obtenerPedido()
          }
        })

        actions.push({
          label: 'No autorizar',
          icon: 'bi bi-x-circle text-danger',
          action: async () => {
            await noAutorizarPedido(pedido.id, true)
            await obtenerPedido()
          }
        })
      }
    }

    if ([SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(pedido.sales_order_status_id)) {
      let allowedRoles = [RoleI.ADMIN, RoleI.CONTROL_CRED]

      if (currentUser.roles.some(r => allowedRoles.includes(r.id))) {
        if (pedido.customer_supplier_id_autorizacion_credito !== AutorizacionCreditoI.DESHABILITADO_CREDITOS) {
          actions.push({
            label: 'Autorizar crédito',
            icon: 'bi bi-check-all text-success',
            action: async () => {
              await autorizarCreditoPedido(pedido.id)
              await obtenerPedido()
            }
          })
        }

        actions.push({
          label: 'No autorizar',
          icon: 'bi bi-x-circle text-danger',
          action: async () => {
            await noAutorizarPedido(pedido.id, false)
            await obtenerPedido()
          }
        })
      }
    }

    if ([SalesOrderStatusI.AUTORIZADO].includes(pedido.sales_order_status_id)) {
      actions.push({
        label: 'Crear orden de egreso',
        icon: 'bi bi-box-arrow-up-right text-success',
        action: async () => {
          await crearOrdenEgreso(pedido.id, pedido)
          await obtenerPedido()
        }
      })
    }

    if (
      [
        SalesOrderStatusI.FALTA_STOCK,
        SalesOrderStatusI.RESERVA_PARCIAL,
        SalesOrderStatusI.EN_REVISION,
        SalesOrderStatusI.COORDINACION_ENTREGA,
        SalesOrderStatusI.PENDIENTE_PREPARACION,
      ].includes(pedido.sales_order_status_id)
    ) {
      if (pedido.sales_order_status_id !== SalesOrderStatusI.EN_REVISION) {
        actions.push({
          label: 'Crear orden de egreso parcial',
          icon: 'bi bi-box-arrow-up-right text-success',
          action: async () => {
            await crearOrdenEgresoParcial(pedido.id, pedido)
            await obtenerPedido()
          }
        })
      }

      if (ordenes && ordenes.filter(o => o.out_order_status_id !== OutOrderStatusI.CANCELADO).length == 1) { // Cant. órdenes de egreso == 1
        actions.push({
          label: 'Cancelar',
          icon: 'bi bi-x-circle text-danger',
          action: () => {
            setEspecial(true)
            setParcial(false)
            setAnticipada(pedido?.billing_type_id == BillingTypesI.ANTICIPADA)
            setCancelarModal(true)

            // Viejo:
            // await cancelacionEspecialPedido(pedido.id, pedido?.billing_type_id == BillingTypesI.ANTICIPADA)
            // await obtenerPedido()
            // await obtenerOrdenes({ sales_order_id: pedido.id })
          }
        })
      }

      if (ordenes && ordenes.filter(o => o.out_order_status_id !== OutOrderStatusI.CANCELADO).length > 1) { // Cant. órdenes de egreso > 1
        actions.push({
          label: 'Cancelar parcialmente',
          icon: 'bi bi-x-circle text-danger',
          action: () => {
            setEspecial(false)
            setParcial(true)
            setAnticipada(pedido?.billing_type_id == BillingTypesI.ANTICIPADA)
            setCancelarModal(true)

            // Viejo:
            // await cancelacionParcialPedido(pedido.id, pedido?.billing_type_id == BillingTypesI.ANTICIPADA)
            // await obtenerPedido()
            // await obtenerOrdenes({ sales_order_id: pedido.id })
          }
        })
      }
    }

    if (
      currentUser.roles?.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.OPERACIONES_ADMIN) &&
      pedido?.billing_type_id == BillingTypesI.ANTICIPADA &&
      ![
        SalesOrderStatusI.BORRADOR,
        SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA,
        SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO,
        SalesOrderStatusI.CANCELADO,
        SalesOrderStatusI.NO_AUTORIZADO,
      ].includes(pedido.sales_order_status_id)
    ) {
      actions.push({
        label: 'Facturar',
        icon: 'bi bi-receipt text-primary',
        action: async () => {
          try {
            await facturaAnticipadaPedido(pedido.id)
            obtenerPedido()
          } catch (error) {
            // Ya se encarga la función
          }
        }
      })
    }

    actions.push({
      label: 'Descargar',
      icon: 'bi bi-download text-primary',
      action: async () => {
        try {
          const detalleData = await obtenerDetallePedido()

          if (detalleData.length > 0) {
            await imprimirPedido(pedido.id)
          } else {
            toast.info('El pedido no tiene artículos cargados')
          }
        } catch (error) {
          toast.error(error.message)
        } finally {
          await obtenerPedido()
        }
      }
    })

    actions.push({
      label: 'Clonar',
      icon: 'bi bi-clipboard-check',
      action: async () => {
        await clonarPedido(pedido.id)
        await obtenerPedido()
      }
    })

    return actions
  }

  useEffect(() => {
    obtenerOrdenesEgresoPedido()
  }, [obtenerOrdenesEgresoPedido])

  if (availableActions().length == 0) return null

  return (
    <>
      <DropdownButton title='Acciones' disabled={loading}>
        {availableActions().map((a, _idx) => (
          <Dropdown.Item key={_idx} onClick={() => a.action()}>
            <i className={`${a.icon} pe-3`} />
            {a.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>

      <AuthModal show={authModal} onClose={() => setAuthModal(false)} pedido={pedido} obtenerPedido={obtenerPedido} />

      <CancelarPedidoModal
        show={cancelarModal}
        onHide={async () => {
          setEspecial(false)
          setParcial(false)
          setAnticipada(false)
          setCancelarModal(false)
          await obtenerPedido()

          if (especial || parcial) {
            await obtenerOrdenes({ sales_order_id: pedido.id })
          }
        }}
        idPedido={pedido.id}
        especial={especial}
        parcial={parcial}
        anticipada={anticipada}
      />
    </>
  )
}

const Actions = ({ loading, pedido, editar = false, setEditar, submitRef, cancelRef, obtenerPedido, serviceArticulos, serviceDocumentos, serviceOrdenes, serviceComprobantes, serviceComentarios }) => {
  const navigate = useNavigate()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <Button type='button' variant='secondary' onClick={() => navigate(-1)} className='btn btn-icon btn-icon-secondary me-3' disabled={loading}>
        <i className='bi bi-chevron-left text-gray-900' />
      </Button>

      {editar
        ?
          <>
            <Button type='button' variant='secondary' onClick={() => cancelRef.current.click()} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cancelar
            </Button>

            <Button type='button' variant='primary' onClick={() => submitRef.current.click()} disabled={loading}>
              <i className='bi bi-save me-1' />
              Guardar
            </Button>
          </>
        :
          <>
            {[SalesOrderStatusI.BORRADOR, SalesOrderStatusI.NO_AUTORIZADO].includes(pedido?.sales_order_status_id) &&
              <Button type='button' onClick={() => setEditar(true)} className='me-3 btn btn-outline btn-outline-primary'>
                <i className='bi bi-pencil text-primary me-1' />
                Editar
              </Button>
            }

            <MenuActions
              loading={loading}
              pedido={pedido}
              obtenerPedido={obtenerPedido}
              serviceArticulos={serviceArticulos}
              serviceDocumentos={serviceDocumentos}
              serviceOrdenes={serviceOrdenes}
              serviceComprobantes={serviceComprobantes}
              serviceComentarios={serviceComentarios}
            />
          </>
      }

    </div>
  )
}

export default Actions