import { useCallback, useState } from 'react'

import { Pedidos } from 'services'

const usePedidos = () => {
  const [loading, setLoading] = useState(false)

  const handleObtenerPedidos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerPedidos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerPedido = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerPedido(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerDetallePedido = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerDetallePedido(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerPedidoVenta = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerPedidoVenta(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerPedidoAlmacenamientoPropio = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerPedidoAlmacenamientoPropio(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCrearPedido = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Pedidos.crearPedido(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleActualizarPedido = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Pedidos.actualizarPedido(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleActualizarOrdenCompraPedido = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Pedidos.actualizarOrdenCompraPedido(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  /* EVENTOS */
  const handleObtenerEventosPedido = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerEventosPedido(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  /* EVENTOS */

  /* DOCUMENTOS */
  const handleObtenerDocumentosPedido = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerDocumentosPedido(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerDocumentoPedido = useCallback(async (id, fileId) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerDocumentoPedido(id, fileId)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCrearDocumentoPedido = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Pedidos.crearDocumentoPedido(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleEliminarDocumentoPedido = useCallback(async (id, fileId) => {
    try {
      setLoading(true)
      const response = await Pedidos.eliminarDocumentoPedido(id, fileId)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  /* DOCUMENTOS */

  /* ÓRDENES */
  const handleObtenerOrdenesEgresoPedido = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerOrdenesEgresoPedido(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  /* PENDIENTES */
  const handleObtenerPendientesPedido = useCallback(async (id) => {
    setLoading(true)

    try {
      const response = await Pedidos.obtenerPendientesPedido(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  /* PENDIENTES */

  /* COMENTARIOS */
  const handleObtenerComentariosPedido = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerComentariosPedido(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCrearComentarioPedido = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await Pedidos.crearComentarioPedido(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  /* COMENTARIOS */

  /* ACCIONES */
  const handleFacturaAnticipadaPedido = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Pedidos.facturaAnticipadaPedido(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  /* MOTIVOS DE CANCELACION */
  const handleObtenerMotivosCancelacion = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Pedidos.obtenerMotivosCancelacion(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleObtenerPedidos,
    handleObtenerPedido,
    handleObtenerDetallePedido,
    handleObtenerPedidoVenta,
    handleObtenerPedidoAlmacenamientoPropio,
    handleCrearPedido,
    handleActualizarPedido,
    handleActualizarOrdenCompraPedido,
    /* EVENTOS */
    handleObtenerEventosPedido,
    /* DOCUMENTOS */
    handleObtenerDocumentosPedido,
    handleObtenerDocumentoPedido,
    handleCrearDocumentoPedido,
    handleEliminarDocumentoPedido,
    /* ÓRDENES */
    handleObtenerOrdenesEgresoPedido,
    /* PENDIENTES */
    handleObtenerPendientesPedido,
    /* COMENTARIOS */
    handleObtenerComentariosPedido,
    handleCrearComentarioPedido,
    /* ACCIONES */
    handleFacturaAnticipadaPedido,
    /* MOTIVOS DE CANCELACION */
    handleObtenerMotivosCancelacion
  }
}

export default usePedidos