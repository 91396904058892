import { useState, useCallback } from 'react';
import { CustomerSuppliers } from 'services';

const useCustomerSuppliers = () => {
  const [loading, setLoading] = useState(false);

  const handleGetCustomerSuppliers = useCallback(async (token, query, params) => {
    try {
      setLoading(true);

      if (!query) query = ''
      const response = await CustomerSuppliers.getCustomerSuppliers(token, query, params);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetCustomerSupplier = useCallback(async (id, token) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.getCustomerSupplier(id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateCustomerSupplier = useCallback(async (data, token) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.createCustomerSupplier(data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateCustomerSupplier = useCallback(async (id, data, token) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.updateCustomerSupplier(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetCustomerSupplierShipmentAddresses = useCallback(async (id, params, token) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierShipmentAddresses(id, params, token)

      return response
    } catch (error) {
      throw error;
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCustomerSupplierDetailShipmentAddresses = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierDetailShipmentAddresses(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleLinkCustomerSupplierDetailShipmentAddressToVerifarma = useCallback(async (id, shipment_address_id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.linkCustomerSupplierDetailShipmentAddressToVerifarma(id, shipment_address_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUnlinkCustomerSupplierDetailShipmentAddressFromVerifarma = useCallback(async (id, shipment_address_id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.unlinkCustomerSupplierDetailShipmentAddressFromVerifarma(id, shipment_address_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddCustomerSupplierShipmentAddress = useCallback(async (id, data, token) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.addCustomerSupplierShipmentAddress(id, data, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleLinkShipmentAddressToCustomerSupplier = useCallback(async (id, shipment_address_id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.linkShipmentAddressToCustomerSupplier(id, shipment_address_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateCustomerSupplierShipmentAddress = useCallback(async (id, shipment_address_id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.updateCustomerSupplierShipmentAddress(id, shipment_address_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteCustomerSupplierShipmentAddress = useCallback(async (id, shipment_address_id) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.deleteCustomerSupplierShipmentAddress(id, shipment_address_id);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetCustomerSupplierPatients = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierPatients(id, params)

      return response;
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddCustomerSupplierPatient = useCallback(async (id, patient_id, token) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.addCustomerSupplierPatient(id, patient_id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteCustomerSupplierPatient = useCallback(async (id, patient_id, token) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.deleteCustomerSupplierPatient(id, patient_id, token);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetListCosts = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.getListCosts(params);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetListCost = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.getListCost(id);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleAddListCost = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.addListCost(data);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [])

  const handleUpdateListCost = useCallback(async (id, data) => {
    try {
      setLoading(true);
      const response = await CustomerSuppliers.updateListCost(id, data);

      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [])

  const handleGetCustomerSupplierFiles = useCallback(async (id, params = {}) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierFiles(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCustomerSupplierFile = useCallback(async (id, file_id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierFile(id, file_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddCustomerSupplierFile = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.addCustomerSupplierFile(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCustomerSupplierTaxesData = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierTaxesData(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateCustomerSupplierTaxesData = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.updateTaxesData(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetCustomerSupplierMultilateralAgreement = useCallback(async (id, params = {}) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getCustomerSupplierMultilateralAgreement(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddCustomerSupplierMultilateralAgreement = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.addMultilateralAgreement(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateCustomerSupplierMultilateralAgreement = useCallback(async (id, multilateral_agreement_id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.updateMultilateralAgreement(id, multilateral_agreement_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetIIBB = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getIIBB(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddIIBBException = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.addIIBBException(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateIIBBException = useCallback(async (id, iibb_exception_id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.updateIIBBException(id, iibb_exception_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDistributor = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getDistributor(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSuppliers = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getSuppliers(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddSupplier = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.addSupplier(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteSupplier = useCallback(async (id, supplier_id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.deleteSupplier(id, supplier_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDiscounts = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getDiscounts(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDiscount = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.addDiscount(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDiscount = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.updateDiscount(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  
  const handleEnableCustomerSupplier = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.enableCustomerSupplier(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  
  const handleDisableCustomerSupplier = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.disableCustomerSupplier(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetClientesParaRemitir = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.getClientesParaRemitir(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddClienteParaRemitir = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.addClienteParaRemitir(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateClienteParaRemitir = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.updateClienteParaRemitir(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteClienteParaRemitir = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.deleteClienteParaRemitir(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerAutorizacionesCredito = useCallback(async () => {
    try {
      setLoading(true)
      const response = await CustomerSuppliers.obtenerAutorizacionesCredito()

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetCustomerSuppliers,
    handleGetCustomerSupplier,
    handleCreateCustomerSupplier,
    handleUpdateCustomerSupplier,
    handleGetCustomerSupplierShipmentAddresses,
    handleGetCustomerSupplierDetailShipmentAddresses,
    handleLinkCustomerSupplierDetailShipmentAddressToVerifarma,
    handleUnlinkCustomerSupplierDetailShipmentAddressFromVerifarma,
    handleAddCustomerSupplierShipmentAddress,
    handleLinkShipmentAddressToCustomerSupplier,
    handleUpdateCustomerSupplierShipmentAddress,
    handleDeleteCustomerSupplierShipmentAddress,
    handleGetCustomerSupplierPatients,
    handleAddCustomerSupplierPatient,
    handleDeleteCustomerSupplierPatient,
    handleGetListCosts,
    handleGetListCost,
    handleAddListCost,
    handleUpdateListCost,
    handleGetCustomerSupplierFiles,
    handleGetCustomerSupplierFile,
    handleAddCustomerSupplierFile,
    handleGetCustomerSupplierTaxesData,
    handleUpdateCustomerSupplierTaxesData,
    handleGetCustomerSupplierMultilateralAgreement,
    handleAddCustomerSupplierMultilateralAgreement,
    handleUpdateCustomerSupplierMultilateralAgreement,
    handleGetIIBB,
    handleAddIIBBException,
    handleUpdateIIBBException,
    handleGetDistributor,
    handleGetSuppliers,
    handleAddSupplier,
    handleDeleteSupplier,
    handleGetDiscounts,
    handleAddDiscount,
    handleUpdateDiscount,
    handleEnableCustomerSupplier,
    handleDisableCustomerSupplier,
    handleGetClientesParaRemitir,
    handleAddClienteParaRemitir,
    handleUpdateClienteParaRemitir,
    handleDeleteClienteParaRemitir,
    handleObtenerAutorizacionesCredito,
  };
};

export default useCustomerSuppliers;