import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { usePatients, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { ShipmentAddressModal, LinkShipmentAddressModal } from 'containers/Modals'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './AddressesColumns'
import Filter from './AddressesFilter'
import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const AddressesTab = ({ updating, handleUpdating, handleGetPatient }) => {
  const { currentUser } = useAuth()
  const { values } = useFormikContext()
  const intl = useIntl()

  const [modal, setModal] = useState(false)
  const [addressInfo, setAddressInfo] = useState(null)

  const [linkModal, setLinkModal] = useState(false)

  const {
    loading,
    handleGetPatientAddresses,
    handleAddPatientAddress,
    handleLinkAddressToPatient,
    handleUpdatePatientAddress,
    handleDeletePatientAddress
  } = usePatients()
  const [patientAddresses, setPatientAddresses] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleShowModal = () => {
    setModal(true)
  }

  const getPatientAddresses = useCallback(async () => {
    try {
      const response = await handleGetPatientAddresses(values.id)
      const data = response.data.result

      setPatientAddresses(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setPatientAddresses([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetPatientAddresses, values.id])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: 'Editar',
        icon: 'bi bi-pencil-square text-primary',
        action: () => {
          setAddressInfo(currentRow)
          handleShowModal(true)
        }
      })

      actions.push({
        label: `${Boolean(currentRow.is_enabled) ? 'Deshabilitar' : 'Habilitar'}`,
        icon: `bi bi-${Boolean(currentRow.is_enabled) ? 'x-circle text-danger' : 'check-circle text-success'}}`,
        action: () => enableDisableAddress(currentRow.id)
      })

      // if (!Boolean(currentRow.is_enabled)) {
      //   actions.push({
      //     label: 'Desvincular',
      //     icon: 'bi bi-trash text-danger',
      //     action: () => deletePatientAddress(currentRow.id)
      //   })
      // }
    }

    return actions
  }

  const addPatientAddress = async (address) => {
    try {
      const data = {
        ...address,
        address: {
          ...address.address,
          origin: address.address.manual ? 'MANUAL' : 'GOOGLE',
        },
        agent_type_id: address.agent_type_id !== '' ? address.agent_type_id : null,
        gln: address.gln !== '' ? address.gln : null,
      }

      const response = await handleAddPatientAddress(values.id, data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleCloseModal()
      handleUpdating(false)
      handleGetPatient()
      getPatientAddresses()
    }
  }

  const linkAddress = async (address_id) => {
    try {
      const response = await handleLinkAddressToPatient(values.id, address_id)
      toast.success("Registro vinculado correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLinkModal(false)
      handleUpdating(false)
      handleGetPatient()
      getPatientAddresses()
    }
  }

  const updatePatientAddress = async (addressId, data) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: '¿Quiere actualizar la información de la dirección de entrega del paciente?',
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, actualizar',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        let new_data = {
          ...data,
          agent_type_id: data.agent_type_id !== '' ? data.agent_type_id : null,
          gln: data.gln !== '' ? data.gln : null,
        }

        const response = await handleUpdatePatientAddress(values.id, addressId, new_data)
        toast.success("Dirección actualizada correctamente", {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleCloseModal()
      setAddressInfo(null)
      handleUpdating(false)
      handleGetPatient()
      getPatientAddresses()
    }
  }

  const enableDisableAddress = async (addressId) => {
    try {
      let address = patientAddresses.find(a => a.id === addressId)
      let status = Boolean(address.is_enabled) ? 'deshabilitar' : 'habilitar'

      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere ${status} la dirección de entrega del paciente?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, ${status}`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        let data = {
          ...address,
          agent_type_id: address.agent_type_id !== '' ? address.agent_type_id : null,
          gln: address.gln !== '' ? address.gln : null,
          is_enabled: !Boolean(address?.is_enabled),
        }

        const response = await handleUpdatePatientAddress(values.id, addressId, data)
        toast.success("Registro actualizado correctamente", {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleUpdating(false)
      handleGetPatient()
      getPatientAddresses()
    }
  }

  // const deletePatientAddress = async (addressId) => {
  //   try {
  //     const resp = await Swal.fire({
  //       title: 'Advertencia',
  //       text: '¿Quiere desvincular la dirección de entrega del paciente?',
  //       icon: 'warning',
  //       showDenyButton: true,
  //       denyButtonText: 'No, cancelar',
  //       confirmButtonText: 'Si, desvincular',
  //       customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
  //       reverseButtons: true,
  //     })

  //     if (resp.isConfirmed) {
  //       const response = await handleDeletePatientAddress(values.id, addressId, 'token')
  //       toast.success(response.message)
  //     }
  //   } catch (error) {
  //     toast.error(error.message)
  //   } finally {
  //     handleUpdating(false)
  //     handleGetPatient()
  //     getPatientAddresses()
  //   }
  // }
  /* Fin menu contextual */

  const columns = useMemo(() => {
    return [
      ...COLUMNS,
      {
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
      }
    ]
  }, [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getPatientAddresses()
  }, [getPatientAddresses])

  return (
    <>
      <ShipmentAddressModal show={modal} onClose={handleCloseModal} values={addressInfo} onSave={values => addressInfo ? updatePatientAddress(addressInfo.id, values) : addPatientAddress(values)} ours={false} origin={'Paciente'} />

      <LinkShipmentAddressModal show={linkModal} onClose={() => setLinkModal(false)} onSave={linkAddress} conTrazabilidad={false} />

      <div className='row'>
        <div className='d-flex justify-content-between align-items-center'>
          <label className='form-label m-0'>{intl.formatMessage({ id: 'FORM.PATIENTS.ADDRESSES.TITLE' })}</label>

          {currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR, RoleI.VENTAS, RoleI.COMPRAS].includes(r.id)) &&
            <div>
              <Button className='BSButton me-4' variant='outline-success' onClick={() => setLinkModal(true)} >Relacionar dirección existente</Button>
              <Button className='BSButton' variant='outline-primary' onClick={() => { handleShowModal(); setAddressInfo(null); }}>Añadir dirección</Button>
            </div>
          }
        </div>
      </div>

      <TableContainer>
        <Filter handleData={setPatientAddresses} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />

        <CustomTable data={patientAddresses} columns={columns} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />

      {/* <FormTable columns={columns}>
        {patientAddresses.length <= 0
          ? <tr><td className='text-italic text-gray-400' colSpan={columns.length}>El paciente aún no tiene direcciones asociadas.</td></tr>
          : (patientAddresses).map((a, i) => (
            <tr key={`patient-address-${i}`}>
              <td className='align-middle'>{a.address.origin}</td>
              <td className='align-middle'>{a.name}</td>
              <td className='align-middle'>
                <ul className='list-style-none m-0 p-0'>
                <li>{a.address.route} {a.address.street_number}{a.address.floor !== '' ? ` (${a.address.floor})` : ''}, {a.address.postal_code}{a.address.postal_code_suffix} {a.address.custom_address_level1}, {a.address.custom_address_level2}, {a.address.administrative_area_level_1}</li>
                </ul>
              </td>
              <td className='align-middle'>
                {a.contact_id
                  ?
                    <ul className='list-style-none m-0 p-0'>
                      <li><strong>Nombre:</strong> {a.contact.name}</li>
                      <li><strong>Email:</strong> {a.contact.email}</li>
                      <li><strong>Teléfono:</strong> {a.contact.phone}</li>
                      <li><strong>Celular:</strong> {a.contact.mobile}</li>
                    </ul>
                  : <div className='text-center'>{'-'}</div>
                }
              </td>
              <td className='align-middle text-center'>
                <div className='d-flex flex-row'>
                  <Tooltip text={`Editar dirección #${a.id}`}>
                    <button
                      type='button'
                      className='btn btn-icon'
                      onClick={() => {
                        setAddressInfo(a)
                        handleShowModal(true)
                      }}
                    >
                      <i className='bi bi-pencil-square text-primary fs-2 ' />
                    </button>
                  </Tooltip>

                  <Tooltip text={`${Boolean(a.is_enabled) ? 'Deshabilitar dirección' : 'Habilitar dirección'} #${a.id}`}>
                    <button type='button' className='btn btn-icon' onClick={() => enableDisableAddress(a.id)}>
                      <i className={`${Boolean(a.is_enabled) ?  'bi bi-x-circle text-warning' : 'bi bi-check-circle text-success'} fs-2`} />
                    </button>
                  </Tooltip>

                  {!Boolean(a.is_enabled) &&
                    <Tooltip text={`Desvincular dirección #${a.id}`}>
                      <button type='button' className='btn btn-icon' onClick={() => deletePatientAddress(a.id)}>
                        <i className='bi bi-trash text-danger fs-2' />
                      </button>
                    </Tooltip>
                  }
                </div>
              </td>
            </tr>
          ))
        }
      </FormTable> */}

      <div className='mb-4' />
    </>
  )
}

export default AddressesTab