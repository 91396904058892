import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'

import { Tooltip } from 'components'

import { currencyFormat } from 'utilities'
import { AutorizacionCreditoI, SalesOrderStatusI } from 'interfaces'

import routes from 'app/routing/routes'

export const useGetColumns = (selectedRows, handleRowSelection) => {
  const COLUMNS = [
    {
      Header: '',
      id: 'customer_supplier_id_autorizacion_credito',
      accessor: (row) => (
        row.customer_supplier_id_autorizacion_credito === AutorizacionCreditoI.DESHABILITADO_CREDITOS
          ?
            <OverlayTrigger
              placement='top'
              overlay={
                <TooltipBS id='tooltip-top'>
                  <div>
                    <p className='m-0 p-0'>El cliente <strong>[{row.customer_supplier_id}] {row.customer_supplier_business_name}</strong></p>
                    <p className='m-0 p-0'>tiene la opción "Deshabilitado créditos" en la pestaña "Créditos" de su perfil.</p>
                  </div>
                </TooltipBS>
              }
            >
              <i className='bi bi-info-circle text-warning' />
            </OverlayTrigger>
          : null
      )
    },
    {
      Header: '',
      id: 'selected',
      accessor: (row) => (
        row.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO
          ?
            <input
              name="selected"
              type="checkbox"
              value={row.id}
              onChange={() => handleRowSelection(row.id, row.sales_order_status_id, row.profitability_auth_sent_date)}
              checked={selectedRows.some(i => i.PedidoId === row.id)}
              disabled={row.PedidoEstadoId !== SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA && row.customer_supplier_id_autorizacion_credito == AutorizacionCreditoI.DESHABILITADO_CREDITOS}
            />
          : <></>
      )
    },
    {
      Header: 'ID Pedido',
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.VENTAS_PEDIDOS}/${row.id}`}>
          {row.id}
        </Link>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>F. Creación</div>,
      id: 'created_at',
      accessor: (row) => (
        <p className='text-center m-0'>
          {moment(row.created_at).format('DD/MM/YYYY')}
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>F. Envío Aut.</div>,
      id: 'profitability_auth_sent_date',
      accessor: (row) => (
        <p className='text-center m-0'>
          {moment(row.profitability_auth_sent_date).format('DD/MM/YYYY HH:mm')}
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <div>Cliente</div>,
      id: 'customer_supplier_business_name',
      accessor: (row) => (
        <>
          <p className={row?.patient_name !== '' ? 'mb-6' : 'm-0'}>
            {row.customer_supplier_documentation} - {row.customer_supplier_business_name}
          </p>

          {row.patient_name !== '' &&
            <div>
              <strong>Paciente: </strong>
              <Tooltip text={row.patient_documentation ? 'Documento' : 'Nro. afiliado'}>
                <span>
                  {row?.patient_documentation ? row.patient_documentation : row.patient_affiliate_number}
                </span>
              </Tooltip> - {row.patient_name}
            </div>
          }
        </>
      ),
      sortOrder: true,
    },
    {
      Header: <div className='text-center'>Vendedor</div>,
      id: 'seller_id',
      accessor: (row) => (
        <div className='text-center'>
          {row.seller_name}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Artículos</div>,
      id: 'lines',
      accessor: (row) => (
        <div className='text-center'>
          {row.lines === 0 ? '-' : row.lines}
        </div>
      )
    },
    {
      Header: <div className='text-end'>Total</div>,
      id: 'total',
      accessor: (row) => (
        <div className='text-end'>
          {row.total == 0 ? '-' : currencyFormat.format(row.total)}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Lím. crédito</div>,
      id: 'customer_supplier_credit',
      accessor: (row) => (
        <div className='text-center'>
          {row?.customer_supplier_credit ? currencyFormat.format(row.customer_supplier_credit) : '-'}
        </div>
      )
    },
    {
      Header: <div className=''>Cond. pago cliente</div>,
      id: 'customer_supplier_payment_condition_label',
      accessor: (row) => (
        <div className=''>
          {row.customer_supplier_payment_condition_label}
        </div>
      ),
    },
    {
      Header: <div className=''>Cond. pago pedido</div>,
      id: 'payment_condition_label',
      accessor: (row) => (
        <div className=''>
          {row.payment_condition_label}
        </div>
      )
    },
    {
      Header: <div className='text-center'>Estado</div>,
      id: 'sales_order_status_id',
      className:'col-status',
      accessor: (row) => (
        <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.sales_order_status_label}
        </span>
      )
    }
  ]

  return {
    COLUMNS
  }
}