import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useShipmentAddresses } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { Filter } from './partials'
import { useGetColumns } from './columns'

import routes from 'app/routing/routes'
import ContextMenu from 'components/ContextMenu'
import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  from: 'CLIENTES',
}

const ShipmentAddresses = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetShipmentAddresses, handleUpdateEnableShipmentAddress } = useShipmentAddresses()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { COLUMNS } = useGetColumns()

  const getShipmentAddresses = useCallback(async () => {
    try {
      let params = {}
      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.shipment_addresses) {
        const { shipment_addresses } = currentUser

        params = {
          ...params,
          from: shipment_addresses?.from ? shipment_addresses.from : 'CLIENTES',
          text: shipment_addresses?.text || '',
          localidad: shipment_addresses?.localidad || '',
          provincia: shipment_addresses?.provincia || '',
          origin_filter: shipment_addresses?.origin_filter || '',
          has_traceability: shipment_addresses?.has_traceability || '',
          agent_type_id_filter: shipment_addresses?.agent_type_id_filter || [],
          gln: shipment_addresses?.gln || '',
          is_ours: shipment_addresses?.is_ours || '',
          is_enabled: shipment_addresses?.is_enabled || '',
        }
      }

      const response = await handleGetShipmentAddresses('', '', params)
      setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetShipmentAddresses, query, currentUser])

  /* Menu contextual */
  const navigate = useNavigate();
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0})
  const [currentRow, setCurrentRow] = useState({})
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: `Ver`,
      icon: "bi bi-pencil-square text-primary",
      action: () => navigate(`${routes.SHIPMENT_ADDRESSES}/${currentRow?.id}`)
    })

    if (currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR].includes(r.id))) {
      actions.push({
        label: `${Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar'}`,
        icon: `${Boolean(currentRow?.is_enabled) ? 'bi-x-circle text-danger' : 'bi-check2-circle text-success'} `,
        action: enableDisableShipmentAddress
      })
    }

    return actions
  }

  const enableDisableShipmentAddress = async () => {
    try {
      let status = Boolean(currentRow.is_enabled) ? 'deshabilitar' : 'habilitar'
      let msg = Boolean(currentRow.is_enabled) ? 'deshabilitada' : 'habilitada'

      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere ${status} la dirección de entrega #${currentRow.id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, ${status}`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        let data = {
          is_enabled: !Boolean(currentRow.is_enabled),
        }

        await handleUpdateEnableShipmentAddress(currentRow.id, data)
        toast(`Dirección de entrega ${msg} correctamente`, { type: Boolean(data.is_enabled) ? 'success' : 'info' })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getShipmentAddresses()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS, getShipmentAddresses])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getShipmentAddresses()
  }, [getShipmentAddresses])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Direcciones de entrega'}
        goTo={
          currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR].includes(r.id))
            ? routes.SHIPMENT_ADDRESSES_NEW
            : null
        }
        filter={<Filter handleData={setData} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default ShipmentAddresses