import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

const COLUMNS = [
  {
    Header: <div className='text-center'>Nro.</div>,
    id: 'id',
    accessor: (row) => (
      <div className='text-center'>
        {row.id_real}
      </div>
    ),
  },
  {
    Header: <div className=''>Tipo</div>,
    id: 'tipo_comprobante',
    accessor: (row) => (
      <div className=''>
        {row.tipo_comprobante}
      </div>
    ),
  },
  {
    Header: <div className=''>Número</div>,
    id: 'nro_completo',
    accessor: (row) => (
      <div className=''>
        {row.nro_completo}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>CAE</div>,
    id: 'cae',
    accessor: (row) => (
      <div className='text-center'>
        {row?.cae ? row.cae : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Fecha</div>,
    id: 'fecha',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.fecha).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>F. cancelación</div>,
    id: 'fecha_cancelacion',
    accessor: (row) => (
      <div className='text-center'>
        {row?.fecha_cancelacion ? moment(row.fecha_cancelacion).format('DD/MM/YYYY') : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'status',
    accessor: (row) => (
      <span className={`badge ${row.label_color} w-100 justify-content-center`}>
        {row.label}
      </span>
    )
  },
  {
    Header: '',
    id: 'motivo_cancelacion',
    accessor: (row) => (
      (Boolean(row?.cancelado) || row?.cancela_a_id) &&
        <OverlayTrigger overlay={
          <Tooltip>
            <div className='text-start'>
              <p className='m-0 p-0'>
                {row.tipo_comprobante == 'Remito'
                  ?
                    <p className='m-0 p-0'>
                      <strong>Motivo:</strong> {row?.motivo_cancelacion || '-'}
                    </p>
                  :
                    <>
                      <p className='m-0 p-0'>
                        <strong>
                          {row?.cancela_a_id ? `Cancela al comprobante #${row.cancela_a_id}` : `Comprobante de cancelación #${row?.comprobante_cancelacion_id}`}
                        </strong>
                      </p>
                      {row?.cancela_a_id &&
                        <p className='m-0 p-0 mt-2'>Motivo: {row?.comprobante_cancelacion_motivo}</p>
                      }
                    </>
                }
              </p>
            </div>
          </Tooltip>
        }>
          <i className="bi bi-code-square text-danger"  />
        </OverlayTrigger>
    ),
  }
]

export {
  COLUMNS
}