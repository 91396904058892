import { useState, useCallback, useMemo, useEffect } from 'react'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'
import moment from 'moment';
import * as XLSX from 'xlsx/xlsx.mjs';

import { useAuth } from 'app/modules/auth';
import { useCustomerSuppliers } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { Filter } from './partials'
import { COLUMNS } from './columns'

import { currencyFormat } from 'utilities'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const ListCosts = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetListCosts } = useCustomerSuppliers()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [columns, setColumns] = useState(COLUMNS)

  const getListCosts = useCallback(async () => {
    try {
      let params = {
        type: 'lista',
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.costs_sales) {
        const { costs_sales } = currentUser

        params = {
          ...params,
          type: 'lista',
          text: costs_sales?.text || '',
          article_text: costs_sales?.article_text || '',
          // created_at: costs_sales?.created_at || '',
        }
      }

      const response = await handleGetListCosts(params)
      const data = response.data.result.map(d => ({
        ...d,
        total_cost: +d.initial_cost + +d.add_cost + +d.sircreb_amount,
        date_from: d.date_from ? moment(d.date_from).format("YYYY-MM-DD") : '',
        date_to: d.date_to ? moment(d.date_to).format("YYYY-MM-DD") : '',
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetListCosts, query, currentUser])

  const exportListCosts = async (values) => {
    try {
      let params = {
        ...values,
        pageNumber: 1,
        pageSize: undefined,
      }

      const response = await handleGetListCosts(params)
      const datos = response.data.result.map(d => {
        let data = {
          'ID Producto': d.product_id,
          'ID Artículo': d.product_var_id,
          'Nombre artículo': d.product_var_name,
          'SKU': d.sku,
          'GTIN': d.gtin,
          'PVP': currencyFormat.format(d.pvp),
          'PSL': currencyFormat.format(d.psl),
          'Bonif. (%)': d?.initial_cost_percent ? d.initial_cost_percent : '',
          'Costo inicial': currencyFormat.format(d.initial_cost),
          'Costo asociado (%)': d?.add_cost_percent ? d.add_cost_percent : '',
          'Costo asociado': currencyFormat.format(d.add_cost),
          'SIRCREB': currencyFormat.format(d.sircreb_amount),
          'Costo total': currencyFormat.format(+d.initial_cost + +d.add_cost + +d.sircreb_amount),
        }

        return data
      })

      let ws = XLSX.utils.json_to_sheet(datos)

      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "Costos_Lista")
      XLSX.writeFile(wb, `Costos_Lista ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const cols = useMemo(() => {
    const c = [...columns]

    return c
  }, [columns])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getListCosts()
  }, [getListCosts])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Costos de lista'}
        filter={<Filter handleData={setData} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} exportData={exportListCosts} />}
        filterColumns
        columns={cols}
        setColumns={setColumns}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "150px" }}></l-helix>}

        <CustomTable
          columns={cols.filter(c => c.visible !== false)}
          data={data || []}
          paginationOptions={paginationOptions}
          queryMD={query}
          setQueryMD={setQuery}
        />
      </TableContainer>
    </>
  )
}

export default ListCosts