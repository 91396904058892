import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useLogisticStock, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { COLUMNS } from './columns'
import { MovementsModal } from './partials'
import ReservasModal from './partials/ReservasModal'

import routes from 'app/routing/routes'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const LogisticStock = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetStockList } = useLogisticStock()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [movementsModal, setMovementsModal] = useState(false)
  const [reservasModal, setReservasModal] = useState(false)

  const getStockList = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.stock_management) {
        const { stock_management } = currentUser

        params = {
          ...params,
          id_article: stock_management?.id_article || '',
          text: stock_management?.text || '',
          gtin: stock_management?.gtin || '',
          storage_space_id: stock_management?.storage_space_id || '',
          customer_supplier_id: stock_management?.customer_supplier_id || '',
          business_unit_id: stock_management?.business_unit_id || '',
          traced: stock_management?.traced || '',
          only_available: stock_management?.only_available || 0,
        }
      }

      const response = await handleGetStockList(params)
      const data = response.result

      setData(data)
      setTotalCount(response.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetStockList, queryMD, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
};

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    actions.push({
      label: "Ver Movimientos",
      icon: "bi bi-clock-history text-warning",
      action: actionMovements
    })

    if (+currentRow?.stock_reserved > 0) {
      actions.push({
        label: "Ver reservas",
        icon: "bi bi-box text-success",
        action: actionVerReservas
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.INVENTORY_STOCK}/${currentRow.product_var_id}`, {
        state: {
          product_var_id:currentRow.product_var_id,
          business_unit_id:currentRow.business_unit_id || 1,
          customer_supplier_id:currentRow.customer_suppliers_id || 1,
          business_unit_name:currentRow.business_unit_name,
          customer_suppliers_name:currentRow.customer_suppliers_name,
          stock_available:currentRow.stock_available,
          stock_reserved:currentRow.stock_reserved,
          stock_not_available:currentRow.stock_not_available,
        }
      })
    }
  }

  const actionVerReservas = ()=>{
    setReservasModal(true)
  }

  const actionMovements = () => {
    if (currentRow) {
      setMovementsModal(true)
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD
  }

  // useEffect(() => {
  //   getStockList()
  // }, [getStockList])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <MovementsModal show={movementsModal} onHide={() => setMovementsModal(false)} article={currentRow} />

      <ReservasModal show={reservasModal} onHide={() => setReservasModal(false)} article={currentRow} />

      <TableContainer
        title='Stock de artículos'
        filter={<Filter setData={setData} setTotalCount={setTotalCount} queryMD={queryMD} setQueryMD={setQueryMD} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

        <CustomTable columns={columns} data={data}  paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default LogisticStock
