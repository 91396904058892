import { useState, useCallback } from 'react'

import { Products } from 'services'

const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}

const useProducts = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);

  const handleGetProducts = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);

      const {filter, queryMD} = params;

      if (filter !== null && filter !== '') {
        // query = filter + '&' + new URLSearchParams(queryMD).toString();
        query = `${filter}&${new URLSearchParams(queryMD).toString()}`
      }
      else{
        // query = "?" + new URLSearchParams(queryMD).toString();
        query = `?${new URLSearchParams(queryMD).toString()}`
      }

      const response = await Products.getProducts(query);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetProductsV2 = useCallback(async (params) => {
    try {
      setLoading(true)

      const response = await Products.getProductsV2(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetProduct = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Products.getProduct(id);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearchProducts = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Products.searchProducts(params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearchProductsVar = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Products.searchProductsVar(params);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateProduct = useCallback(async (data, token) => {
    try {
      setLoading(true);
      const response = await Products.createProduct(data);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateProduct = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Products.updateProduct(data);

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDeleteProduct = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Products.deleteProduct(id);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);


  const handleGetProductFamilies = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Products.getProductFamilies();
      return response?.data
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetProductMakers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Products.getProductMakers();
      return response?.data
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);



  const productTypesOptions = [
    { value: 1, label: 'Medicamento' },
    { value: 2, label: 'Producto Médico' }
  ];
  const distributionChannelOptions = [
    { value: 1, label: 'Shop' },
    { value: 2, label: 'Droguería' }
  ];



  return {
    loading,
    queryString,
    setQueryString,
    handleGetProductFamilies,
    handleGetProductMakers,
    handleGetProducts,
    handleGetProductsV2,
    handleGetProduct,
    handleCreateProduct,
    handleUpdateProduct,
    handleDeleteProduct,
    handleSearchProducts,
    handleSearchProductsVar,
    productTypesOptions,
    distributionChannelOptions
  };
};

export default useProducts;