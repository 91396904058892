import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'

import { Tooltip } from 'components'
import { OrderTypeI, OutOrderStatusI } from 'interfaces'
import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

import { RoleI } from 'interfaces'

export const useGetColumns = (selectedRows, handleRowSelection) => {
  const { currentUser } = useAuth()

  const intl = useIntl()

  const COLUMNS = [
    {
      Header: '',
      id: 'selected',
      label: '',
      accessor: (row) => (
        currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE) &&
        row.out_order_status_id === OutOrderStatusI.PENDIENTE_PREPARACION
          ?
            <input
              name="selected"
              type="checkbox"
              // value={row.id}
              onChange={() => handleRowSelection(row.id, row.out_order_status_id)}
              checked={row.isSelected}
              disabled={!currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE)}
            />
          : <></>
      ),
      visible: true,
      notHide: true,
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.ID' }),
      id: 'id',
      label: 'Nro.',
      accessor: (row) => (
        <Link to={`${routes.OUT_ORDERS}/${row.id}`}>
          {row.id}
        </Link>
      ),
      hasOrder: true,
      visible: true,
      notHide: true,
    },
    {
      Header: <div className='text-center'>F. creación</div>,
      id: 'created_at',
      label: 'F. creación',
      accessor: (row) => (
        <p className='text-center m-0'>
          {moment(row.created_at).format('DD/MM/YYYY')}
        </p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <div className='text-center'>Pedido</div>,
      id: 'sales_order_id',
      label: 'Pedido',
      accessor: (row) => (
        <>
        <p className='text-center p-0 m-0'>
          <Link to={`${routes.VENTAS_PEDIDOS}/${row.sales_order_id}`}>
            {row.sales_order_id}
          </Link>
        </p>
        </>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <div className='text-center'>F. entrega</div>,
      id: 'dispatch_date',
      label: 'F. entrega',
      accessor: (row) => (
        <p className='text-center m-0'>
          {row.dispatch_date ? moment(row.dispatch_date).format('DD/MM/YYYY') : '-'}
        </p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.CLIENT' }),
      id: 'customer_supplier_id',
      label: 'Cliente',
      accessor: (row) => (
        <>
          <strong>[{row.customer_supplier_id}] {row.customer_supplier_business_name}</strong>
          <p className='m-0 p-0 mt-2'>{row.customer_supplier_documentation_type}: {row.customer_supplier_documentation}</p>

          {Boolean(row.remito_para_terceros) && row?.id_tercero_para_remitir !== null &&
            <p className='m-0 p-0 mt-4'>Remitir a: {row.tercero_para_remitir}</p>
          }

          {row.patient_id &&
            <div className='mt-4'>
              <strong>Paciente:</strong>
              <p className='m-0 p-0 mt-2'>[{row.patient_id}] {row.patient_name}</p>
              <p className='m-0 p-0 mt-2'>Documento: {row?.patient_documentation || '-'}</p>
              <p className='m-0 p-0 mt-2'>Nro. afiliado: {row?.patient_affiliate_number || '-'}</p>
            </div>
          }
        </>
      ),
      visible: true,
    },
    {
      Header: 'Nombre / Domicilio',
      id: 'address_id_ship',
      label: 'Nombre / Domicilio',
      accessor: (row) => (
        <>
          {row.order_type_id != OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO &&
            <>
              <strong>{row.ship_name}</strong>
              <p className='m-0 p-0 mt-4'>{row?.ship_address?.trim()}</p>
              <p className='m-0 p-0 mt-2'>GLN: {row?.ship_gln || '-'}</p>
            </>
          }
        </>
      ),
      visible: true,
    },
    {
      Header: 'Localidad',
      id: 'LocalidadEnvio',
      label: 'Localidad',
      accessor: (row) => (
        <p className='m-0 p-0'>{row?.LocalidadEnvio?.trim()}</p>
      ),
      visible: true,
    },
    {
      Header: 'Provincia',
      id: 'ProvinciaEnvio',
      label: 'Provincia',
      accessor: (row) => (
        <p className='m-0 p-0'>{row?.ProvinciaEnvio?.trim()}</p>
      ),
      visible: true,
    },
    {
      Header: <div className='text-center m-0'>Artículos</div>,
      id: 'lines',
      label: 'Artículos',
      accessor: (row) => (
        <div className='text-center'>
          {row.lines === 0 ? '-' : row.lines}

          {row?.detail &&
            <OverlayTrigger
              placement='top'
              overlay={
                <TooltipBS id={`tooltip-${row.id}`}>
                  <div>
                    {row.detail.split(',').map((item, index) => (
                      <div key={index}>{item.trim()}</div>
                    ))}
                  </div>
                </TooltipBS>
              }
            >
              <i className='bi bi-info-circle text-warning ms-2' />
            </OverlayTrigger>
          }
        </div>
      ),
      visible: true,
    },
    {
      Header: <div className='text-end'>Total</div>,
      id: 'total_calc',
      label: 'Total',
      accessor: (row) => (
        <div className='text-end'>
          {currencyFormat.format(row?.total_calc || 0)}
        </div>
      ),
      visible: true,
    },
    {
      Header: <p className='text-center m-0'>{intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.STATUS' })}</p>,
      id: 'out_order_status_id',
      label: 'Estado',
      className:'col-status',
      accessor: (row) =>(
        <span className={`badge ${row.label_color} w-100 justify-content-center m-0 fw-normal`}>
          {row.out_order_status_label}
        </span>
      ),
      visible: true,
    },
    {
      Header: "",
      id: 'icons',
      label: '',
      accessor: (row) => (
        <div className='d-flex'>
          {row.returned_date
            ?
              <Tooltip text='Tiene redespacho'>
                <i className='bi bi-arrow-repeat text-primary fs-3 me-2' />
              </Tooltip>
            : <></>
          }
        </div>
      ),
      visible: true,
      notHide: true,
    },
    {
      Header: "",
      id: 'has_regrigeration',
      label: '',
      accessor: (row) => (
        <div style={{ width: "70px" }}>
          {Boolean(row.has_refrigeration) &&
            <Tooltip text='Requiere frío'>
              <i className='bi bi-snow text-primary me-2' />
            </Tooltip>
          }

          {Boolean(row.order_type_id == OrderTypeI.PEDIDO_EGRESO) &&
            <Tooltip text='Pedido de egreso'>
              <i className='bi bi-box-arrow-up text-primary me-2' />
            </Tooltip>
          }

          {Boolean(row.order_type_id == OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO) &&
            <Tooltip text='Pedido de venta con almacenamiento propio'>
              <i className='bi bi-save text-primary me-2' />
            </Tooltip>
          }

          {Boolean(row.por_unidades == 1) &&
            <Tooltip text='Pedido por unidades'>
              <i className='bi bi-1-square text-primary me-2' />
            </Tooltip>
          }
        </div>
      ),
      visible: true,
      notHide: true,
    },
  ]

  return {
    COLUMNS
  }
}