import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useClickOutside, useSalesOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { useGetColumns } from './columns'
import { Filter, TableActions } from './partials'
import { AutorizacionCreditoI, OrderTypeI, SalesOrderStatusI } from 'interfaces'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const ProfitabilityAuthorizations = () => {
  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const handleRowSelection = useCallback((rowId, statusId, idClienteAutorizacionCredito, idCondicionPagoVenta, idCondicionPagoCliente) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some(i => i.PedidoId === rowId)) {
        return prevSelectedRows.filter(i => i.PedidoId !== rowId)
      } else {
        return [
          ...prevSelectedRows,
          {
            PedidoId: rowId,
            status_id: statusId,
            IdClienteAutorizacionCredito: idClienteAutorizacionCredito,
            IdCondicionPagoVenta: idCondicionPagoVenta,
            IdCondicionPagoCliente: idCondicionPagoCliente,
            checked: true
          }]
      }
    })
  }, [])

  const { COLUMNS } = useGetColumns(selectedRows, handleRowSelection)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading, handleGetPendingRent, handleUpdateSalesOrderStatus, handleUpdateOutRequestStatus, handleCheckSalesOrderPVP, handleUpdateObservations, handleUpdateDispatchObservations, handleCreateOutOrder } = useSalesOrders()
  const navigate = useNavigate();

  const getSalesOrders = useCallback(async () => {
    try {
      let params = {
        sales_order_status_id: [SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA],
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetPendingRent(params)
      if (response?.data?.result) {
        const _pedidos = response.data.result.map(p => ({
          ...p,
          id: p.PedidoId,
          order_type_id: OrderTypeI.PEDIDO_VENTA
        }))

        setData(_pedidos)
        setTotalCount(+response.data.metadata.count)
        setSelectedRows([])
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      setSelectedRows([])
      toast.error(error.message)
    }
  }, [handleGetPendingRent, query])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({
        x: e.clientX,
        y: e.clientY,
        target: e.target.id,
      })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: 'Ir a detalle',
        icon: 'bi bi-eye text-primary',
        action: actionEdit,
      })

      if (currentRow.PedidoEstadoId === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA) {
        actions.push({
          label: "Autorizar rentabilidad",
          icon: "bi bi-clipboard-check text-success",
          action: async () => {
            await actionRentAutorize(currentRow?.id)

            if (
              currentRow.IdClienteAutorizacionCredito == AutorizacionCreditoI.AUTORIZADO_SIEMPRE ||
              (
                currentRow.IdClienteAutorizacionCredito == AutorizacionCreditoI.AUTOMATICA_CONDICION_PAGO &&
                currentRow.IdCondicionPagoVenta == currentRow.IdCondicionPagoCliente
              )
            ) {
              await handleCreateOutOrder(currentRow?.id)
            }

            await getSalesOrders()
          }
        })

        actions.push({
          label: "No autorizar",
          icon: "bi bi-clipboard-x text-danger",
          action: actionRentNotAutorize
        })

        actions.push({
          label: 'Añadir observaciones',
          icon: 'bi bi-book text-primary',
          action: actionAddObservation
        })

        actions.push({
          label: 'Añadir observaciones despacho',
          icon: 'bi bi-book-fill text-primary',
          action: actionAddDispatchObservation
        })
      }

      /* TODO verificar estados posibles para anulaciones */
    }

    return actions
  }

  const updateSalesOrderStatus = async (newStatus) => {
    try {
      if (currentRow?.order_type_id === OrderTypeI.PEDIDO_VENTA) {
        const response = await handleUpdateSalesOrderStatus(currentRow?.id, newStatus)
        toast.success("Orden actualizada correctamente", { theme: "colored" })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getSalesOrders()
    }
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(currentRow?.order_type_id === OrderTypeI.PEDIDO_VENTA
        ? `${routes.VENTAS_PEDIDOS}/${currentRow.id}`
        : currentRow?.order_type_id === OrderTypeI.PEDIDO_EGRESO
          ? `${routes.OUT_REQUEST}/${currentRow.id}`
          : routes.DASHBOARD
      )
    }
  }

  const actionRentAutorize = async () => {
    try {
      const pvp = await handleCheckSalesOrderPVP(currentRow?.id)

      let text = `<p>¿Autorizar la rentabilidad del <strong>Pedido #${currentRow?.id}</strong>?</p>`

      if (pvp.data.result.length > 0) {
        let qty = pvp.data.result.length
        text = `<p>El <strong>Pedido #${currentRow?.id}<strong> tiene (${qty}) detalle${qty > 1 ? `s` : ''} con PVP desactualizado, ¿quiere autorizar la rentabilidad?</p>`
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        html: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cerrar",
        confirmButtonText: "Sí, autorizar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await updateSalesOrderStatus(SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  const actionRentNotAutorize = async () => {
    try {
      const inputValue = '';
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a rechazar la autorización de rentabilidad del pedido #${currentRow?.id}, ¿confirma?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Motivo",
        inputAttributes: {
          autocomplete:"off"
        },
        inputValidator: (value) => {
          if (!value || value.trim() == '') {
            return "Por favor, completar motivo de rechazo"
          }
        },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, rechazar autorización",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(currentRow?.id, SalesOrderStatusI.NO_AUTORIZADO, { observaciones: resp.value })
        toast.success("Autorización rechazada", { theme: "colored" })
      }

      getSalesOrders()
    } catch (err) {
      toast.error(err.message)
    }
  }

  const actionAddObservation = async () => {
    try {
      const inputValue = currentRow?.Observacion;
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Añadir observaciones al pedido?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Observaciones del pedido",
        inputAttributes: {
          autocomplete:"off"
        },
        // inputValidator: (value) => {
        //   if (!value || value.trim() == '') {
        //     return "Por favor, completar motivo de rechazo"
        //   }
        // },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, añadir",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const response = await handleUpdateObservations(currentRow?.id, { observations: resp.value })
        toast.success(response.data.message)
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      getSalesOrders()
    }
  }

  const actionAddDispatchObservation = async () => {
    try {
      const inputValue = currentRow?.ObservacionDespacho;
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `¿Añadir observaciones de despacho al pedido?`,
        icon: "warning",
        input:"textarea",
        inputValue,
        inputLabel: "Observaciones despacho",
        inputAttributes: {
          autocomplete:"off"
        },
        // inputValidator: (value) => {
        //   if (!value || value.trim() == '') {
        //     return "Por favor, completar motivo de rechazo"
        //   }
        // },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, añadir",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const response = await handleUpdateDispatchObservations(currentRow?.id, { observations: resp.value })
        toast.success(response.data.message)
      }
    } catch (err) {
      toast.error(err.message)
    } finally {
      getSalesOrders()
    }
  }

  const nullifyOrder = () => {
    updateSalesOrderStatus(SalesOrderStatusI.ANULADO)
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
    }
  ], [COLUMNS, getSalesOrders])

  const paginationOptions = {
    totalSize: totalCount,
    setObQuery: setQuery,
    obQuery: query,
  }

  useEffect(() => {
    getSalesOrders()
  }, [getSalesOrders])

  return (
    <>
      <TableContainer
        title='Autorizaciones de rentabilidad'
        filter={<Filter query={query} setQuery={setQuery} setSelectedRows={setSelectedRows} loading={loading} />}
        actions={
          <TableActions
            loading={loading}
            data={data}
            selectedRows={selectedRows}
            handleSelectedRows={setSelectedRows}
            handleGetSalesOrders={getSalesOrders}
          />
        }
        doubleActions={true}
      >
        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default ProfitabilityAuthorizations