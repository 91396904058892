import { Link } from "react-router-dom"
import moment from 'moment'

import { Tooltip } from 'components'

import routes from "app/routing/routes"
import { currencyFormat } from "utilities"

const COLUMNS = [
  {
    Header: <div style={{ width: '70px' }}>Nro.</div>,
    id: 'IdOrdenEgreso',
    label: 'Nro.',
    accessor: (row) => (
      <Tooltip text='Ver orden'>
        <Link to={`${routes.OUT_ORDERS}/${row.IdOrdenEgreso}`} target='_blank'>
          <strong>{row.IdOrdenEgreso}</strong>
        </Link>
      </Tooltip>
    ),
    visible: true,
    notHide: true,
  },
  {
    Header: <div style={{ width: '200px' }}>Cliente</div>,
    id: 'Cliente',
    label: 'Cliente',
    accessor: (row) => (
      <Tooltip text='Ver cliente'>
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.IdCliente}`} target='_blank'>
          <strong>[{row.IdCliente}] {row.Cliente}</strong>
        </Link>
      </Tooltip>
    ),
    visible: true,
  },
  {
    Header: <div style={{ width: '150px' }}>Remito</div>,
    id: 'IdRemito',
    label: 'Remito',
    accessor: (row) => (
      <div>
        {row?.IdRemito && row?.Remito
          ?
            <>
              <p className='m-0'><strong>{row.Remito}</strong></p>
              <p className='m-0 mt-2'><i>Fecha: {moment(row?.FechaRemito).format('DD/MM/YYYY')}</i></p>
            </>
          : <i>Sin remito válido</i>
        }
      </div>
    ),
    visible: true,
  },
  {
    Header: <div style={{ width: '250px' }}>Dirección de entrega</div>,
    id: 'IdEnvio',
    label: 'Dirección de entrega',
    accessor: (row) => (
      <div>
        <Tooltip text='Ver dirección'>
          <Link to={`${routes.SHIPMENT_ADDRESSES}/${row.IdEnvio}`} target='_blank'>
            <strong>{row.NombreEnvio}</strong>
          </Link>
        </Tooltip>

        <p className='m-0'><strong>Domicilio:</strong> {row.DomicilioEnvio?.trim()}{row.CPEnvio?.trim() ? ` (CP: ${row.CPEnvio?.trim()})` : ''}</p>
        <p className='m-0'><strong>Localidad:</strong> {row.LocalidadEnvio?.trim()}</p>
        <p className='m-0'><strong>Provincia:</strong> {row.ProvinciaEnvio?.trim()}</p>
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Total entregado según remito'><div className='text-end' style={{ width: '120px' }}>Valor entregado</div></Tooltip>,
    id: 'ValorEntregado',
    label: 'Valor entregado',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row.ValorEntregado)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Total devuelto por el cliente'><div className='text-end' style={{ width: '120px' }}>Valor devuelto</div></Tooltip>,
    id: 'ValorDevuelto',
    label: 'Valor devuelto',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row.ValorDevuelto)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Total entregado según remito menos todo lo devuelto por el cliente'><div className='text-end' style={{ width: '120px' }}>Valor final</div></Tooltip>,
    id: 'ValorFinal',
    label: 'Valor final',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.ValorFinal || 0)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Sumatoria comprobantes ya emitidos, con cae, para este remito'><div className='text-end' style={{ width: '120px' }}>Facturado consolidado</div></Tooltip>,
    id: 'FacturadoConsolidado',
    label: 'Facturado consolidado',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.Consolidado || 0)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Sumatoria comprobantes diferidos para este remito'><div className='text-end' style={{ width: '120px' }}>Comprobantes diferidos</div></Tooltip>,
    id: 'FacturadoPendiente',
    label: 'Comprobantes diferidos',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.Pendiente || 0)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Diferencia entre lo entregado según remito y el total de todos los comprobantes ya emitidos, con cae'><div className='text-end' style={{ width: '120px' }}>Dif. Facturado</div></Tooltip>,
    id: 'DiferenciaFacturado',
    label: 'Dif. Facturado',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.DiferenciaFacturado || 0)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <Tooltip text='Diferencia entre lo entregado según remito y la suma de todos los comprobantes ya emitidos mas los comprobantes diferidos'><div className='text-end' style={{ width: '120px' }}>Dif. Facturado + Diferido</div></Tooltip>,
    id: 'DiferenciaFacturadoMasPendiente',
    label: 'Dif. facturado + Diferido',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.DiferenciaFacturadoMasPendiente || 0)}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center' style={{ width: '120px' }}>Estado</div>,
    id: 'EstadoControlGestion',
    label: 'Estado',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge ${row.EstadoControlGestionColor} w-100 justify-content-center m-0 fw-normal`}>
          {row.EstadoControlGestion}
        </span>

        {row.IdUsuarioControlGestion && <p className='m-0 p-0 mt-2'>{row.UsuarioControlGestion}</p>}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Obs.</div>,
    id: 'ObservacionControlGestion',
    label: 'Obs.',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text={row?.ObservacionControlGestion || <i>Sin observaciones</i>}>
          <span className='bi bi-info-circle text-primary' />
        </Tooltip>
      </div>
    ),
    visible: true,
  }
]

export {
  COLUMNS,
}