import { useState, useCallback } from 'react';

import { Reports } from '../services';


const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}

const useReports = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);


  const handleGetIncomesReport = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);
      const response = await Reports.getIncomes(params);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetTransactionalDocumentsReport = useCallback(async (params) => {
    setLoading(true);
    try {
      const response = await Reports.getTransactionalDocuments(params);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetTransactionalDocumentsDetailReport = useCallback(async (params) => {
    try {
      setLoading(true);
      const response = await Reports.getTransactionalDocumentsDetail(params);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetVentasReport = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);
      const response = await Reports.getVentas(params);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetVentasDetalleReport = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);
      const response = await Reports.getVentasDetalle(params);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetReporteRemitos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Reports.getReporteRemitos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetReporteRemitosSinComprobantesDetalle = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Reports.getReporteRemitosSinComprobantesDetalle(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetReporteRemitosVerifarma = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Reports.getReporteRemitosVerifarma(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])


  const handleGetReporteInventario = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Reports.getReporteInventario(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetReporteAnalisStock = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Reports.getReporteAnalisisStock(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    queryString,
    setQueryString,
    handleGetIncomesReport,
    handleGetTransactionalDocumentsReport,
    handleGetTransactionalDocumentsDetailReport,
    handleGetVentasReport,
    handleGetVentasDetalleReport,
    handleGetReporteRemitos,
    handleGetReporteRemitosSinComprobantesDetalle,
    handleGetReporteInventario,
    handleGetReporteRemitosVerifarma,
    handleGetReporteAnalisStock,
  }
}

export default useReports;