import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import { useAuth } from 'app/modules/auth'

import routes from 'app/routing/routes'
import { RoleI } from 'interfaces'

const Actions = ({ editing, handleEditing, submitRef, cancelRef }) => {
  const { currentUser } = useAuth()

  const navigation = useNavigate()

  return (
    <div className='d-flex align-items-center justify-content-end'>
      {!editing
        ?
          <>
            <Button
              type='button'
              variant='secondary'
              onClick={() => navigation(routes.SHIPMENT_ADDRESSES)}
              className={currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR].includes(r.id)) ? 'me-3' : ''}
            >
              <i className="ki-duotone ki-left" />
              Volver
            </Button>

            {currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR].includes(r.id)) &&
              <Button variant='primary' type='button' onClick={() => handleEditing(true)}>
                <i className='bi bi-pencil' />
                Editar
              </Button>
            }
          </>
        :
          <>
            <Button className='me-3' variant='secondary' type='button' onClick={() => cancelRef.current.click()}><i className="bi bi-slash-circle" />Cancelar</Button>
            <Button variant='primary' type='button' onClick={() => submitRef.current.click()}><i className='bi bi-save me-2' />Guardar</Button>
          </>
      }
    </div>
  )
}

export default Actions