import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'

import { InputV2, SelectV2 } from 'components/v2'

const initialFilterValues = {
  business_unit_id: [],
  customer_supplier: '',
  id_articulo: '',
  articulo: '',
  droga: '',
  laboratorio: '',
  sku: '',
  text: '',
  text2: '',
}

const Filter = ({ loading, queryMD, setQueryMD, exportData  }) => {
  const filterData = (values) => {
    const params = {
      ...queryMD,
      ...values,
      business_unit_id: values?.business_unit_id || undefined,
      customer_supplier: values?.customer_supplier || undefined,
      id_articulo: values?.id_articulo || undefined,
      articulo: values?.articulo || undefined,
      droga: values?.droga || undefined,
      laboratorio: values?.laboratorio || undefined,
      sku: values?.sku || undefined,
      text: values?.text ? values.text : undefined,
      text2: values?.text2 ? values.text2 : undefined,
      pageNumber: 1,
      pageSize: 10,
    }

    setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
  }

  return (
    <Formik
      initialValues={initialFilterValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <SelectV2
              id='business_unit_id'
              name='business_unit_id'
              col='col-4'
              label='Uni. de negocio'
              options={[
                { value: 1, label: 'Farma' },
                { value: 2, label: 'Shop' },
              ]}
              placeholder='Seleccionar'
              noLabel
              isMulti
            />

            <InputV2 id='customer_supplier' name='customer_supplier' label='Dueño' muted col='col-8' />
          </div>

          <div className='row'>
            <InputV2 id='id_articulo' name='id_articulo' label='ID Artículo' muted col='col-2' />
            <InputV2 id='articulo' name='articulo' label='Artículo' muted col='col-6' />

            <InputV2 id='droga' name='droga' label='Droga' muted col='col-4' />

            <InputV2 id='laboratorio' name='laboratorio' label='Laboratorio' muted col='col-4' />

            <InputV2 id='sku' name='sku' label='SKU' muted col='col-4' />
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => exportData()}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-file-earmark-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant="secondary"
                onClick={resetForm}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter