
import { InputBS, SelectField, SwitchField, TypeaheadField } from 'components';
import { useFormikContext } from 'formik';
import { useAlfabeta, useArticles, useProducts } from 'hooks';
import { AlfabetaModal } from '../../AlfabetaModal'
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GtinModal } from './GtinModal';

const GeneralForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const {distributionChannelOptions} = useProducts();  
  const [lblAlfabeta, setLblAlfabeta] = useState('Vincular con alfabeta')
  const [showAlfabeta, setShowAlfabeta] = useState(false);
  const [showGtinModal, setShowGtinModal] = useState(false);
  const {  unitTypes   } = useAlfabeta()
  const { handleGetArticle, loading } = useArticles();

  const onHideGtinModal = async ()=>{
    const resp = await handleGetArticle({ product_id: values.product_id, product_var_id: values.id })
    if(resp){
      setFieldValue('gtines', resp.gtines)
    }
    setShowGtinModal(false)
  }

  useEffect(()=>{
    if(values){
     
      if(values.reg_id){
        setLblAlfabeta(`Vinculado con alfabeta: ${values.nom}`)
      }
      else{

      }
    }
  }, [values.reg_id])

  useEffect(()=>{
    console.log(values)
  }, [values])
    




  useEffect(()=>{
    setShowGtinModal(false)
  }, [])




  return (
    <div style={{ flex: '2', marginRight:"10px" }}>

      <AlfabetaModal show={showAlfabeta} onHide={()=>{setShowAlfabeta(false)}} values={values} setFieldValue={setFieldValue} />
      <GtinModal show={showGtinModal} onHide={onHideGtinModal} values={values} loading={loading}/>

      <div className="row mb-5">
          <div className="col"></div>
          {values?.parent_product && <div className="col text-end">
            <span>{lblAlfabeta}<i className='fa fa-link text-primary ms-3 cursor-pointer' onClick={()=>{setShowAlfabeta(true)}}></i></span>
          </div>}
      </div>

      <div className="row mb-5">
          <div className="col"><InputBS  id='name' name='name' label="Nombre artículo" disabled={!values.parent_product} /></div>
          <div className="col"><InputBS  id='presentation' name='presentation' label="Presentacion" disabled={!values.parent_product}/></div>
          <div className="col"><InputBS  id='unit' name='unit' label="Unidades" disabled={!values.parent_product} /></div>
      </div>


    <div className="row mb-5">
        <div className="col-8">
          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <InputBS id="gtines" name="gtines" label="GTIN" maxLength={14} disabled={values.id} />
            </div>
            
            { values?.id && 
              <button type="button" className="btn btn-primary ms-2" disabled={!values.parent_product || !values.id} style={{ height: "38px" }} onClick={()=>setShowGtinModal(true)}>
                <i className="fas fa-edit mb-2 ms-2"></i>
              </button>
            }

          </div>
        </div>
        {/* <div className="col"><InputBS  id='gtin' name='gtin' label="GTIN" maxLength={14} disabled={!values.parent_product}/></div> */}
        <div className="col-4"><InputBS  id='sku' name='sku' label="SKU" maxLength={30} disabled={!values.parent_product}/></div>

    </div>

    <div className="row">
        <div className="col-3" style={{paddingTop:"37px",paddingBottom:"25px"}}>
            <div className="d-flex">
              <SwitchField  id='traced' name='traced'/> <span style={{ fontSize: '1.1rem' }}>Producto trazable</span>
            </div>
        </div>
        <div className="col-3" style={{paddingTop:"37px",paddingBottom:"25px"}}>
            <div className="d-flex">
              <SwitchField  id='refrigerated' name='refrigerated'/> <span style={{ fontSize: '1.1rem' }}>Requiere heladera</span>
            </div>
        </div>
        <div className="col-3" style={{paddingTop:"37px",paddingBottom:"25px"}}>
            <div className="d-flex">
              <SwitchField  id='imported' name='imported'/> <span style={{ fontSize: '1.1rem' }}>Producto Importado</span>
            </div>
        </div>
        <div className="col-3" style={{paddingTop:"37px",paddingBottom:"25px"}}>
            <div className="d-flex">
            <SwitchField  id='score_line_cancelled' name='score_line_cancelled'/> <span style={{ fontSize: '1.1rem' }}>Troquel anulado</span>
            </div>
        </div>
         
        <div className="col-3" style={{paddingTop:"37px",paddingBottom:"25px"}}>
            <div className="d-flex">
            <SwitchField  id='en_falta' name='en_falta'/> <span style={{ fontSize: '1.1rem' }}>En falta por el laboratorio</span>
            </div>
        </div>
          
          
    </div> 


  </div>
  );
};


export default GeneralForm;
