import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'
import moment from 'moment'

import { useClickOutside, useTransactionalDocuments, useReports, useOutOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import useGetColumns from './columns'

import { labelColor } from 'pages/Sells/Orders/List'

export const tipoEntregaLabelColor = (tipoEntrega) => {
  switch (tipoEntrega) {
    case 'No':
      return 'estado-gris';
    case 'A cliente':
      return 'estado-amarillo';
    case 'A paciente':
      return 'estado-azul';
    case 'Farmacia':
      return 'estado-verde';
    default:
      return 'estado-gris';
  }
}

const ESTADOS_ORDEN = {
  GENERADO: 1,
  PENDIENTE_INGRESO: 2,
  INGRESO_PARCIAL: 3,
  VENCIDO: 4,
  CANCELADO: 5,
  COMPLETO: 6,
  BORRADOR: 7,
  AUTORIZADO: 8,
  CONFIRMADO: 9,
  COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  RECHAZADO: 12,
  ERROR_TRAZA: 13,
};

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  issue_date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  issue_date_to: moment().format('YYYY-MM-DD'),
}

const VentasReport = () => {
  const { loading, handleGetVentasReport, handleGetVentasDetalleReport } = useReports()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0);

  const { loading: loadingTD, handlePrintFactura } = useTransactionalDocuments()

  const { loading: loadingOO, handlePrintWaybill } = useOutOrders()

  const { COLUMNS } = useGetColumns()
  const [columns, setColumns] = useState(COLUMNS)
  const [conDetalle, setConDetalle] = useState(false)

  const getReport = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      const response = await handleGetVentasReport(params)
      const data = response.result.map(d => ({
        ...d,
        label_color: labelColor(d.EstadoPedidoID),
        entrega_label_color: tipoEntregaLabelColor(d.EntregaEspecial),
      }))

      setData(data);
      setTotalCount(response.metadata.count);
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetVentasReport, queryMD])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    if (currentRow?.remito_id) {
      actions.push({
        label: "Descargar remito",
        icon: "bi bi-download text-primary",
        action: downloadRemito
      })
    }

    if (currentRow?.ComprobanteId && currentRow?.NroComp) {
      actions.push({
        label: 'Descargar factura',
        icon: 'bi bi-download text-primary',
        action: downloadDocument
      })
    }

    return actions
  }

  const downloadRemito = async() => {
    if (currentRow) {
      try {
        let copias = 2
        const response = await handlePrintWaybill(currentRow.OrdenEgreso, copias)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Remito descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${currentRow.remito_id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const downloadDocument = async () => {
    if (currentRow) {
      try {
        const response = await handlePrintFactura(currentRow?.CodigoInternoComprobante, currentRow?.ComprobanteId)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Comprobante descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `comprobante-${currentRow.ComprobanteId}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          toast.error('Error al descargar el comprobante')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }
  /* Fin menu contextual */

  const cols = useMemo(() => {
    const c = [...columns]

    const hasActionsColumns = columns.some(c => c.id == 'actions')

    if (!hasActionsColumns) {
      c.push({
        Header: '',
        id: 'actions',
        label: '',
        className:'col-icons',
        accessor: (row) =>
          true > 0 && (
            <i
              ref={threeDotsRef}
              id='threeDots'
              className="bi bi-three-dots-vertical"
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={event => handleContextMenu(event, row)}
            />
          ),
        visible: true,
        notHide: true,
      })
    }

    return c
  }, [columns, threeDotsRef, handleContextMenu])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    helix.register()
  }, [])

  useEffect(() => {
    getReport()
  }, [getReport])

  return (
    <>
      <TableContainer
        title='Reporte de Ventas'
        filter={<Filter queryMD={queryMD} setQueryMD={setQueryMD} setConDetalle={setConDetalle} />}
        filterColumns
        columns={cols}
        setColumns={setColumns}
      >
        {(loading || loadingTD || loadingOO) && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }} />}

        <CustomTable
          columns={cols.filter(c => c.visible !== false)}
          data={data}
          paginationOptions={paginationOptions}
          queryMD={queryMD}
          setQueryMD={setQueryMD}
        />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default VentasReport
