import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useOutOrders, usePedidos } from 'hooks'

import { nombreEvento } from './utils'

import './styles.css'

const EventosModal = ({ show, onClose, pedido = false }) => {
  const { id } = useParams()

  const { handleObtenerEventosPedido } = usePedidos()
  const { handleObtenerEventosOrdenEgreso } = useOutOrders()

  const [eventos, setEventos] = useState([])

  const obtenerEventos = useCallback(async () => {
    try {
      let response = null

      if (pedido) {
        response = await handleObtenerEventosPedido(id)
      } else {
        response = await handleObtenerEventosOrdenEgreso(id)
      }
      const data = response.data.result

      setEventos(data)
    } catch (error) {
      toast.error(error.message)
      setEventos([])
    }
  }, [id, handleObtenerEventosPedido, handleObtenerEventosOrdenEgreso])

  const handleClose = () => {
    setEventos([])
    onClose()
  }

  useEffect(() => {
    if (show) {
      obtenerEventos()
    }
  }, [show, obtenerEventos])

  if (!show) return null

  return (
    <Modal show={show} onHide={handleClose} size='md' backdrop='static' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Eventos {pedido ? 'del Pedido' : 'de la Orden de egreso'} #{id}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="timeline-container">
          {eventos.map((evento, index) => (
            <div key={evento.id} className="timeline-item">
              <div className="timeline-icon" />

              <div className="card timeline-card">
                <div className="card-body p-3">
                  <h5 className="card-title mb-1">
                    {nombreEvento(pedido, evento.event_type_name)}
                  </h5>

                  <p className='card-text mt-1 mb-0'>
                    Usuario: {evento.user_name}
                  </p>

                  <small className="text-muted">
                    {moment(evento.created_at).format('DD/MM/YYYY HH:mm')}
                  </small>

                  <p className="card-text mt-2 mb-0">
                    {evento?.description
                      ? evento.description
                      : <i>Sin descripción</i>
                    }
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' variant='secondary' onClick={handleClose}>
          <i className='bi bi-slash-circle' />
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EventosModal