import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AssignmentOutlined, Diversity3Outlined, SellOutlined, MonetizationOnOutlined, ImportContactsOutlined, GroupOutlined } from '@mui/icons-material'

import { useCustomerSuppliers } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import { initialValues, schema, dataAdapter } from './constants'
import { GeneralTab, ShipmentAddressesTab, CustomersTab, SellingsTab, SuppliersTab, TabCreditos } from './partials'

import routes from 'app/routing/routes'

const AddCustomerSupplier = () => {
  const navigate = useNavigate()
  const submitRef = useRef(null)

  const { handleCreateCustomerSupplier } = useCustomerSuppliers()
  const [creando, setCreando] = useState(false)

  const handleCreate = async (customer_supplier) => {
    setCreando(true)

    try {
      if (!Boolean(customer_supplier.is_customer) && !Boolean(customer_supplier.is_supplier) && !Boolean(customer_supplier.is_distributor) && !Boolean(customer_supplier.cliente_para_remitir)) {
        toast.error('Por favor, seleccione una relación comercial')
        return
      }
      if (!customer_supplier.address?.route) {
        toast.error('Por favor, ingrese la dirección comercial del cliente/proveedor')
        return
      }
      if (Boolean(customer_supplier.is_customer) && customer_supplier.seller_id == '') {
        toast.error('Por favor, seleccione un vendedor en la pestaña Ventas')
        return
      }

      const data = dataAdapter(customer_supplier)
      const response = await handleCreateCustomerSupplier(data, 'token')
      toast.success(response.message)
      navigate(`${routes.CUSTOMER_SUPPLIERS}/${response.id}`)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setCreando(false)
    }
  }

  return (
    <>
      <DetailContainer
        title='Crear Cliente/Proveedor'
        buttons={
          <div className='d-flex align-items-center justify-content-center'>
            <Button className='me-3' variant='secondary' type='button' onClick={() => navigate(-1)}>
              <i className="ki-duotone ki-left" />
              Volver
            </Button>

            <Button variant='primary' type='button' onClick={() => submitRef.current.click()} disabled={creando}>
              <i className="bi bi-save me-2" />
              Guardar
            </Button>
          </div>
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={handleCreate}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form className="d-flex flex-column">
                <TabsContainer
                  initialValue='general'
                  tabs={[
                    { value: 'general', label: 'General', icon: <AssignmentOutlined />, show: true },
                    { value: 'shipment-addresses', label: 'Direcciones de entrega', icon: <ImportContactsOutlined />, show: true },
                    { value: 'customers', label: 'Cliente', icon: <Diversity3Outlined />, show: Boolean(values.is_customer), errors: errors.billing_type_id || errors.price_update_id },
                    { value: 'sellings', label: 'Ventas', icon: <SellOutlined />, show: Boolean(values.is_customer), errors: Boolean(values.is_customer) && values.seller_id == ''},
                    { value: 'creditos', label: 'Créditos', icon: <MonetizationOnOutlined />, show: Boolean(values.is_customer), errors: Boolean(values.is_customer) && values.seller_id == ''},
                    { value: 'suppliers', label: 'Proveedores', icon: <GroupOutlined />, show: values.is_distributor },
                  ]}
                  tabsContent={[
                    <GeneralTab />,
                    <ShipmentAddressesTab />,
                    Boolean(values.is_customer) && <CustomersTab />,
                    (values.is_customer || (values.is_customer && values.is_supplier)) && <SellingsTab />,
                    (values.is_customer || (values.is_customer && values.is_supplier)) && <TabCreditos creando={creando} />,
                    values.is_distributor && <SuppliersTab />,
                  ]}
                />

                <div className='separator my-8' />

                <div className='d-flex justify-content-end'>
                  <Button ref={submitRef} variant='primary' type='submit' disabled={creando}>
                    <i className='bi bi-save me-2' />
                    Guardar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default AddCustomerSupplier