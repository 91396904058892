import { useState, useEffect, useCallback } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs';

import { useSalesOrders } from 'hooks/Sales'
import { Input, SelectField } from 'components'

import { SalesOrderStatusI, OrderTypeI } from 'interfaces'

const initialValues = {
  id: '',
  text: '',
  profitability_auth_sent_date_from: '',
  profitability_auth_sent_date_to: '',
  profitability_greater_than: '',
  sales_order_status_id: [SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA],
}

const Filter = ({ query, setQuery, setSelectedRows, loading }) => {
  const { loading: loadingSO, handleGetPendingRent, handleGetPendingRentDetail, handleGetSalesOrderStatus } = useSalesOrders();
  const [formikInitialValues] = useState(initialValues)
  const [status, setStatus] = useState([])

  const filterData = (values) => {
    let params = {
      ...query,
      id: values?.id ? values.id : undefined,
      text: values?.text ? values.text : undefined,
      profitability_auth_sent_date_from: values.profitability_auth_sent_date_from != '' ? values.profitability_auth_sent_date_from : undefined,
      profitability_auth_sent_date_to: values.profitability_auth_sent_date_to != '' ? values.profitability_auth_sent_date_to : undefined,
      profitability_auth_greater_than: values.profitability_auth_greater_than !== '' ? values.profitability_auth_greater_than : undefined,
      sales_order_status_id: values.sales_order_status_id?.length > 0 ? values.sales_order_status_id : undefined,
    }

    setQuery({ ...params, pageNumber: 1, pageSize: 10 })
    setSelectedRows([])
  }

  const exportData = async (values) => {
    try {
      let params = {
        ...query,
        ...values,
        id: values?.id ? values.id : undefined,
        text: values?.text ? values.text : undefined,
        profitability_auth_sent_date_from: values.profitability_auth_sent_date_from != '' ? values.profitability_auth_sent_date_from : undefined,
        profitability_auth_sent_date_to: values.profitability_auth_sent_date_to != '' ? values.profitability_auth_sent_date_to : undefined,
        profitability_auth_greater_than: values.profitability_auth_greater_than !== '' ? values.profitability_auth_greater_than : undefined,
        sales_order_status_id: values.sales_order_status_id?.length > 0 ? values.sales_order_status_id : undefined,
        pageNumber: 1,
        pageSize: undefined
      }

      const response = await handleGetPendingRentDetail(params)

      if (response?.data?.result) {
        console.log(response.data.result)

        const datos = response.data.result.map(d => ({
          ...d,
          RentaEspecial : d?.RentaEspecial,
          RentaLista : d?.RentaLista,
          RentaListaPedido : d?.RentaListaPedido,
          RentaUltimaCompra : d?.RentaUltimaCompra,
          PedidoFecha: moment(d.PedidoFecha).format('DD/MM/YYYY'),
          FechaEnvioAutorizacion: d.FechaEnvioAutorizacion ? moment(d.FechaEnvioAutorizacion).format("DD/MM/YYYY HH:mm") : '',
          FechaEstimadaEntrega: d?.FechaEstimadaEntrega ? moment(d.FechaEstimadaEntrega).format('DD/MM/YYYY') : '',
        }))

        var ws = XLSX.utils.json_to_sheet(datos)

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Rentabilidad")
        XLSX.writeFile(wb, `Rentabilidad ${moment().format("YYYYMMDD-HHmm")}.xlsx` )
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getSalesOrderStatus = useCallback(async () => {
    try {
      const response = await handleGetSalesOrderStatus()
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setStatus(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetSalesOrderStatus])

  useEffect(() => {
    getSalesOrderStatus()
  }, [getSalesOrderStatus])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id' name='id' />
              <p className='text-muted'>ID Pedido</p>
            </div>

            <div className='col-6'>
              <Input id='text' name='text' />
              <p className='text-muted'>Cliente</p>
            </div>

            <div className='col-2'>
              <Input id='profitability_auth_sent_date_from' name='profitability_auth_sent_date_from' type='datetime-local' /><p className='text-muted'>Desde</p>
            </div>

            <div className='col-2'>
              <Input id='profitability_auth_sent_date_to' name='profitability_auth_sent_date_to' type='datetime-local' /><p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <Input id='profitability_auth_greater_than' name='profitability_auth_greater_than' /><p className='text-muted'>Rentabilidad mayor/igual a</p>
            </div>

            <div className='col-6'>
              <SelectField
                id='sales_order_status_id'
                name='sales_order_status_id'
                options={status}
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
                isMulti
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                className='me-3'
                onClick={() => exportData(values)}
                disabled={loading}
              >
                <i className="bi bi-file-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='submit'
                variant="primary"
                disabled={loading}
              >
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter