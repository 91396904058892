import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Dropdown, DropdownButton, Button, Form } from 'react-bootstrap';

import { useAuth } from 'app/modules/auth';

import { KTCard, KTCardBody } from '_metronic/helpers'
import { CreateButton, Loading } from 'components'

import './styles.sass'
import routes from 'app/routing/routes';

const TableContainer = ({
  title = '',
  subtitle,
  goTo,
  goToText,
  goToSecondary,
  goToSecondaryText,
  dropdownText,
  dropdownActions,
  dropdownDisabled=false,
  createButton = undefined,
  irVersion2 = false,
  volverVersion1 = false,
  initialQuery = {},
  setQuery,
  filter,
  loading = false,
  actions,
  doubleActions = false,
  filterColumns = false,
  columns = [],
  setColumns,
  children,
}) => {
  const { setCurrentUser } = useAuth()
  const navigate = useNavigate()

  // Estado para manejar si el dropdown está abierto o cerrado
  const [showDropdown, setShowDropdown] = useState(false)

  const [selectedColumns, setSelectedColumns] = useState(columns)

  // Manejar el cambio del checkbox sin cerrar el menú
  const handleColumnChange = (columnId, event) => {
    event.stopPropagation() // Evita que el click cierre el menú

    const updatedColumns = selectedColumns.map(col =>
      col.id === columnId ? { ...col, visible: !col.visible } : col
    )
    setSelectedColumns(updatedColumns)
    setColumns(updatedColumns)
  }

  const cambiarVersion = (ruta) => {
    setCurrentUser((prev) => ({
      ...prev,
      sales_orders: {
        id_text: '',
        order_type_id_filter: [],
        purchase_order: '',
        out_order_id: '',
        from_date: '',
        to_date: '',
        nombre: '',
        documento: '',
        customer_supplier_id: [],
        from_auth_date: '',
        to_auth_date: '',
        paciente: '',
        paciente_documento: '',
        patient_id: [],
        seller_id_filter: [],
        article_text: '',
        user_id_filter: [],
        grouped_status_id: [],
        sales_order_status_id_filter: [],
      }
    }))

    setQuery(initialQuery)
    navigate(ruta)
  }

  useEffect(() => {
    setSelectedColumns(columns)
  }, [columns])

  const columnsHideable = columns.filter(c => !c?.notHide)
  const columnThreshold = 5
  const groupedColumns = []
  for (let i = 0; i < columnsHideable.length; i += columnThreshold) {
    groupedColumns.push(columnsHideable.slice(i, i + columnThreshold))
  }

  return (
    <>
      <KTCard className="mb-8">
        <KTCardBody className={`d-flex justify-content-between align-items-center ${!title ? 'p-0' : ''}`}>
          {title && <h3 className='m-0' style={{height:"38px", lineHeight:"38px"}}>{title}</h3>}

          <div className='d-flex'>
            {goTo && goTo !== '' &&
              <Link to={goTo}>
                <CreateButton text={goToText ? goToText : 'Crear'} styles='me-4' />
              </Link>
            }

            {goToSecondary && goToSecondary !== '' &&
              <Link to={goToSecondary}>
                <CreateButton text={goToSecondaryText ? goToSecondaryText : 'Crear'} styles='w-auto' />
              </Link>
            }

            {createButton && createButton}

            {irVersion2 &&
              <Button
                type='button'
                variant='secondary'
                className={dropdownActions && dropdownActions.length ? 'me-3' : ''}
                onClick={() => cambiarVersion(routes.VENTAS_PEDIDOS)}
              >
                <i className='bi bi-2-square text-primary me-1' />
                Ir a V2
              </Button>
            }

            {volverVersion1 &&
              <Button
                type='button'
                variant='secondary'
                className={dropdownActions && dropdownActions.length ? 'me-3' : ''}
                onClick={() => cambiarVersion(routes.SALES_ORDERS)}
              >
                <i className='bi bi-1-square text-primary me-1' />
                Volver a V1
              </Button>
            }

            {dropdownActions && dropdownActions.length &&
              <DropdownButton
                title={
                  <>
                    <i className="bi bi-file-earmark-plus mb-1 me-2 fs-4" />
                    {dropdownText || 'Crear'}
                  </>
                }
                align={{ lg: 'end' }}
                disabled={dropdownDisabled}
              >
                {dropdownActions.map((a, idx)=>(
                  <Dropdown.Item key={idx} as={Link} to={a.href} state={a.state}>
                    <i className={`bi bi-${a.icon} pe-2`} />
                    {a.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            }
          </div>
        </KTCardBody>

        {filter &&
          <KTCardBody className='border-top'>
            {filter}
          </KTCardBody>
        }
      </KTCard>

      <Card>
        {(actions || filterColumns) &&
          <Card.Header className='d-flex justify-content-end align-items-center gap-2'>
            {actions &&
              <div>
                {doubleActions
                  ? <div className='w-100'>{actions}</div>
                  : <>{actions}</>
                }
              </div>
            }

            {filterColumns && (
              <DropdownButton
                show={showDropdown}
                onToggle={(isOpen) => setShowDropdown(isOpen)}
                variant="secondary"
                title={<i className="bi bi-sliders mb-1 me-2 fs-4" />}
                align={{ lg: 'end' }}
                className="my-dropdown-button"
              >
                <div className="my-dropdown-grid">
                  {groupedColumns.map((group, groupIdx) => (
                    <div key={groupIdx} className="my-dropdown-column">
                      {group.map((col, idx) =>
                        !Boolean(col?.notHide) &&
                        (
                          <Dropdown.Item
                            key={idx}
                            as="button"
                            onClick={(e) => handleColumnChange(col.id, e)}
                            className="d-flex align-items-center"
                          >
                            <Form.Check
                              id={col.id}
                              name={col.id}
                              type="checkbox"
                              label={col.label}
                              onChange={(e) => handleColumnChange(col.id, e)}
                              checked={col.visible}
                            />
                          </Dropdown.Item>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </DropdownButton>
            )}
          </Card.Header>
        }

        <Card.Body className='TableContainer'>
          {loading ? <Loading /> : children}
        </Card.Body>
      </Card>
    </>
  )
}

export default TableContainer
