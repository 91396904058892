import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const COLUMNS =  [
  {
    Header: <div className=''>Nro.</div>,
    id: 'id',
    label: 'Nro.',
    accessor: (row) => (
      <Tooltip text='Ver'>
        <Link to={`${routes.LOGISTIC_INCOME}/${row.id}`} state={{ in_request_id: row.in_request_id }} className='ml-2'>
          {row.id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
    visible: true,
    notHide: true,
  },
  {
    Header: <div className="text-center">Tipo</div>,
    id: "order_type_label",
    label: 'Tipo',
    accessor: (row) => (
      <div className='text-center'>
        {row.order_type_label}
      </div>
    ),
    hasOrder: true,
    visible: true,
  },
  {
    Header: <div className='text-center'>F. Creación</div>,
    id: 'created_at',
    label: 'F. Creación',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.created_at).format("DD/MM/YYYY")}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>F. Ingreso</div>,
    id: 'in_date',
    label: 'F. ingreso',
    accessor: (row) => (
      <div className='text-center'>
        {row?.in_date ? moment(row.in_date).format("DD/MM/YYYY") : '-'}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className="text-center">No. Pedido</div>,
    id: 'in_request_id',
    label: 'No. Pedido',
    accessor: (row) =>(
      <div className='text-center'>
        <Link to={
          row.order_type_name === 'PEDIDO_COMPRA'
            ? `${routes.PURCHASE_REQUEST_DETAIL}/${row.in_request_id}`
            : `${routes.INCOME_REQUEST_DETAIL}/${row.in_request_id}`
          }
        >
          {row.in_request_id}
        </Link>
      </div>
    ),
    hasOrder: true,
    visible: true,
  },
  {
    Header: <div className=''>Proveedor</div>,
    id: 'supplier_name',
    label: 'Proveedor',
    accessor: (row)=>(
      <div className=''>
        <strong className='m-0 p-0'>[{row.supplier_id}] {row.supplier_name}</strong>
        <p className='m-0 p-0 mt-2'>Documento: {row.supplier_documentation}</p>
      </div>
    ),
    hasOrder: true,
    visible: true,
  },
  {
    Header: <div className='text-center'>Remito</div>,
    id: 'waybill_number',
    label: 'Remito',
    accessor: (row) => (
      <div className='text-center'>
        {row?.waybill_number || <i>Sin remito</i>}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Factura</div>,
    id: 'supplier_invoice',
    label: 'Factura',
    accessor: (row) => (
      <div className='text-center'>
        {row?.supplier_invoice || <i>Sin factura</i>}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className=''>Nombre / Domicilio</div>,
    id: 'shipment_address_name',
    label: 'Nombre / Domicilio',
    accessor: (row)=>(
      <div className=''>
        {row?.shipment_address_id
          ?
            <>
              <strong className='m-0 p-0'>{row?.shipment_address_name}</strong>
              <p className='m-0 p-0 mt-2'>{row?.domicilio?.trim() || '-'}</p>
              <p className='m-0 p-0 mt-2'>GLN: {row?.shipment_address_gln || '-'}</p>
            </>
          : <i>Sin dirección</i>
        }
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className=''>Localidad</div>,
    id: 'localidad',
    label: 'Localidad',
    accessor: (row) => (
      <>
        {row?.localidad
          ? <p className='m-0 p-0'>{row?.localidad?.trim()}</p>
          : <i>Sin localidad</i>
        }
      </>
    ),
    visible: true,
  },
  {
    Header: <div className=''>Provincia</div>,
    id: 'provincia',
    label: 'Provincia',
    accessor: (row) => (
      <>
        {row?.provincia
          ? <p className='m-0 p-0'>{row?.provincia?.trim()}</p>
          : <i>Sin provincia</i>
        }
      </>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Artículos</div>,
    id: 'qty',
    label: 'Artículos',
    accessor: (row) => (
      <div className='text-center'>
        {row?.items || 0}

        {row?.detail &&
          <OverlayTrigger
            placement='top'
            overlay={
              <TooltipBS id={`tooltip-${row.id}`}>
                <div>
                  {row.detail.split(',').map((item, index) => (
                    <div key={index}>{item.trim()}</div>
                  ))}
                </div>
              </TooltipBS>
            }
          >
            <i className='bi bi-info-circle text-warning ms-2' />
          </OverlayTrigger>
        }
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className="text-center">Estado</div>,
    id: "order_status_label",
    label: 'Estado',
    className: 'col-status',
    accessor: (row) =>(
      <span className={ `badge ${row.label_color} w-100 justify-content-center fw-normal`}>
        {row.order_status_label}
      </span>
    ),
    visible: true,
  },
]

export {
  COLUMNS
}