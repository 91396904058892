import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useSalesOrders, usePedidos } from 'hooks'

import { SelectV2 } from 'components/v2'
import { TextareaBS } from 'components'
import { SalesOrderStatusI } from 'interfaces'

const initialValues = {
  id_motivo_cancelacion: 1,
  observaciones: '',
}

const CancelarPedidoModal = ({ show, onHide, idPedido, especial, parcial, anticipada }) => {
  const { handleUpdateSalesOrderStatus, handleCancelSalesOrderSpecial, handleCancelacionParcial } = useSalesOrders()
  const [cancelando, setCancelando] = useState(false)

  const { handleObtenerMotivosCancelacion } = usePedidos()
  const [cargandoMotivos, setCargandoMotivos] = useState(false)
  const [motivos, setMotivos] = useState([])

  const cancelarPedido = async (values) => {
    setCancelando(true)

    try {
      if (!values?.id_motivo_cancelacion) {
        toast.error('Debe elegir un motivo de cancelación')
        return
      }
      if (!values?.observaciones) {
        toast.error('Debe completar las observaciones / motivo de cancelación')
        return
      }

      let response

      if (!especial && !parcial) {
        response = await handleUpdateSalesOrderStatus(idPedido, SalesOrderStatusI.CANCELADO, values)
      } else if (especial) {
        response = await handleCancelSalesOrderSpecial(idPedido, values)
      } else if (parcial) {
        response = await handleCancelacionParcial(idPedido, values)
      }

      toast.success(response.data.message)
      onHide()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setCancelando(false)
    }
  }

  const obtenerMotivosCancelacion = async () => {
    setCargandoMotivos(true)

    try {
      const params = {
        activo: 1,
      }
      const response = await handleObtenerMotivosCancelacion(params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.motivo_label,
      }))

      setMotivos(data)
    } catch (error) {
      setMotivos([])
      toast.error(error.message)
    } finally {
      setCargandoMotivos(false)
    }
  }

  useEffect(() => {
    if (show) {
      obtenerMotivosCancelacion()
    }
  }, [show])

  return (
    <Modal show={show} onHide={onHide} backdrop='static' size='md' centered>
      <Modal.Header closeButton>
        <Modal.Title>Cancelación del Pedido #{idPedido}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={cancelarPedido}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row'>
                  <SelectV2
                    id='id_motivo_cancelacion'
                    name='id_motivo_cancelacion'
                    label='Motivo de cancelación'
                    options={motivos}
                    placeholder='Seleccionar motivo'
                    col='col-12'
                    disabled={cancelando || cargandoMotivos}
                  />
                </div>

                <div className='row mt-6'>
                  <div className='col-12'>
                    <TextareaBS id='observaciones' name='observaciones' label='Observaciones / Motivo' disabled={cancelando} />
                  </div>
                </div>

                {(especial || parcial) && <div className='separator mt-6' />}

                {especial &&
                  <p class='mt-2'>Esta cancelación también va a impactar en la Orden de egreso asociada al Pedido.</p>
                }

                {parcial &&
                  <>
                    <p class='mt-4'>La cancelación parcial puede llegar a cancelar las órdenes de egreso con el estado:</p>
                    <p class='m-0 p-0 fw-bold'>En revisión</p>
                    <p class='m-0 p-0 fw-bold'>Reserva parcial</p>
                    <p class='m-0 p-0 fw-bold'>Coordinación de entrega</p>
                    <p class='m-0 p-0 fw-bold'>Pendiente de preparación</p>
                  </>
                }

                {anticipada &&
                  <p class='mt-4 fst-italic'>Nota: Deberá hacer la NC manualmente si generó una factura anticipada</p>
                }

                <div className='separator mt-6' />

                <div className='row mt-6'>
                  <div className='col-12 text-end'>
                    <Button type='button' variant='secondary' onClick={onHide} className='me-3' disabled={cancelando}>
                      <i className='bi bi-slash-circle me-1' />
                      Cerrar
                    </Button>

                    <Button type='submit' variant='danger' disabled={cancelando}>
                      <i className='bi bi-x-circle me-1' />
                      Cancelar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CancelarPedidoModal