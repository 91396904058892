import React, { useEffect, useMemo, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import Select from 'react-select';
import { useArticles, useGetArticleDetail } from "hooks";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}


export function GtinModal({ show, onHide, values, loading}) {
    const [gtines, setGtines] = useState([]);
    const {handleGetGTIN, handleAddGTIN, handleDeleteGTIN} = useArticles();

    const fetchData = async() => {
        if(values?.id){
            const resp = await handleGetGTIN({product_var_id:values.id})
            console.log(resp)
            if(resp?.result){
                setGtines(resp.result)
            }
        }
        // const qs = {maker_id,family_id}
        // const _art = await handleGetArticles(qs)
        // if (_art && _art.result) {
        //   const _opt = _art.result.map(i=>({value:i.id, label:i.nom + ' ' + i.pres}))
        //   setOptionsArticle(_opt)
        // }
    }
    const deleteGTIN = async(data)=>{

        if(gtines?.length<=1){
            toast.error("No se puede dejar al artículo sin gtin")
            return
        }

        const resp = await Swal.fire({
        title: "Advertencia",
        text: `Confirma elimincación del GTIN ${data.gtin}?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si, eliminar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
        })

        if (resp.isConfirmed) {
            const transport = {
                product_var_id:values.id,
                gtin:data.gtin
            }
            try{
                await handleDeleteGTIN(transport)
                fetchData()
                toast.success("Gtin eliminado del artículo")
            }
            catch(err){
                console.log(err)
                toast.error("Error al eliminar GTIN")
            }
        }
    }
    const addGTIN = async()=>{
        console.log(values)
        let inputValue = '';
        const resp = await Swal.fire({
            title: "",
            text: ``,
            icon: "info",
            input:"text",
            inputValue,
            inputLabel: "Nuevo GTIN",
            inputAttributes: {
                maxlength: "14",
                autocomplete:"off"
            },
            inputValidator: (value) => {
                if (!/^\d+$/.test(value)) {
                    return "Ingresar un valor numérico!";
                }
            },
            preConfirm: (value) => {
                if (!value || value.length < 14) {
                    Swal.showValidationMessage("El GTIN debe tener 14 caracteres");
                    return false;
                }
                return value; 
            },
            showDenyButton: true,
            denyButtonText: "No",
            confirmButtonText: "Agregar",
            customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
            reverseButtons: true,
        })

        if (resp.isConfirmed) {
            const data = {
                product_var_id:values.id,
                gtin:resp.value
            }
            try{
                await handleAddGTIN(data)
                fetchData()
                toast.success("Gtin agregado correctamente")
            }
            catch(err){
                console.log(err)
                toast.error("Error al guardar GTIN")
            }
        }
    }

    useEffect(() => {
      if (show) {
        console.log(values)
        fetchData()
      }
    }, [show])

  return (
    <>
      <Modal  show={show} onHide={onHide} backdrop='static' enforceFocus={false} restoreFocus={false}>
        <Modal.Header closeButton>
          <h3>Valores de GTIN para el artículo {values?.name}</h3>
        </Modal.Header>

        <Modal.Body className="">
            {loading &&
                <div className='w-100 text-center'>
                    <div className='spinner-border text-success'></div>
                </div>
            }

            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table table-head-custom table-vertical-center" >
                            <thead>
                                <tr>
                                    <th>GTIN</th>
                                    <th style={{width:"80px", textAlign:"end"}}><i className='bi bi-plus cursor-pointer mr-3 text-success fs-2' title="Agregar GTIN" onClick={addGTIN}></i></th>
                                </tr>
                            </thead>
                            <tbody>
                            {gtines.map((data, index) => {
                                return <tr key={index} style={{borderTop: "1px solid #dee2e6"}}>
                                    <td className="pl-0"> {data.gtin || ""} </td>
                                    <td className="pl-0"> 
                                        <i className='bi bi-trash cursor-pointer mr-3 text-danger' title="Eliminar GTIN" onClick={()=>deleteGTIN(data)}></i> 
                                    </td>
                                </tr>;
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none" }}>
          <Button variant="secondary" onClick={onHide}>Salir</Button>
          {/* <Button type="button" variant="primary" onClick={handlePriceOnly}>Vincular solo precio</Button>
          <Button type="button" variant="primary" onClick={handleAllFields}>Vincular todo</Button> */}
        </Modal.Footer>
        </Modal>
    </>
  )
}