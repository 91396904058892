import { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useCustomerSuppliers } from 'hooks'

import { TableContainer } from 'containers'
import { ShipmentAddressModal, LinkShipmentAddressModal } from 'containers/Modals'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { ShipmentAddressesFilter, ShipmentAddressesColumns } from './'
import LinkShipmentAddressModalV2 from './LinkShipmentAddressModalV2'
import routes from 'app/routing/routes'

import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const ShipmentAddressesTab = ({ editing, handleEditing, handleGetCustomerSupplier }) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { values } = useFormikContext()
  const { COLUMNS } = ShipmentAddressesColumns()

  const [modal, setModal] = useState(false)
  const [shipmentAddressInfo, setShipmentAddressInfo] = useState(null)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [linkModal, setLinkModal] = useState(false)

  const {
    handleGetCustomerSupplierShipmentAddresses,
    handleAddCustomerSupplierShipmentAddress,
    handleLinkShipmentAddressToCustomerSupplier,
    handleUpdateCustomerSupplierShipmentAddress,
    handleDeleteCustomerSupplierShipmentAddress,
    handleGetCustomerSupplierDetailShipmentAddresses,
    handleLinkCustomerSupplierDetailShipmentAddressToVerifarma,
    handleUnlinkCustomerSupplierDetailShipmentAddressFromVerifarma,
  } = useCustomerSuppliers()
  const [shipmentAddresses, setShipmentAddresses] = useState([])

  const getShipmentAddresses = useCallback(async () => {
    try {
      let params = {
        sortField: 'id',
        sortOrder: 'DESC',
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      // const response = await handleGetCustomerSupplierShipmentAddresses(values.id, params)
      const response = await handleGetCustomerSupplierDetailShipmentAddresses(values.id, params)
      setShipmentAddresses(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setShipmentAddresses([])
      setTotalCount(0)
    }
  }, [handleGetCustomerSupplierShipmentAddresses, values.id, query])

  const addShipmentAddress = async (new_shipment_address) => {
    try {
      const data = {
        ...new_shipment_address,
        address: {
          ...new_shipment_address.address,
          origin: new_shipment_address.address.manual ? 'MANUAL' : 'GOOGLE',
        },
        agent_type_id: new_shipment_address.agent_type_id !== '' ? new_shipment_address.agent_type_id : null,
        gln: new_shipment_address.gln !== '' ? new_shipment_address.gln : null,
      }

      const response = await handleAddCustomerSupplierShipmentAddress(values.id, data, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setModal(false)
      handleEditing(false)
      handleGetCustomerSupplier()
      getShipmentAddresses()
    }
  }

  const linkShipmentAddress = async (shipment_address_id) => {
    try {
      const response = await handleLinkShipmentAddressToCustomerSupplier(values.id, shipment_address_id)
      toast.success("Dirección vinculada correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLinkModal(false)
      handleEditing(false)
      handleGetCustomerSupplier()
      getShipmentAddresses()
    }
  }

  const updateShipmentAddress = async (shipmentAddressId, values) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere actualizar la información de la dirección de entrega #${shipmentAddressId} del cliente/proveedor?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, actualizar',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        let data = {
          ...values,
        }

        const response = await handleUpdateCustomerSupplierShipmentAddress(values.id, shipmentAddressId, data)
        toast.success("Dirección actualizada correctamente", {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setModal(false)
      setShipmentAddressInfo(null)
      handleEditing(false)
      handleGetCustomerSupplier()
      getShipmentAddresses()
    }
  }

  const enableDisableShipmentAddress = async (shipmentAddressId) => {
    try {
      let data = shipmentAddresses.find(sa => sa.id === shipmentAddressId)

      let status = Boolean(data.is_enabled) ? 'deshabilitar' : 'habilitar'
      let msg = Boolean(data.is_enabled) ? 'deshabilitada' : 'habilitada'

      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere ${status} la dirección de entrega #${data.id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, ${status}`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        data = {
          ...data,
          is_enabled: !Boolean(data?.is_enabled),
        }

        await handleUpdateCustomerSupplierShipmentAddress(values.id, shipmentAddressId, data)
        toast(`Dirección de entrega ${msg} correctamente`, { type: data.is_enabled ? 'success' : 'info' })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleEditing(false)
      handleGetCustomerSupplier()
      getShipmentAddresses()
    }
  }

  const deleteShipmentAddress = async (shipmentAddressId) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere desvincular la dirección de entrega #${shipmentAddressId} del cliente/proveedor?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, desvincular',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteCustomerSupplierShipmentAddress(values.id, shipmentAddressId)
        toast.success("Dirección desvinculada correctamente", {theme: "colored"})
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleEditing(false)
      handleGetCustomerSupplier()
      getShipmentAddresses()
    }
  }

  const linkShipmentAddressToVerifarma = async (shipment_address_id) => {
    try {
      const response = await handleLinkCustomerSupplierDetailShipmentAddressToVerifarma(values.id, shipment_address_id)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getShipmentAddresses()
    }
  }

  const unlinkShipmentAddressFromVerifarma = async (shipment_address_id) => {
    try {
      const response = await handleUnlinkCustomerSupplierDetailShipmentAddressFromVerifarma(values.id, shipment_address_id)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getShipmentAddresses()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Editar',
      icon: 'bi bi-pencil-square text-primary',
      action: () => {
        let data = {
          ...currentRow,
          observations: currentRow?.observations ? currentRow.observations : '',
        }
        setShipmentAddressInfo(data)
        setModal(true)
      }
    })

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.SHIPMENT_ADDRESSES}/${currentRow?.id}`)
    })

    actions.push({
      label: Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar',
      icon: Boolean(currentRow?.is_enabled) ? "bi bi-x-circle text-warning" : "bi bi-check-circle text-success",
      action: () => enableDisableShipmentAddress(currentRow?.id)
    })

    actions.push({
      label: "Desvincular",
      icon: "bi bi-trash text-danger",
      action: () => deleteShipmentAddress(currentRow?.id)
    })

    if (currentRow?.verifarma == 1) {
      actions.push({
        label: 'Desvincular de Verifarma',
        icon: 'bi bi-box-arrow-right text-danger',
        action: () => {
          console.log('customer_supplier_id: ', values.id, 'shipment_address_id: ', currentRow?.id)
          unlinkShipmentAddressFromVerifarma(currentRow?.id)
        }
      })
    } else {
      actions.push({
        label: 'Vincular con Verifarma',
        icon: 'bi bi-box-arrow-in-left text-success',
        action: () => {
          console.log('customer_supplier_id: ', values.id, 'shipment_address_id: ', currentRow?.id)
          linkShipmentAddressToVerifarma(currentRow?.id)
        }
      })
    }

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getShipmentAddresses()
  }, [getShipmentAddresses])

  return (
    <>
      <ShipmentAddressModal
        show={modal}
        onClose={() => {
          setShipmentAddressInfo(null)
          setModal(false)
        }}
        values={shipmentAddressInfo}
        onSave={values => {
          shipmentAddressInfo
            ? updateShipmentAddress(shipmentAddressInfo.id, values)
            : addShipmentAddress(values)
        }}
        ours={false}
      />

      <LinkShipmentAddressModalV2
        show={linkModal}
        onClose={() => {
          setLinkModal(false)
          getShipmentAddresses()
        }}
      />

      <div className='row mb-3'>
        <div className='col text-end'>
          <Button
            variant='outline-success'
            onClick={() => setLinkModal(true)}
            className={`BSButton ${currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR, RoleI.COMPRAS].includes(r.id)) ? 'me-4' : ''}`}
          >
            Relacionar dirección existente
          </Button>

          {currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR, RoleI.COMPRAS].includes(r.id)) &&
            <Button className='BSButton' variant='outline-primary' onClick={() => setModal(true)}>Añadir dirección de entrega</Button>
          }
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ShipmentAddressesFilter handleData={setShipmentAddresses} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />

          <TableContainer
            // filter={<ShipmentAddressesFilter handleData={setShipmentAddresses} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />}
          >
            <CustomTable columns={columns} data={shipmentAddresses || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
          </TableContainer>

          <ContextMenu
            showContextMenu={showContextMenu}
            setShowContextMenu={setShowContextMenu}
            actions={availableActions()}
            currentPos={currentPos}
            ref={contextRef}
          />
        </div>
      </div>
    </>
  )
}

export default ShipmentAddressesTab