import * as Yup from 'yup'
import { formatInputDate } from 'utilities'

const googleAddress = {
  manual: false,
  place_id: '',
  route: '',
  street_number: '',
  floor: '',
  sublocality_level_1: '',
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  locality: '',
  postal_code: '',
  postal_code_suffix: '',
  country: '',
  latitude: 0,
  longitude: 0,
  google_result: '',
  origin: '',
  formatted_address: '',
  locality_custom: '',
  custom_address_level1_label: '',
  custom_address_level1: '',
  custom_address_level2_label: '',
  custom_address_level2: '',
}

const initialValues = {
  // -- General
  id: 0,
  is_customer: false,
  is_supplier: false,
  is_distributor: false,
  cliente_para_remitir: false,
  cod_abrev: '',
  documentation_type_id: '',
  documentation: '',
  vat_condition_id: '',
  business_name: '',
  enrollment_date: '',
  type_id: '',
  payment_condition_id: '',
  is_big_company: false,
  company_system: 'SCA',
  billing_type_id: '',
  price_update_id: '',
  address: googleAddress,
  email_notifications: '',
  number_notifications_suffix: '',
  number_notifications: '',
  observations: '',
  is_enabled: 0,
  updated_at: '',
  cancel_date: '',
  // -- Direcciones de entrega
  shipment_addresses: [],
  // -- Ventas
  seller_id: 0,
  comission: 0,
  credit: 0,
  suppliers: [],
  // -- Pacientes
  patients: [],
  //
  de_blister: false,
  rnos: '',
  id_autorizacion_credito: '',
}

const directionSchema = Yup.object().shape({
  manual: Yup.boolean().default(false),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  street: Yup.string(),
  route: Yup.string(),
  street_number: Yup.string(),
  postal_code: Yup.string(),
  floor: Yup.string(),
  custom_address_level1: Yup.string(),
  custom_address_level2: Yup.string(),
  administrative_area_level_1: Yup.string(),
})

const schema = Yup.object().shape({
  // -- General
  is_customer: Yup.boolean().default(false),
  is_supplier: Yup.boolean().default(false),
  is_distributor: Yup.boolean().default(false),
  cliente_para_remitir: Yup.boolean().default(false),
  cod_abrev: Yup.number().max(4294967295, 'El código de abreviatura debe ser menor o igual a 4294967295'),
  business_name: Yup.string().required('* Ingrese una razón social'),
  documentation_type_id: Yup.string().required('* Seleccione el tipo de documento').nonNullable(),
  documentation: Yup.string()
    .required('* Ingrese un documento').nonNullable()
    .matches(/^[0-9]*$/, '* El documento solo puede contener números')
    .test('custom-validation', '* Longitud y formato incorrectos para el tipo de documento', function(value) {
      const { documentation_type_id } = this.parent;

      if (documentation_type_id === '1' && (value.length !== 8 || !/^[0-9]+$/.test(value))) {
        throw this.createError({ message: '* El DNI debe contener 8 caracteres' });
      }

      if (documentation_type_id === '2' && (value.length !== 11 || !/^[0-9]+$/.test(value))) {
        throw this.createError({ message: '* El CUIT debe contener 11 caracteres' });
      }

      return true;
    }),
  vat_condition_id: Yup.number().required('* Seleccione el tipo de condición frente al IVA').nonNullable(),
  enrollment_date: Yup.string().required('* Ingrese una fecha de alta'),
  type_id: Yup.string().required('* Seleccione un tipo'),
  payment_condition_id: Yup.string().required('* Seleccione una condición de pago').nonNullable(),
  // billing_type_id: Yup.number().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Seleccione una facturación').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  // price_update_id: Yup.number().when(['is_customer'], {
  //   is: (is_customer) => Boolean(is_customer),
  //   then: (schema) => schema.required('* Seleccione una opción').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  direction: directionSchema,
  email_notifications: Yup.string(),
  number_notifications_suffix: Yup.string().matches(/^(\+)?[0-9\s]+$/, '* El sufijo debe ser numérico y puede incluir el signo + al principio'),
  number_notifications: Yup.string().min(10, '* El número de celular debe tener al menos 10 dígitos').label('Celular'),
  // -- Ventas
  // seller_id: Yup.string().when(['is_customer', 'is_supplier'], {
  //   is: (is_customer, is_supplier) => Boolean(is_customer) || (Boolean(is_customer) && Boolean(is_supplier)),
  //   then: (schema) => schema.required('* Seleccione un vendedor').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  // comission: Yup.number().when(['is_customer', 'is_supplier'], {
  //   is: (is_customer, is_supplier) => Boolean(is_customer) || (Boolean(is_customer) && Boolean(is_supplier)),
  //   then: (schema) => schema.required('* Ingrese una comisión'),
  //   otherwise: (schema) => schema,
  // }),
  // credit: Yup.number().when(['is_customer', 'is_supplier'], {
  //   is: (is_customer, is_supplier) => Boolean(is_customer) || (Boolean(is_customer) && Boolean(is_supplier)),
  //   then: (schema) => schema.required('* Ingrese un crédito'),
  //   otherwise: (schema) => schema,
  // }),
})

const addShipmentAddressInitialValues = {
  name: '',
  address: googleAddress,
  contact: {
    name: '',
    email: '',
    has_notifications: false,
    phone: '',
    mobile_suffix: '' ,
    mobile: '',
    observations: '',
  },
  observations: '',
  has_traceability: false,
  agent_type_id: '',
  gln: '',
  integrates_verifarma: false,
  is_ours: false,
  is_enabled: true,
}

const addShipmentAddressSchema = Yup.object().shape({
  name: Yup.string().required('* Ingrese un nombre para la dirección'),
  direction: directionSchema,
  contact: Yup.object().shape({
    name: Yup.string().required('* Ingrese un nombre para el contacto'),
    email: Yup.string().email('* Ingrese un email válido'),
    has_notifications: Yup.boolean().default(false),
    phone: Yup.string(),
    mobile: Yup.string(),
    observations: Yup.string(),
  }),
  observations: Yup.string(),
  has_traceability: Yup.boolean().default(false),
  agent_type_id: Yup.string().when('has_traceability', {
    is: true,
    then: (schema) => schema.required('* Seleccione un tipo de agente').nonNullable(),
    otherwise: (schema) => schema,
  }),
  gln: Yup.string().when('has_traceability', {
    is: true,
    then: (schema) => schema.required('* Indique el GLN').nonNullable(),
    otherwise: (schema) => schema,
  }),
  integrates_verifarma: Yup.boolean().default(false),
})

const addSupplierInitialValues = {
  supplier_id: '',
  supplier_business_name: '',
  supplier_documentation: '',
}

const addPatientInitialValues = {
  id: '',
  documentation_type_id: '',
  documentation_type_name: '',
  documentation: '',
  first_name: '',
  last_name: '',
}

const addPatientSchema = Yup.object().shape({
  id: Yup.number().required('* Seleccione un paciente').nonNullable(),
  // documentation_type_id: Yup.string().required('* Seleccione el tipo de documento').nonNullable(),
  // documentation: Yup.string().required('* Ingrese un documento'),
  first_name: Yup.string().required('* Ingrese un nombre'),
  last_name: Yup.string().required('* Ingrese un apellido'),
})

const dataAdapter = (data) => {
  let values = {
    ...data,
    is_customer: Boolean(data.is_customer),
    is_supplier: Boolean(data.is_supplier),
    is_distributor: Boolean(data.is_distributor),
    cliente_para_remitir: Boolean(data.cliente_para_remitir),
    billing_type_id: data?.billing_type_id || '',
    is_big_company: Boolean(data.is_big_company),
    company_system: data?.company_system !== '' ? data.company_system : 'SCA',
    price_update_id: data?.price_update_id || '',
    address: {
      ...data.address,
      manual: Boolean(data.address.origin == 'MANUAL'),
    },
    de_blister: Boolean(data.de_blister),
    rnos: data?.rnos || '',
    id_autorizacion_credito: data?.id_autorizacion_credito,
  }

  values.shipment_addresses = data.shipment_addresses?.sort((a, b) => a.id - b.id)
  // values.address.manual = false
  values.enrollment_date = formatInputDate(data.enrollment_date)
  values.updated_at = formatInputDate(data.updated_at)
  values.cancel_date = formatInputDate(data.cancel_date)
  values.comission = data?.comission ? data.comission : 0
  values.credit = data?.credit ? data.credit : 0

  return values;
}

const updateDataAdapter = (data) => {
  let values = {
    ...data,
    is_customer: Boolean(data?.is_customer),
    is_supplier: Boolean(data?.is_supplier),
    is_distributor: Boolean(data?.is_distributor),
    cliente_para_remitir: Boolean(data.cliente_para_remitir),
    is_big_company: Boolean(data?.is_big_company),
    company_system: Boolean(data?.is_big_company) ? data.company_system : null,
    billing_type_id: Boolean(data?.is_customer) ? data.billing_type_id : null,
    price_update_id: Boolean(data?.is_customer) ? data.price_update_id : null,
    seller_id: data?.seller_id ? data.seller_id : null,
    province_id: data?.province_id ? data.province_id : null,
    address: {
      ...data.address,
      origin: data.address?.id ? data.address.origin : data.address.manual ? 'MANUAL' : 'GOOGLE',
      latitude: data.address?.latitude ? data.address.latitude : 0,
      longitude: data.address?.longitude ? data.address.longitude : 0,
    },
    de_blister: Boolean(data?.de_blister),
    rnos: data?.rnos || null,
    id_autorizacion_credito: data?.id_autorizacion_credito,
  }

  delete values.documentation_type_name;
  delete values.type_name;
  delete values.payment_condition_name;
  delete values.shipment_addresses;
  delete values.seller;
  delete values.patients;
  delete values.updated_at;
  delete values.cancel_date;
  delete values.vat_condition_label;
  delete values.vat_condition_prefix;
  delete values.billing_type_label
  delete values.price_update_label

  return values
}

export {
  initialValues,
  schema,
  addShipmentAddressInitialValues,
  addShipmentAddressSchema,
  addPatientInitialValues,
  addPatientSchema,
  dataAdapter,
  updateDataAdapter,
  addSupplierInitialValues,
}