import { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx/xlsx.mjs'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useRequest } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { PurchaseHistoryDialog } from './PurchaseHistoryDialog'
import { COLUMNS } from './columns'

import routes from 'app/routing/routes'
import { helix } from 'ldrs'
import { OrderTypeI, RoleI } from 'interfaces'

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC',
  order_type_id_filter: [1],
  // order_type_id: 1,
}

export const ESTADOS_PEDIDO_COMPRA = {
  GENERADO: 1,
  PENDIENTE_INGRESO: 2,
  INGRESO_PARCIAL: 3,
  VENCIDO: 4,
  CANCELADO: 5,
  COMPLETO: 6,
  BORRADOR: 7,
  AUTORIZADO: 8,
  CONFIRMADO: 9,
  COMPLETO_PARCIAL: 10,
  INGRESADO: 11,
  RECHAZADO: 12,
  ERROR_TRAZA: 13
};

const tipoOrden = (order_type_id) => {
  let tipo = ''

  switch (order_type_id) {
    case OrderTypeI.PEDIDO_COMPRA:
      tipo = 'compra'
      break
    case OrderTypeI.PEDIDO_INGRESO:
      tipo = 'ingreso'
      break
    case OrderTypeI.PEDIDO_DEVOLUCION:
      tipo = 'devolución'
      break
    default:
      tipo = ''
      break
  }

  if (tipo?.trim() !== '') {
    return ` de ${tipo}`
  } else {
    return ''
  }
}

export const labelColor = (order_status_id) => {
  switch (order_status_id) {
    case ESTADOS_PEDIDO_COMPRA.GENERADO:
    case ESTADOS_PEDIDO_COMPRA.PENDIENTE_INGRESO:
      return "estado-amarillo";
    case ESTADOS_PEDIDO_COMPRA.BORRADOR:
      return "estado-gris";
    case ESTADOS_PEDIDO_COMPRA.COMPLETO_PARCIAL:
    case ESTADOS_PEDIDO_COMPRA.COMPLETO:
      return "estado-verde";
    case ESTADOS_PEDIDO_COMPRA.CANCELADO:
    case ESTADOS_PEDIDO_COMPRA.RECHAZADO:
      return "estado-rojo";
    default:
      return '';
  }
}

const PurchaseRequests = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetRequestsV2, handlePublishRequest, handleClosePartialRequest, handlePrintRequest, handleCancelRequest, 
    handleCheckInRequestPVP, handleAuthorizeRequest } = useRequest()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [showHistoryModal, setShowHistoryModal] = useState(false)

  const [columns, setColumns] = useState(COLUMNS)

  const getRequests = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.in_requests) {
        const { in_requests } = currentUser

        params = {
          ...params,
          id_filter: in_requests?.id_filter || '',
          // order_type_id: in_requests?.order_type_id || undefined,
          order_type_id_filter: in_requests?.order_type_id_filter || [1],
          order_status_id_filter: in_requests?.order_status_id_filter || [],
          date_from: in_requests?.date_from || '',
          date_to: in_requests?.date_to || '',
          proveedor: in_requests?.proveedor || '',
          proveedor_documento: in_requests?.proveedor_documento || '',
          estimated_in_date_from: in_requests?.estimated_in_date_from || '',
          estimated_in_date_to: in_requests?.estimated_in_date_to || '',
          distribuidor: in_requests?.distribuidor || '',
          distribuidor_documento: in_requests?.distribuidor_documento || '',
          in_date_from: in_requests?.in_date_from || '',
          in_date_to: in_requests?.in_date_to || '',
          article_text: in_requests?.article_text || '',
          non_compliant_in_requests: in_requests?.non_compliant_in_requests || '',
        }
      }

      const response = await handleGetRequestsV2(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.order_status_id)
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetRequestsV2, queryMD, currentUser])

  const exportData = async () => {
    try {
      let params = {
        ...queryMD,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetRequestsV2(params)

      const datos = response.data.result.map(d => {
        let data = {
          "Nº Pedido": d.id,
          "Tipo": d.order_type_label,
          "Proveedor": d.supplier_name,
          "Fecha creación": moment(d.created_at).format('DD/MM/YYYY'),
          "Ingreso estimado": d?.estimated_in_date ? moment(d.estimated_in_date).format('DD/MM/YYYY') : '-',
          "Ingreso": d?.in_order_created_at ? moment(d.in_order_created_at).format('DD/MM/YYYY') : '-',
          "Articulos": d.product_qty,
          "Monto": d.total,
          "Estado": d.order_status_label
        }

        return data
      })

      let ws = XLSX.utils.json_to_sheet(datos)

      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "Ordenes")
      XLSX.writeFile(wb, `Pedidos ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {

      actions.push({
        label: `${currentRow.order_status_id == ESTADOS_PEDIDO_COMPRA.BORRADOR ? "Editar" : 'Ver'}`,
        icon: `bi bi-${currentRow.order_status_id == ESTADOS_PEDIDO_COMPRA.BORRADOR  ? 'pencil' : 'eye'} text-primary`,
        action: actionEdit
      })

      if (currentRow.order_status_id === ESTADOS_PEDIDO_COMPRA.BORRADOR && currentRow.total > 0) {
          if (currentRow.order_type_id==OrderTypeI.PEDIDO_COMPRA) {
            actions.push({
              label: "Enviar a autorizar",
              icon: "bi bi-box-arrow-in-down-right text-success",
              action: publishRequest
            })
          } else if (currentRow.order_type_id==OrderTypeI.PEDIDO_INGRESO) {
            actions.push({
              label: "Enviar a operaciones",
              icon: "bi bi-clipboard-check text-success",
              action: sendToOperations
            })
          }
      }

      if (currentRow.order_status_id != ESTADOS_PEDIDO_COMPRA.COMPLETO && currentRow.order_status_id != ESTADOS_PEDIDO_COMPRA.CANCELADO) {
        let authRoles = [RoleI.ADMIN, RoleI.COMPRAS, RoleI.CONTROL_RENTAS]

        if (currentUser.roles.some(r => authRoles.includes(r.id))) {
          actions.push({
            label: "Cancelar",
            icon: "bi bi-x-circle text-danger",
            action: actionCancel
          })
        }
      }

      if (currentRow.order_type_id==OrderTypeI.PEDIDO_COMPRA) {
        actions.push({
          label: "Imprimir",
          icon: "bi bi-printer text-primary",
          action: actionPrint
        })
      }

      actions.push({
        label: "Eventos de la Orden",
        icon: "bi bi-clock-history text-primary",
        action: actionViewEvents
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      if (currentRow.order_type_id === 1) {
        navigate(`${routes.PURCHASE_REQUEST_DETAIL}/${currentRow.id}`)
      } else {
        navigate(`${routes.INCOME_REQUEST_DETAIL}/${currentRow.id}`)
      }
    }
  }

  const actionCancel = async () => {
    if (currentRow) {
      const { id, order_type_id } = currentRow

      const res = await Swal.fire({
        title: "Advertencia",
        html: `
          <p class='m-0'>¿Confirma la cancelación del <strong>Pedido${tipoOrden(order_type_id)} #${id} </strong>?</p>
        `,
        icon: "warning",
        input: 'textarea',
        inputLabel: 'Observaciones',
        inputValue: '',
        inputValidator: (value) => {
          if (!value) {
            return 'Completar una observación'
          }
        },
        showDenyButton: true,
        denyButtonText: "No, cerrar",
        confirmButtonText: "Si, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
        reverseButtons: true,
      })

      if (res.isConfirmed) {
        try {
          let data = {
            id: id,
            revocation_notes: res.value
          }
          const response = await handleCancelRequest(data)
          toast.success(response.message)
        } catch (error) {
          toast.error(error.message)
        } finally {
          getRequests()
        }
      }
    }
  }

  const actionPrint = async () => {
    if (currentRow) {
      const { id } = currentRow

      try {
        const response = await handlePrintRequest(id)
        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Pedido de compra descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `pedido-compra-${id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)

        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const actionViewEvents = () => {
    setShowHistoryModal(true)
  }

  const onCloseHistoryModal = (data) => {
    setShowHistoryModal(false)
  }
  /* Fin menu contextual */

  const closePartialRequest = async () => {
    try{
      const { id } = currentRow;

      const resp = await Swal.fire({
        title: "Advertencia",
        text: "¿Confirma el cierre parcial del pedido?",
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, cerrar pedido",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleClosePartialRequest(id)
        toast.success("El pedido se cerró con estado 'entrega parcial'", { theme: "colored" })
        getRequests()
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const publishRequest = async () => {
    try {
      const { id } = currentRow

      const response = await handleCheckInRequestPVP(id)

      let text = "¿Enviar el pedido a autorizar? A partir de ese momento, no se podrán realizar modificaciones"
      if (response.data.result.length > 0) {
        let qty = response.data.result.length
        text = `El pedido #${id} tiene (${qty}) detalle${qty > 1 ? 's' : ''} con PVP desactualizado, ¿quiere enviarlo a autorizar?`;
      }

      const resp = await Swal.fire({
        title: "Advertencia",
        text: text,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar a autorizar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handlePublishRequest(id)
        toast.success("El pedido se envió a autorizar", { theme: "colored" })
      }
      getRequests()
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const sendToOperations = async () => {
    try {
      const { id } = currentRow
      const resp = await Swal.fire({
        title: "Advertencia",
        text: "Envía el pedido a operaciones?",
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        await handleAuthorizeRequest({id})
        toast.success("El pedido se envió a operaciones", { theme: "colored" })
      }
      getRequests()
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const cols = useMemo(() => {
    const c = [...columns]

    const hasActionsColumns = columns.some(c => c.id == 'actions')

    if (!hasActionsColumns) {
      c.push({
        Header: '',
        id: 'actions',
        label: '',
        className:'col-icons',
        accessor: (row) =>
          true && (
            <i
              id='threeDots'
              ref={threeDotsRef}
              className="bi bi-three-dots-vertical"
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={event => handleContextMenu(event, row)}
            />
          ),
        visible: true,
        notHide: true,
      })
    }

    return c
  }, [columns, threeDotsRef, handleContextMenu])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD,
  }

  useEffect(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <PurchaseHistoryDialog  show={showHistoryModal} onClose={onCloseHistoryModal} currentRow={currentRow} />

      <TableContainer
        title='Pedidos'
        dropdownActions={[
          {
            label: "Pedido de compra",
            href: routes.PURCHASE_REQUEST_NEW,
            icon: 'currency-dollar',
            state: { type: OrderTypeI.PEDIDO_COMPRA }
          },
          {
            label: "Pedido de ingreso",
            href: routes.INCOME_REQUEST_NEW,
            icon: 'box-arrow-in-down',
            state: { type: OrderTypeI.PEDIDO_INGRESO }
          },
        ].filter(action => {
          if (action.label === "Pedido de ingreso") {
            return currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.OPERACIONES  || r.id == RoleI.COMPRAS));
          }
          return true; // Incluir otros objetos por defecto
        })}
        filter={<Filter queryMD={queryMD} setQueryMD={setQueryMD} exportData={exportData} />}
        filterColumns
        columns={cols}
        setColumns={setColumns}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable
          columns={cols.filter(c => c.visible !== false)}
          data={data || []}
          handleContextMenu={handleContextMenu}
          queryMD={queryMD}
          setQueryMD={setQueryMD}
          paginationOptions={paginationOptions}
        />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default PurchaseRequests