import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'

import { useCustomerSuppliers } from 'hooks'

import { SelectV2, InputV2 } from 'components/v2'

import { AutorizacionCreditoI } from 'interfaces'
import { Tooltip } from 'components'

const textoAutorizacionCredito = (id_autorizacion_credito) => {
  let text = ''

  switch (id_autorizacion_credito) {
    case AutorizacionCreditoI.DESHABILITADO_CREDITOS:
      text = 'No se podran avanzar los pedidos.'
      break
    case AutorizacionCreditoI.MANUAL:
      text = ''
      break
    case AutorizacionCreditoI.AUTOMATICA_CONDICION_PAGO:
      text = 'Si coincide la condición de pago del pedido con la del cliente.'
      break
    case AutorizacionCreditoI.AUTORIZADO_SIEMPRE:
      text = 'Cuando se autoriza la rentabilidad, se autoriza automáticamente el crédito.'
      break
  }

  return text
}

const TabCreditos = ({ creando }) => {
  const { values } = useFormikContext()

  const { handleObtenerAutorizacionesCredito } = useCustomerSuppliers()

  const [cargandoAutorizaciones, setCargandoAutorizaciones] = useState(false)
  const [autorizaciones, setAutorizaciones] = useState([])

  const obtenerAutorizacionesCredito = async () => {
    setCargandoAutorizaciones(true)

    try {
      const response = await handleObtenerAutorizacionesCredito()

      if (response.data.result) {
        const data = response.data.result.map(d => ({
          value: d.id,
          label: d.label,
        }))

        setAutorizaciones(data)
      }
    } catch (error) {
      setAutorizaciones([])
      toast.error(error.message)
    } finally {
      setCargandoAutorizaciones(false)
    }
  }

  useEffect(() => {
    obtenerAutorizacionesCredito()
  }, [])

  return (
    <>
      <div className='row'>
        <SelectV2
          id='id_autorizacion_credito'
          name='id_autorizacion_credito'
          label='Autorización crédito'
          options={autorizaciones}
          col='col-6'
          placeholder='Seleccionar una opción'
          disabled={creando || cargandoAutorizaciones}
        />

        <div className='col-6 d-flex flex-row justify-content-star align-items-center mt-6'>
          {values?.id_autorizacion_credito != AutorizacionCreditoI.MANUAL &&
            <Tooltip text={textoAutorizacionCredito(values?.id_autorizacion_credito)}>
              <i className='bi bi-info-circle text-warning' />
            </Tooltip>
          }
        </div>
      </div>

      <div className='row my-6'>
        <InputV2
          id='credit'
          name='credit'
          type='number'
          label={'Límite crédito'}
          col='col-6'
          disabled={creando}
        />
      </div>
    </>
  )
}

export default TabCreditos