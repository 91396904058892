import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { left, right } from "@popperjs/core";


const Factura = ({ show, handleClose, comprobanteData, downloadVisualizarComprobante }) => {

  let nrComprobante = comprobanteData?.comprobante?.nrComprobante || '';
  let letra = comprobanteData?.comprobante?.letra || '';
  let codAfip = ' ' + comprobanteData?.comprobante?.codigo_afip || '';

  let [fecha, setFecha] = useState(comprobanteData?.comprobante?.fecha_comprobante || '');
  let [nrTransaccion, setnrTransaccion] = useState(comprobanteData?.comprobante?.id || '');

  let copias = "Original";
  let [nombreComprobante, setnrNombreComprobante] = useState('');

  let [razonSocial, setRazonSocial] = useState('');
  let [direccion, setDireccion] = useState('');
  let [provinciaPais, setProvinciaPais] = useState('');
  let [cuit, setCuit] = useState('');
  let [ivaCliente, setIvaCliente] = useState('');

  let [glnDestino, setGlnDestino] = useState('');
  let [ordenCompra, setOrdenCompra] = useState('');
  let [formaPago, setFormaPago] = useState('');
  //para NCs
  let [facturaAsoc, setFacturaAsoc] = useState('');
  let [id_fc_asoc, setIdFcAscoc] = useState('');
  let [remitoAsoc, setRemitoAsoc] = useState('');
  let [ordenEgresoId, setOrdenEgresoId] = useState('');
  //
  let [pedido_venta_id, setPedido_venta_id] = useState('');
  let [nrCompAsoc, setNrCompAsoc] = useState('');
  let [orden_egreso_id, setOrden_egreso_id] = useState('');

  let [domicilioEntrega, setDomicilioEntrega] = useState('');
  let [direccionEntrega, setDireccionEntrega] = useState('');
  let [codigoPostalEntrega, setCodigoPostalEntrega] = useState('');
  let [tipoTransporte, setTipoTransporte] = useState('');

  let [vendedor, setVendedor] = useState('');
  let [observaciones, setObservaciones] = useState('');
  let [cotizacion, setCotizacion] = useState('');
  let [paciente, setPaciente] = useState('');
  let [importante, setImportante] = useState("Las caracteristicas especiales de conservacion de estos productos para segurar sus propiedadesterapeuticas hacen que bajo ningun concepto se acepten devoluciones.");
  let [qr_url, setQr_url] = useState('');
  let [cae, setCae] = useState('');
  let [fechaCAE, setFechaCAE] = useState('');
  let [fechaVenPago,  setFechaVenPago] = useState('');
  let [CBU, setCBU] = useState('');

  let [netoGravado, setNetoGravado] = useState('');
  let [netoNoGravado, setNetoNoGravado] = useState('');
  //Para Fcs B
  let [subTotal, setSubTotal] = useState('');
  //


  let [iva21, setIva21] = useState('');
  let [iva105, setIva105] = useState('');
  let [internos, setInternos] = useState('');
  let [total, setTotal] = useState('');


  let [items, setItems] = useState([
  ]);

  let [percepciones, setPercepciones] = useState([
  ]);


  useEffect(() => {
    setFecha(comprobanteData?.comprobante?.fecha_comprobante || '');
    setnrTransaccion(comprobanteData?.comprobante?.id || '');
    setnrNombreComprobante({
      1: "FACTURA",
      6: "FACTURA",
      201: "FACTURA DE CRÉDITO ELECTRÓNICA",
      206: "FACTURA DE CRÉDITO ELECTRÓNICA",
      3: "NOTA DE CRÉDITO",
      8: "NOTA DE CRÉDITO",
      203: "NOTA DE CRÉDITO ELECTRÓNICA",
      208: "NOTA DE CRÉDITO ELECTRÓNICA",
      2: "NOTA DE DEBITO",
      7: "NOTA DE DEBITO",
      12: "NOTA DE DEBITO",
      202: "NOTA DE DEBITO ELECTRÓNICA",
      207: "NOTA DE DEBITO ELECTRÓNICA",

    }[comprobanteData?.comprobante?.codigo_afip] || "");


    setRazonSocial(comprobanteData?.comprobante?.razonSocial || '');
    setDireccion(comprobanteData?.comprobante?.direccion || '');
    setProvinciaPais(comprobanteData?.comprobante?.provinciaPais || '');
    //
    setCuit(comprobanteData?.comprobante?.cuit || '');
    setIvaCliente(comprobanteData?.comprobante?.ivaCliente || '');

    setGlnDestino(comprobanteData?.comprobante?.glnDestino || '');
    setOrdenCompra(comprobanteData?.comprobante?.ordenCompra || '');
    setFormaPago(comprobanteData?.comprobante?.formaPago || '');
    //Ncs
    setFacturaAsoc(comprobanteData?.comprobante?.facturaAsoc || '');
    setIdFcAscoc(' ('+comprobanteData?.comprobante?.id_fc_asoc+')' || '');
    setRemitoAsoc(comprobanteData?.comprobante?.remitoAsoc || '');
    setOrdenEgresoId(comprobanteData?.comprobante?.ordenEgresoId || '');

    //
    setPedido_venta_id(comprobanteData?.comprobante?.pedido_venta_id || '');
    setNrCompAsoc(comprobanteData?.comprobante?.nrCompAsoc || '');
    setOrden_egreso_id(comprobanteData?.comprobante?.orden_egreso_id || '');

    setDomicilioEntrega(comprobanteData?.comprobante?.domicilioEntrega || '');
    setDireccionEntrega(comprobanteData?.comprobante?.direccionEntrega || '');
    setCodigoPostalEntrega(comprobanteData?.comprobante?.codigoPostalEntrega || '');
    setTipoTransporte(comprobanteData?.comprobante?.tipo_transporte || '');


    setVendedor(comprobanteData?.comprobante?.vendedor || '');
    setObservaciones(comprobanteData?.comprobante?.observaciones || '');
    setCotizacion(comprobanteData?.comprobante?.cotizacion || '');
    setPaciente(comprobanteData?.comprobante?.paciente || '');
    setQr_url(comprobanteData?.comprobante?.qr_url || '');
    setCae(comprobanteData?.comprobante?.cae || '');
    setFechaCAE(comprobanteData?.comprobante?.fechaCae || '');
    setFechaVenPago(comprobanteData?.comprobante?.fechaVenPago|| '');
    setCBU(comprobanteData?.comprobante?.cbu || '');

    setNetoGravado(comprobanteData?.comprobante?.netoGravado || '');
    setNetoNoGravado(comprobanteData?.comprobante?.netoNoGravado || '');
    setSubTotal(comprobanteData?.comprobante?.subTotal || '');

    setIva21(comprobanteData?.comprobante?.iva21 || '');
    setIva105(comprobanteData?.comprobante?.iva105 || '');
    setInternos(comprobanteData?.comprobante?.internos || '');
    setTotal(comprobanteData?.comprobante?.importe_total || '');


    const extractedItems = comprobanteData?.comprobante?.arrayItems?.map(item => ({
      articulo: item.articulo,
      descripcion: item.descripcion,
      cantidad: item.cantidad,
      precio: item.precio,
      bonificacion: item.bonificacion,
      precioUnitario: item.precioUnitario,
      importeIVA: item.importeIVA,
      importeTotal: item.importeTotal,
    })) || [];

    // Extraer y asignar los items de arrayItems al estado de items
    const extractedPercepciones = comprobanteData?.comprobante?.arrayPercepciones?.map(percepciones => ({
      descripcion: percepciones.descripcion,
      importe: percepciones.importe
    })) || [];

    console.log("extractedPercepciones", extractedPercepciones);

    setItems(extractedItems);
    setPercepciones(extractedPercepciones);


  }, [comprobanteData]);



  let bodyItems = items.map((item, index) => (
    <tr style={{ borderTop: "0.1rem solid black" }} key={index}>
      <td style={{ padding: "0.5rem 0", fontSize: "0.9rem" }}>{item.articulo}</td>
      <td style={{ padding: "0.5rem 0", textAlign: "left", fontSize: "0.9rem" }}>{item.descripcion}</td>
      <td style={{ padding: "0.5rem 0", textAlign: "right", fontSize: "0.9rem" }}>{item.cantidad}</td>
      <td style={{ padding: "0.5rem 0", textAlign: "right", fontSize: "0.9rem" }}>{item.precio}</td>
      <td style={{ padding: "0.5rem 0", textAlign: "right", fontSize: "0.9rem" }}>{item.bonificacion}</td>
      <td style={{ padding: "0.5rem 0", textAlign: "right", fontSize: "0.9rem" }}>{item.precioUnitario}</td>
      {letra === "A" ? (
        <td style={{ padding: "0.5rem 0", textAlign: "right", fontSize: "0.9rem" }}>{item.importeIVA}</td>
      ) : (
        <></>
      )}
      <td style={{ padding: "0.5rem 0", textAlign: "right", fontSize: "0.9rem" }}>{item.importeTotal}</td>
    </tr>
  ));



  let bodyPcepciones = percepciones.map((pcepcion, index) => (
    <tr key={index}>
      <td>{pcepcion.descripcion}</td>
      <td style={{ textAlign: right }}>{pcepcion.importe}</td>
    </tr>
  ));

  const [isEditing, setIsEditing] = useState(false);
  const handleEdit = () => setIsEditing(true);
  const handleSave = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
    }
  };
  const handleSaveTwo = () => setIsEditing(false);


  let arrayModificaciones = [
    { nrComprobante },
    { letra },
    { codAfip },
    { fecha },
    { nrTransaccion },
    { copias },
    { nombreComprobante },
    { razonSocial },
    { direccion },
    { provinciaPais },
    { cuit },
    { ivaCliente },
    { glnDestino },
    { ordenCompra },
    { formaPago },
    { facturaAsoc },
    { id_fc_asoc },
    { remitoAsoc },
    { ordenEgresoId },
    { pedido_venta_id },
    { nrCompAsoc },
    { orden_egreso_id },
    { domicilioEntrega },
    { direccionEntrega },
    { codigoPostalEntrega },
    { tipoTransporte },
    { vendedor },
    { observaciones },
    { cotizacion },
    { paciente },
    { importante },
    { qr_url },
    { cae },
    { fechaCAE },
    { CBU },
    { netoGravado },
    { netoNoGravado },
    { subTotal },
    { iva21 },
    { iva105 },
    { internos },
    { total },
    { items },
    { percepciones }
  ];



  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header style={{ marginBottom: "-5px" }} closeButton>
      </Modal.Header>
      <Modal.Body>
        <div style={{ margin: "0rem", marginTop: "0" }}>
          <div style={{
            borderTop: "1.5rem solid rgb(0, 153, 169)",
            marginBottom: "1rem",
            borderBottom: "0.1rem solid black",
          }}></div>

          {/* Encabezado */}
          <div style={{ display: "flex", alignItems: "center", padding: "0.5rem 0", borderBottom: "0.1rem solid black" }}>
            <div style={{ width: "45%" }}>
              <img src="https://raw.githubusercontent.com/Franco-Hasper/DrofarLogo/main/drofarLogo-2.png" alt="drofar" style={{ width: "75%", marginTop: "-30px" }} />
            </div>
            <div style={{ width: "35%", textAlign: "left", fontFamily: 'Arial, sans-serif' }}>
              <h3 style={{ fontSize: "2.5rem", fontWeight: "bold", marginLeft: "10%", marginTop: "-10px" }}>{letra}</h3>

              <p style={{ marginTop: "-10px" }}>COD. AFIP {codAfip}</p>

            </div>
            <div style={{ width: "20%", textAlign: "right" }}>
              <h3 style={{ marginTop: "-10px" }}>{copias}</h3>
              <p>{isEditing ? (
                <input
                  type="text"
                  value={fecha}
                  onChange={(e) => setFecha(e.target.value)}
                  onKeyDown={handleSave}
                  autoFocus
                  style={{ width: "80px" }}
                />
              ) : (
                fecha
              )}</p>
            </div>
          </div>

          {/* Datos Emisor y comprobante */}
          <div style={{ display: "flex", borderBottom: "0.1rem solid black", marginBottom: "0.3rem", marginTop: "10px" }}>
            <div style={{ width: "45%" }}>
              <h4>Redimer S.A.</h4>
              <p style={{ marginTop: "-5px" }}>Av. Juan B. Justo 2713</p>
              <p style={{ marginTop: "-15px" }}>(1414) Ciudad Autonoma de Buenos Aires</p>
              <p style={{ marginTop: "-15px" }}>Tel: (011) 5365-5009</p>
              <p style={{ marginTop: "-15px" }}>info@drofar.com.ar - www.drofar.com.ar</p>
              <p style={{ marginTop: "-15px", fontWeight: "bold" }} className="observations">IVA Responsable Inscripto</p>
            </div>
            <div style={{ width: "35%" }}>
              <p style={{ fontSize: "1rem", fontWeight: "bold" }}>{nombreComprobante}</p>
              <p style={{ marginTop: "-15px" }}>Buenos Aires</p>
              <p style={{ marginTop: "-15px" }}><span className="observations" style={{ fontWeight: "bold" }}>CUIT: </span>30-70741652-8</p>
              <p style={{ marginTop: "-15px" }}><span className="observations" style={{ fontWeight: "bold" }}>GLN ORIGEN: </span>7798166400019</p>
              <p style={{ marginTop: "-15px" }}><span className="observations" style={{ fontWeight: "bold" }}>Ing Bru: </span>901-214897-5</p>
              <p style={{ marginTop: "-15px" }}><span className="observations" style={{ fontWeight: "bold" }}>Fecha de inicio de actividad: </span>02/2001</p>

            </div>
            <div style={{ width: "20%", textAlign: "right" }}>
              <p style={{ fontSize: "1rem", fontWeight: "bold" }}>{nrComprobante}</p>
              <p style={{ fontSize: "0.8rem", fontWeight: "bold", marginTop: "-15px" }}><span className="observations">NR. DE TRANSACCIÓN </span></p>
              <p style={{ fontSize: "1rem", marginTop: "-15px" }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={nrTransaccion}
                    onChange={(e) => setnrTransaccion(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "60px" }}
                  />
                ) : (
                  nrTransaccion
                )}
              </p>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: "1rem" }}>
            <div style={{ width: "33%" }}>
              <p style={{ fontWeight: "bold" }}>

                {isEditing ? (
                  <input
                    type="text"
                    value={razonSocial}
                    onChange={(e) => setRazonSocial(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  razonSocial
                )}</p>

              <p style={{ marginTop: "-15px" }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  direccion
                )}</p>

              <p style={{ marginTop: "-15px" }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={provinciaPais}
                    onChange={(e) => setProvinciaPais(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  provinciaPais
                )}</p>

              <p style={{ marginTop: "-15px" }}><span className="observations" style={{ fontWeight: "bold" }}>CUIT: </span>
                {isEditing ? (
                  <input
                    type="text"
                    value={cuit}
                    onChange={(e) => setCuit(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  cuit
                )}
              </p>

              <p style={{ marginTop: "-15px", fontWeight: "bold" }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={ivaCliente}
                    onChange={(e) => setIvaCliente(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  ivaCliente
                )}
              </p>
              <p className="observations">{/* Tipo IVA */}</p>
            </div>
            <div style={{ width: "40%" }}>
              <p><span style={{ fontWeight: "bold" }} className="observations">GLN DESTINO: </span>
                {isEditing ? (
                  <input
                    type="text"
                    value={glnDestino}
                    onChange={(e) => setGlnDestino(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  glnDestino
                )}</p>
              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Orden de compra: </span>
                {isEditing ? (
                  <input
                    type="text"
                    value={ordenCompra}
                    onChange={(e) => setOrdenCompra(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  ordenCompra
                )}
              </p>
              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Forma de pago: </span>
                {isEditing ? (
                  <input
                    type="text"
                    value={formaPago}
                    onChange={(e) => setFormaPago(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "230px" }}
                  />
                ) : (
                  formaPago
                )}</p>


              {nombreComprobante === "NOTA DE CRÉDITO" || nombreComprobante === "NOTA DE CRÉDITO ELECTRÓNICA"
              ||nombreComprobante === "NOTA DE DEBITO" || nombreComprobante === "NOTA DE DEBITO ELECTRÓNICA"
              ? (
                <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Factura Asoc: </span>
                  {isEditing ? (
                    <input
                      type="text"
                      value={facturaAsoc}
                      onChange={(e) => setFacturaAsoc(e.target.value)}
                      onKeyDown={handleSave}
                      autoFocus
                      style={{ width: "230px" }}
                    />
                  ) : (
                    facturaAsoc
                  )}
                  {isEditing ? (
                    <input
                      type="text"
                      value={id_fc_asoc}
                      onChange={(e) => setIdFcAscoc(e.target.value)}
                      onKeyDown={handleSave}
                      autoFocus
                      style={{ width: "230px" }}
                    />
                  ) : (
                    id_fc_asoc
                  )}
                  </p>
              ) : (
                <></>
              )}


              {nombreComprobante === "NOTA DE CRÉDITO" || nombreComprobante === "NOTA DE CRÉDITO ELECTRÓNICA" 
              ||nombreComprobante === "NOTA DE DEBITO" || nombreComprobante === "NOTA DE DEBITO ELECTRÓNICA"? (
                <>
                  <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="remitoAsoc">Remito Asoc: </span>
                    {isEditing ? (
                      <input
                        type="text"
                        value={remitoAsoc}
                        onChange={(e) => setRemitoAsoc(e.target.value)}
                        onKeyDown={handleSave}
                        autoFocus
                        style={{ width: "179px" }}
                      />
                    ) : (
                      remitoAsoc
                    )} </p>

                  <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="ordenIgresoId">Orden Egreso Id: </span>
                    {isEditing ? (
                      <input
                        type="text"
                        value={orden_egreso_id}
                        onChange={(e) => setOrden_egreso_id(e.target.value)}
                        onKeyDown={handleSave}
                        autoFocus
                        style={{ width: "179px" }}
                      />
                    ) : (
                      orden_egreso_id
                    )} </p>



                  <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Pedido de venta: </span>
                    {isEditing ? (
                      <input
                        type="text"
                        value={pedido_venta_id}
                        onChange={(e) => setPedido_venta_id(e.target.value)}
                        onKeyDown={handleSave}
                        autoFocus
                        style={{ width: "230px" }}
                      />
                    ) : (
                      pedido_venta_id
                    )}
                  </p>


                </>

              ) : (
                <>

                  <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Pedido de venta: </span>
                    {isEditing ? (
                      <input
                        type="text"
                        value={pedido_venta_id}
                        onChange={(e) => setPedido_venta_id(e.target.value)}
                        onKeyDown={handleSave}
                        autoFocus
                        style={{ width: "230px" }}
                      />
                    ) : (
                      pedido_venta_id
                    )}
                  </p>


                  <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Orden Egreso: </span>
                    {isEditing ? (
                      <input
                        type="text"
                        value={orden_egreso_id}
                        onChange={(e) => setOrden_egreso_id(e.target.value)}
                        onKeyDown={handleSave}
                        autoFocus
                        style={{ width: "230px" }}
                      />
                    ) : (
                      orden_egreso_id
                    )}</p>


                  <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Remito: </span>
                    {isEditing ? (
                      <input
                        type="text"
                        value={nrCompAsoc}
                        onChange={(e) => setNrCompAsoc(e.target.value)}
                        onKeyDown={handleSave}
                        autoFocus
                        style={{ width: "179px" }}
                      />
                    ) : (
                      nrCompAsoc
                    )} </p>



                </>

              )}









            </div>
            <div style={{ width: "22%" }}>
              <p style={{ fontWeight: "bold" }} className="observations">Entregar en:</p>
              <p style={{ marginTop: "-15px" }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={domicilioEntrega}
                    onChange={(e) => setDomicilioEntrega(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  domicilioEntrega
                )}</p>
              <p style={{ marginTop: "-15px" }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={direccionEntrega}
                    onChange={(e) => setDireccionEntrega(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  direccionEntrega
                )}</p>
              <p style={{ marginTop: "-15px" }} className="observations">
                {isEditing ? (
                  <input
                    type="text"
                    value={codigoPostalEntrega}
                    onChange={(e) => setCodigoPostalEntrega(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  codigoPostalEntrega
                )}</p>
              <p style={{ fontWeight: "bold", marginTop: "-15px" }} className="observations">
                {isEditing ? (
                  <input
                    type="text"
                    value={tipoTransporte}
                    onChange={(e) => setTipoTransporte(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  tipoTransporte
                )}</p>
            </div>
          </div>

          {/* Tabla de artículos */}
          <div className="table-container">
            <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left", borderTop: "0.2rem solid black" }}>
              <thead>
                <tr>
                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", width: "15%" }}>Artículo</th>
                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", width: "25%" }}>Descripción</th>
                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", textAlign: "right", width: "10%" }}>Cant.</th>
                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", textAlign: "right", width: "15%" }}>Precio</th>
                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", textAlign: "right", width: "8%" }}>Bonif</th>
                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", textAlign: "right", width: "12%" }}>Precio Uni.</th>
                  {letra === "A" ? (
                    <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", textAlign: "right", width: "5%" }}>IVA</th>
                  ) : (
                    <></>
                  )}


                  <th style={{ padding: "0.5rem 0", borderBottom: "0.2rem solid black", textAlign: "right", width: "15%" }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {bodyItems}
              </tbody>
            </table>
          </div>

          {/* Pie de página */}
          <div style={{ display: "flex", justifyContent: "space-between", borderTop: "0.1rem solid", paddingTop: "0.4rem", marginTop: "2rem" }}>
            <div style={{ width: "45%" }}>
              <p style={{ fontWeight: "bold" }}><span className="observations">Ud. fue atendido por: </span>
                {isEditing ? (
                  <input
                    type="text"
                    value={vendedor}
                    onChange={(e) => setVendedor(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  vendedor
                )}</p>
              <p style={{ marginTop: "-15px" }}>Solicitamos confeccionar los cheques a la orden de <span className="observations" style={{ fontWeight: "bold" }}>Redimer S.A.</span> sin la leyenda Para depositar en Cuenta.</p>
              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="observations">Observaciones: </span>
                {isEditing ? (
                  <input
                    type="text"
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  observaciones
                )}</p>

              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="cotizacion">Cotización Moneda extranjera:</span>
                {isEditing ? (
                  <input
                    type="text"
                    value={cotizacion}
                    onChange={(e) => setCotizacion(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  cotizacion
                )}</p>
              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="paciente">Paciente:</span>
                {isEditing ? (
                  <input
                    type="text"
                    value={paciente}
                    onChange={(e) => setPaciente(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  paciente
                )}</p>
              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="importante">Importante:</span>
                {isEditing ? (
                  <input
                    type="text"
                    value={importante}
                    onChange={(e) => setImportante(e.target.value)}
                    onKeyDown={handleSave}
                    autoFocus
                    style={{ width: "227px" }}
                  />
                ) : (
                  importante
                )}</p>
              <img src={qr_url} alt="qr" style={{ width: "75px", marginRight: "1rem", marginTop: "-5px" }} />
              <p style={{ marginTop: "5px" }}><span style={{ fontWeight: "bold" }} className="cae">CAE:</span>{cae}</p>
              <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="fechaCAE">FeVen CAE:</span>{fechaCAE}</p>
              

              {nombreComprobante === "FACTURA DE CRÉDITO ELECTRÓNICA" || nombreComprobante === "NOTA DE CRÉDITO ELECTRÓNICA" 
                || nombreComprobante === "NOTA DE DEBITO ELECTRÓNICA" ? (
                  <>
                    <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="fechaVenPago">fechaVen Pago:</span>{fechaVenPago}</p>
                    <p style={{ marginTop: "-15px" }}><span style={{ fontWeight: "bold" }} className="cbu">CBU:</span>1910006355000607878228</p>
                  </>
                ) : (
                  <></>
                )}

              <p style={{ marginTop: "-15px" }}>Teléfono gratuito CABA Defensa Consumidor: 147</p>
            </div>
            <div style={{ width: "50%" }}>


              <div className="totales">

                {letra === "A" ? (

                  <>
                    <div className="netoGravado" style={{ width: "100%" }}>
                      <p style={{ width: "50%", float: "left" }}>Neto Gravado:</p>
                      <p style={{ width: "50%", float: "right", textAlign: "right" }}>{netoGravado}</p>
                    </div>

                    <div className="netoNoGravado" style={{ width: "100%" }}>
                      <p
                        style={{
                          width: "50%",
                          float: "left",
                          marginTop: "-15px",
                          borderBottom: "0.1rem solid black",
                        }}
                      >
                        No Gravado:
                      </p>
                      <p
                        style={{
                          width: "50%",
                          float: "right",
                          textAlign: "right",
                          marginTop: "-15px",
                          borderBottom: "0.1rem solid black",
                        }}
                      >
                        {netoNoGravado}
                      </p>
                    </div>
                  </>

                ) : (

                  <div className="SubTotal" style={{ width: "100%" }}>
                    <p style={{ width: "50%", float: "left", borderBottom: "0.1rem solid black" }}>SubTotal:</p>
                    <p style={{ width: "50%", float: "right", textAlign: "right", borderBottom: "0.1rem solid black" }}>{subTotal}</p>
                  </div>


                )}


                {letra === "A" ? (
                  <>
                    <div className="iva21" style={{ width: "100%" }}>
                      <p style={{ width: "50%", float: left, marginTop: "-10px" }}>IVA 21.00:</p>
                      <p style={{ width: "50%", float: right, textAlign: right, marginTop: "-10px" }}>{iva21}</p>
                    </div>

                    <div className="iva105" style={{ width: "100%" }}>
                      <p style={{ width: "50%", float: left, marginTop: "-15px" }}>IVA 10.50:</p>
                      <p style={{ width: "50%", float: right, textAlign: right, marginTop: "-15px" }}>{iva105}</p>
                    </div>

                    <div className="internos" style={{ width: "100%" }}>
                      <p style={{ width: "50%", float: left, marginTop: "-15px", borderBottom: "0.1rem solid black" }}>Internos:</p>
                      <p style={{ width: "50%", float: right, textAlign: right, marginTop: "-15px", borderBottom: "0.1rem solid black" }}>{internos}</p>
                    </div>

                  </>

                ) : (

                  <></>

                )}







                {/* Tabla de artículos */}
                <div className="table-container-p0ercepciones">

                  <div className="total" style={{ width: "100%" }}>
                    <p style={{ width: "50%", float: left, marginTop: "-10px", marginBottom: "0px" }}>Percep Ing Bru:</p>
                    <p style={{ width: "50%", float: right, textAlign: right, marginTop: "-10px", marginBottom: "0px" }}>{internos}</p>
                  </div>

                  <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "left" }}>

                    <tbody style={{ marginBottom: "-5px" }}>
                      {bodyPcepciones}
                    </tbody>
                  </table>
                </div>

                <div className="total" style={{ width: "100%", borderBottom: "0.1rem solid black" }}>
                  <p style={{ width: "50%", float: left, paddingBottom: "0.5rem", marginTop: "0.5rem", borderBottom: "0.1rem solid black" }}>Total:</p>
                  <p style={{ width: "50%", float: right, paddingBottom: "0.5rem", textAlign: right, marginTop: "0.5rem", borderBottom: "0.1rem solid black" }}>{total}</p>
                </div>


              </div>

            </div>

          </div>
          <p style={{ marginTop: "-15px", float: right }}>Página 1/1</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleEdit}>
          Editar
        </Button>
        <Button variant="secondary" onClick={() => downloadVisualizarComprobante(arrayModificaciones)}>
          Imprimir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Factura;