import service from "../service"

const PATH = {
  Products: "/product",
  Family: "/ab/family",
  Maker: "/ab/maker",
}

export class Products {
  static getProducts = async (params) => {
    const response = await service.get(PATH.Products + params );
    return response;
  };

  static getProductsV2 = async (params) => {
    const response = await service.get(PATH.Products, {
      params,
    })
    return response
  }

  static getProduct = async (id) => {
    const response = await service.get(`${PATH.Products}/${id}`);
    return response;
  };

  static searchProducts = async (params) => {
    const response = await service.get(PATH.Products, {params});
    return response;
  };

  static searchProductsVar = async (params) => {
    const response = await service.get(`${PATH.Products}/var`, {params});

    return response;
  }

  static createProduct = async (data) => {
    const response = await service.post(PATH.Products, data);
    return response;
  };

  static updateProduct = async (data) => {
    const {id, ...rest} = data;
    const response = await service.put(`${PATH.Products}/${id}`, rest);
    return response;
  };

  static deleteProduct = async (id) => {
    const response = await service.delete(`${PATH.Products}/${id}`);
    return response;
  };


  static getProductFamilies = async () => {
    const response = await service.get(PATH.Family);
    return response;
  };

  static getProductMakers = async () => {
    const response = await service.get(PATH.Maker);
    return response;
  };


}