import React from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

const Actions = ({ row }) => {
  return (
    <div className='d-flex justify-content-around'>
    { row.id?      
      <Tooltip text='Ver costos'>
        <Link to={`${routes.COST_CUSTOMER}/${row.id}`} state={{row}}>
          <i className='bi bi-pencil-square text-primary fs-3' style={{ cursor: 'pointer' }} />
        </Link>
      </Tooltip>
      :    
      <Tooltip text='Ver costos'>
        <Link to={`${routes.COST_PRODUCT}/${row.product_var_id}`} state={{row}}>
          <i className='bi bi-pencil-square text-primary fs-3' style={{ cursor: 'pointer' }} />
        </Link>
      </Tooltip>
    }
    </div>
  )
}

export default Actions