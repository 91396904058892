import service from "services/service";

const PATH = {
  shipmentAddresses: "/shipment-addresses",
  addresses: 'shipment-addresses/address',
};

export class ShipmentAddresses {
  static getShipmentAddresses = async (token, query, params) => {
    const response = await service.get(`${PATH.shipmentAddresses}${query}`, {
      params
    });

    return response;
  };

  static getShipmentAddress = async (id, token) => {
    const response = await service.get(`${PATH.shipmentAddresses}/${id}`);

    return response;
  };

  static searchAddress = async (query) => {
    const response = await service.get(`${PATH.addresses}${query}`)
    return response
  }

  static addShipmentAddress = async (data, token) => {
    const response = await service.post(PATH.shipmentAddresses, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }

  static updateShipmentAddress = async (id, data, token) => {
    const response = await service.patch(`${PATH.shipmentAddresses}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }
  static updateAgent = async (id, data, token) => {
    const response = await service.patch(`${PATH.shipmentAddresses}/${id}/actualizar-agente`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }
  static updateEnableShipmentAddress = async (id, data) => {
    const response = await service.patch(`${PATH.shipmentAddresses}/${id}/enabled`, data)

    return response
  }

  static deleteShipmentAddress = async (id, token) => {
    const response = await service.delete(`${PATH.shipmentAddresses}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response
  }

  static getShipmentAddressAssociatedCustomers = async (id) => {
    const response = await service.get(`${PATH.shipmentAddresses}/${id}/customer-suppliers`)
    return response
  }

  static crearDocumentoDireccionEntrega = async (id, data) => {
    const response = await service.post(`${PATH.shipmentAddresses}/${id}/documentos`, data)
    return response
  }

  static obtenerDocumentosDireccionEntrega = async (id, params) => {
    const response = await service.get(`${PATH.shipmentAddresses}/${id}/documentos`, {
      params
    })
    return response
  }

  static obtenerDocumentoDireccionEntrega = async (id, fileId) => {
    const response = await service.get(`${PATH.shipmentAddresses}/${id}/documentos/${fileId}`, { responseType: 'blob' })
    return response
  }

  static eliminarDocumentoDireccionEntrega = async (id, fileId) => {
    const response = await service.delete(`${PATH.shipmentAddresses}/${id}/documentos/${fileId}`)
    return response
  }

  
}