import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'

import { useClickOutside, useOutOrders } from 'hooks'
import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter, TableActions } from './partials'
import { useGetColumns } from './columns'

import routes from 'app/routing/routes'
import { OutOrderStatusI, RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  out_order_status_id_filter: [OutOrderStatusI.PENDIENTE_PREPARACION]
}

export const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul";
    // case OutOrderStatusI.EN_PREPARACION:
    //   return "estado-gris";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case OutOrderStatusI.ENTREGADA:
      return "estado-verde";
    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    default:
      return ""
  }
}

const OutOrders = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetOutOrders, handleUpdateOutOrderStatus, handlePrintWaybill, handleLiberarReserva } = useOutOrders()
  const [data, setData] = useState([])
  const [pendingOrders, setPendingOrders] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [selectedRows, setSelectedRows] = useState([])

  const handleRowSelection = useCallback((rowId, statusId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some(i => i.id === rowId)) {
        return prevSelectedRows.filter(i => i.id !== rowId)
      } else {
        return [...prevSelectedRows, { id: rowId, status_id: statusId, checked: true }]
      }
    })
  }, [])

  const { COLUMNS } = useGetColumns(selectedRows, handleRowSelection)

  const [columns, setColumns] = useState(COLUMNS)

  const getOutOrders = useCallback(async () => {
    try {
      let params = {
        getDetail: true,
        // sinTotal: true
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.out_orders) {
        params = {
          ...params,
          id_text: currentUser?.out_orders?.id_text || '',
          from_date: currentUser?.out_orders?.from_date || '',
          to_date: currentUser?.out_orders?.to_date || '',
          nombre: currentUser?.out_orders?.nombre || '',
          documento: currentUser?.out_orders?.documento || '',
          has_refrigeration: currentUser?.out_orders?.has_refrigeration || '',
          article_text: currentUser?.out_orders?.article_text || '',
          paciente: currentUser?.out_orders?.paciente || '',
          paciente_documento: currentUser?.out_orders?.paciente_documento || '',
          direccion: currentUser?.out_orders?.direccion || '',
          localidad: currentUser?.out_orders?.localidad || '',
          provincia: currentUser?.out_orders?.provincia || '',
          amount_min: currentUser?.out_orders?.amount_min || '',
        }
      }

      const response = await handleGetOutOrders(params)
      const dataColored = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.out_order_status_id)
      }))

      setData(dataColored);
      setTotalCount(response.data.metadata.count)

      const respPending = await handleGetOutOrders({
        ...params,
        pageNumber: 1,
        pageSize: undefined,
        out_order_status_id_filter: [OutOrderStatusI.PENDIENTE_PREPARACION],
        sin_total: true,
      })
      setPendingOrders(respPending?.data?.result)

      setSelectedRows([])
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetOutOrders, query, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()
  const navigate = useNavigate();

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow?.out_order_status_id === OutOrderStatusI.EN_REVISION && Boolean(currentRow?.requires_coordination)) {
      actions.push({
        label: "Coordinar entrega",
        icon: "bi bi-box-arrow-up-right text-success",
        action: actionCoordinate
      })
    }

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    if (
      currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE) &&
      currentRow?.out_order_status_id == OutOrderStatusI.EN_PREPARACION
    ) {
      actions.push({
        label: "Ir a la ola",
        icon: "bi bi-tsunami text-success",
        action: () => navigate(`${routes.WAVES}/${currentRow.wave_id}`)
      })
    }

    if (
      currentUser.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE) &&
      currentRow?.remito_id == null &&
      (
        currentRow?.out_order_status_id == OutOrderStatusI.EN_REVISION ||
        currentRow?.out_order_status_id == OutOrderStatusI.COORDINACION_ENTREGA ||
        currentRow?.out_order_status_id == OutOrderStatusI.PENDIENTE_PREPARACION
      )
    ) {
      actions.push({
        label: 'Liberar reserva',
        icon: 'bi bi-box-arrow-up text-warning',
        action: () => liberarReserva()
      })
    }

    if (currentRow?.out_order_status_id === OutOrderStatusI.PENDIENTE_DESPACHO) {
      actions.push({
        label: "Descargar Remito",
        icon: "bi bi-download text-success",
        action: () => printWaybill()
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.OUT_ORDERS}/${currentRow.id}`)
    }
  }

  const actionCoordinate = async() => {
    const resp = await Swal.fire({
      title: "Advertencia",
      text: `Envía a coordinar a la órden #${currentRow?.id}`,
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No",
      confirmButtonText: "Si, enviar a coordinación",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true,
    })

    if (resp.isConfirmed) {
      updateOutOrderStatus(OutOrderStatusI.COORDINACION_ENTREGA)
    }
  }


  const printWaybill = async() => {
    if (currentRow) {
      try {
        let copias = 2
        const response = await handlePrintWaybill(currentRow.id, copias)

        // const arrayBuffer = await response.arrayBuffer()
        if (response.status === 200) {
          toast.success('¡Remito descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${currentRow.remito_id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  const updateOutOrderStatus = async(newStatus)=>{
    try {
      const response = await handleUpdateOutOrderStatus(currentRow?.id, newStatus)

      toast.success("Orden actualizada correctamente", {theme: "colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      getOutOrders()
    }
  }

  const liberarReserva = async () => {
    if (currentRow) {
      try {
        const resp = await Swal.fire({
          title: "Advertencia",
          text: `¿Liberar reserva de la Orden de egreso #${currentRow.id}?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, liberar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          const response = await handleLiberarReserva(currentRow.id)
          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        getOutOrders()
      }
    }
  }
  /* Fin menu contextual */

  const cols = useMemo(() => {
    const c = [...columns]

    const hasActionsColumns = columns.some(c => c.id == 'actions')

    if (!hasActionsColumns) {
      c.push({
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => (
          <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
        ),
        visible: true,
        notHide: true,
      })
    }

    return c
  }, [columns, handleContextMenu, threeDotsRef])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  const dataWithSelection = useMemo(() => {
    return data.map((item) => ({
      ...item,
      isSelected: selectedRows.some(sel => sel.id === item.id),
    }))
  }, [data, selectedRows])

  useEffect(() => {
    getOutOrders()
  }, [getOutOrders])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Órdenes de egreso'}
        filter={<Filter setData={setData} query={query} setQuery={setQuery} setTotalCount={setTotalCount} loading={loading} />}
        actions={
          currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE) &&
            <TableActions
              selectedRows={selectedRows}
              handleSelectedRows={setSelectedRows}
              handleGetOutOrders={getOutOrders}
              loading={loading}
              data={data}
              pendingOrders={pendingOrders}
            />
        }
        filterColumns
        columns={cols}
        setColumns={setColumns}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable
          columns={cols.filter(c => c.visible !== false)}
          data={dataWithSelection || []}
          paginationOptions={paginationOptions}
          queryMD={query}
          setQueryMD={setQuery}
          handleContextMenu={handleContextMenu}
        />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default OutOrders