import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'
import moment from 'moment'

import { useReports, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { COLUMNS } from './columns'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'name',
  sortOrder: 'ASC',
  con_stock: 1,
}

const InventarioReport = () => {
  const { loading, handleGetReporteInventario } = useReports()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [columns, setColumns] = useState(COLUMNS)

  const getReporteInventario = useCallback(async (params = query) => {
    try {
      let requestParams = {}

      if (params && params.pageNumber) {
        requestParams = {
          ...requestParams,
          ...params,
        }
      }

      const response = await handleGetReporteInventario(requestParams)
      const data = response.data.result.map(d => ({
        ...d,
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [query, handleGetReporteInventario])

  const exportData = async () => {
    try {
      let params = {
        ...query,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleGetReporteInventario(params)

      if (response.data.result) {
        const datos = response.data.result.map(d => {
          const data = {
            'ID': d.id,
            'ID Reg': d.reg_id,
            'SKU': d.sku,
            'Nombre': d.name,
            'Droga': d.droga,
            'Laboratorio': d.laboratorio,
            'Trazable': Boolean(d?.trazable) ? 'SÍ' : 'NO',
            'Frío': Boolean(d?.Frio) ? 'SÍ' : 'NO',
            'TA': Boolean(d?.TA) ? 'SÍ' : 'NO',
            'Unidades': d.unidades,
            'ID Cliente': d.customer_supplier_id,
            'Cliente': d.customer_supplier_business_name,
            'ID Unidad de negocio': d.business_unit_id,
            'Unidad de negocio': d.business_unit_name,
            'Proveedor usual': d.ProveedorUsual,
            'Condicion de pago': d.CondicionPago,
            'Disponible': d.Disponible,
            'Reservado': d.Reservado,
            'Pickeados': d.Pickeados,
            'NoDisponible': d.NoDisponible,
            '¿Precio manual?': Boolean(d?.EsPrecioManual) ? 'SÍ' : 'NO',
            'PVP': d.pvp,
            'PVP Disponible': d.DisponiblePVP,
            'PSL': d.psl,
            'PSL Disponible': d.DisponiblePSL,
            'Costo lista': d.CostoLista,
            'Costo lista Disponible': d.DisponibleCostoLista,
            'Costo especial mejor': d.CostoEspecialMejor,
            'Ultimo precio de compra': d.UltimoPrecioCompra,
            'Lote': d.Lote,
            'Próx. vencimiento': d.ProxVencimiento,
            'Días próx. vencimiento': d.DiasProxVencimiento,
          }

          return data
        })

        let ws = XLSX.utils.json_to_sheet(datos)

        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Reportes")
        XLSX.writeFile(wb, `Inventario ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  };

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    return actions
  }
  /* Fin Menu contextual */

  const cols = useMemo(() => {
    const c = [...columns]

    const hasActionsColumns = columns.some(c => c.id == 'actions')

    if (!hasActionsColumns) {
      c.push({
        Header: '',
        id: 'actions',
        label: '',
        className:'col-icons',
        accessor: (row) =>
          true > 0 && (
            <i
              ref={threeDotsRef}
              id='threeDots'
              className="bi bi-three-dots-vertical"
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
              onClick={event => handleContextMenu(event, row)}
            />
          ),
        visible: true,
        notHide: true,
      })
    }

    return c
  }, [columns, threeDotsRef, handleContextMenu])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: (newQuery) => {
      setQuery(newQuery)
      getReporteInventario(newQuery)
    }
  }

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title='Reporte de Inventario'
        filter={
          <Filter
            loading={loading}
            data={data}
            setData={setData}
            query={query}
            setQuery={setQuery}
            setTotalCount={setTotalCount}
            exportData={exportData}
            onSearch={getReporteInventario}
          />
        }
        filterColumns
        columns={cols}
        setColumns={setColumns}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable
          columns={cols.filter(c => c.visible !== false)}
          data={data}
          paginationOptions={paginationOptions}
          queryMD={query}
          setQueryMD={setQuery}
        />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default InventarioReport