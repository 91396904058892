import { useState, useMemo, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify';
import moment from 'moment';
import * as XLSX from 'xlsx/xlsx.mjs';
import { helix } from 'ldrs'

import { useSalesOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import Filter from './Filter'
import { COLUMNS } from './columns';

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const PurchaseStockManagement = () => {
  const { loading, handleGetPendingDetail } = useSalesOrders()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0);

  const [columns, setColumns] = useState(COLUMNS)

  const getRequests = useCallback(async () => {
    try {
      let params = {
        order_status_id: 1,
      }

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }
      const response = await handleGetPendingDetail(params)

      if (response.data && response.data.result) {
        const data = response.data.result

        const dataColored = data.map(d => ({
          ...d,
          Necesario: +d.Necesario < 0 ? 0 : +d.Necesario,
          NecesarioMasAutorizacion: +d.NecesarioMasAutorizacion < 0 ? 0 : +d.NecesarioMasAutorizacion
        }))

        const totalCount = response.data.metadata.count

        setData(dataColored)
        setTotalCount(totalCount)
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [queryMD, handleGetPendingDetail])

  const exportData = async () => {
    try {
      const response = await handleGetPendingDetail({ ...queryMD, pageNumber: 1, pageSize:10000 })

      if (response.data?.result) {
        const datos = response.data.result.map(d => {
          let data = {
            'Unidad de negocio': d.business_unit_name,
            'Dueño': d.customer_suppliers_name,
            "Artículo": d.name,
            "Droga": d.droga,
            "ID Artículo": d.id,
            "Laboratorio": d.laboratorio,
            "SKU": d.sku,
            "Disponible": +d.Disponible,
            "No Disponible": +d.NoDisponible,
            "Pendiente Ingreso": +d.PendienteIngreso,
            "Pendiente Autorizacion": +d.EnAutorizacion,
            "Reservado": +d.Reservado,
            "En Falta": +d.FaltaStock,
            "Comprar":  +d.Necesario < 0 ? 0 : +d.Necesario,
            "Comprar + Autorizacion": +d.NecesarioMasAutorizacion < 0 ? 0 : +d.NecesarioMasAutorizacion
          };

          return data
        })

        var ws = XLSX.utils.json_to_sheet(datos)

        var wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "GestionCompras")
        XLSX.writeFile(wb, `Gestion_compras_${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      setData([])
    }
  }

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  };

  const cols = useMemo(() => {
    const c = [...columns]

    return c
  }, [columns])

  useEffect(() => {
    getRequests()
  }, [getRequests])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <TableContainer
      title='Gestión de Compras'
      filter={<Filter loading={loading} queryMD={queryMD} setQueryMD={setQueryMD} exportData={exportData} />}
      filterColumns
      columns={cols}
      setColumns={setColumns}
    >
      {loading && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

      <CustomTable
        columns={cols.filter(c => c.visible !== false)}
        data={data}
        queryMD={queryMD}
        setQueryMD={setQueryMD}
        paginationOptions={paginationOptions}
      />
    </TableContainer>
  )
}

export default PurchaseStockManagement