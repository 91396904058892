import { useState, useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const CreditNoteModal = ({ show, onHide }) => {
    const [observaciones, setObservaciones] = useState("")
    const [motivo, setMotivo] = useState('')
    const [fceAnulacion, setFceAnulacion] = useState(false)
    const [errorCarga, setErrorCarga] = useState(false)

    const onSave = () => {
        if (!observaciones || observaciones.trim() == "") {
            setErrorCarga(true)
            return
        }

        onHide({ observaciones, motivo, fceAnulacion })
    }

    useEffect(() => {
        setObservaciones("")
        setMotivo('')
        setFceAnulacion(false)
        setErrorCarga(false)
    }, [show])

    return (
        <Modal show={show} onHide={onHide} backdrop='static' centered>
            <Modal.Header closeButton>
                <Modal.Title>Generar nota de crédito</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                    <div className='row mb-4'>
                        <div className="col">
                            <label htmlFor='observaciones' className='form-label'>Observaciones</label>
                            <textarea
                                id='observaciones'
                                className={`form-control ${errorCarga?'border-danger':''}`}
                                rows={3}
                                value={observaciones}
                                onChange={(e=> {
                                    setErrorCarga(false)
                                    setObservaciones(e.target.value)
                                })}
                            />
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className="col">
                            <label htmlFor='motivo' className='form-label'>Motivo (uso interno)</label>
                            <textarea
                                id='motivo'
                                className={`form-control ${errorCarga ? 'border-danger' : ''}`}
                                rows={3}
                                value={motivo}
                                onChange={(e=> {
                                    setErrorCarga(false)
                                    setMotivo(e.target.value)
                                })}
                            />
                        </div>
                    </div>

                    <div className='separator my-8' />

                    <div className="row">
                        <div className="col-10">
                            <Form.Label className='pb-2'>¿Nota de crédito responde a un rechazo de la FCEA? <i>(Sólo válido en factura de crédito electrónica)</i></Form.Label>
                        </div>

                        <div className='col-2 d-flex flex-row align-items-center'>
                            <Form.Group>
                                <Form.Check
                                    type="switch"
                                    className={''}
                                    checked={fceAnulacion}
                                    onChange={() => setFceAnulacion(!fceAnulacion)}
                                />
                            </Form.Group>
                        </div>
                    </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant='secondary' onClick={()=>onHide(null)}>Cancelar</Button>
                <Button variant='primary' onClick={onSave}>Generar NC</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreditNoteModal