import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'

import useTransactionalDocuments from 'hooks/useTransactionalDocuments'
import { Input, SelectField, SwitchField } from 'components'

import { TransactionalDocumentTypeI } from 'interfaces'

import { labelColor, statusDisplay } from './'

/*
  (1, 'Factura A'),
  (2, 'Nota de Débito A'),
  (3, 'Nota de Crédito A'),
  (6, 'Factura B'),
  (7, 'Nota de Débito B'),
  (8, 'Nota de Crédito B'),
  (51, 'Factura M. No habilitado para CAEA'),
  (52, 'Nota de Débito M. No habilitado para CAEA'),
  (53, 'Nota de Crédito M. No habilitado para CAEA'),
  (201, 'Factura de Crédito Electrónica MiPyMEs (FCE) A'),
  (202, 'Nota de Débito Electrónica MiPyMEs (FCE) A'),
  (203, 'Nota de Crédito Electrónica MiPyMEs (FCE) A'),
  (206, 'Factura de Crédito Electrónica MiPyMEs (FCE) B'),
  (207, 'Nota de Débito Electrónica MiPyMEs (FCE) B'),
  (208, 'Nota de Crédito Electrónica MiPyMEs (FCE) B'),
  (91, 'Remito (sólo para comprobantes asociados)'),
  (995, 'Remito Electrónico de Carne (sólo para comprobantes asociados)'),
  (88, 'Remito Electrónico de Tabaco Acondicionado (sólo para comprobantes asociados)'),
  (990, 'Remito Electrónico de Tabaco en Hebras (sólo para comprobantes asociados)'),
  (997, 'Remito Electrónico de Azúcar para Mercado Interno (sólo para comprobantes asociados)'),
  (993, 'Remito Electrónico de Harina en Camión (sólo para comprobantes asociados)'),
  (994, 'Remito Electrónico de Harina en Tren (sólo para comprobantes asociados)')

const optionsBusinessUnits = [
  { value: undefined, label: 'Todos' },
  { value: 'Farma', label: 'Farma' },
  { value: 'Shop', label: 'Shop' }
]*/

const voucherTypes = [
  { value: TransactionalDocumentTypeI.FACTURA, label: "Factura" },
  { value: TransactionalDocumentTypeI.NOTA_DEBITO, label: "Nota de Débito" },
  { value: TransactionalDocumentTypeI.NOTA_CREDITO, label: "Nota de Crédito" },
  { value: TransactionalDocumentTypeI.REMITO, label: "Remito" },
]

const status = [
  { value: 'SUCCESS', label: 'Aprobado', },
  { value: 'SUCCESS_WITH_OBSERVATIONS', label: 'Aprobado c/ observaciones', },
  { value: 'ERROR_NEGOCIO_AFIP', label: 'Error AFIP', },
  { value: 'ERROR_PROCESAR_COMPROBANTE', label: 'Error comprobante', },
  { value: 'ANULADO', label: 'Anulado', },
  { value: 'DEFERRED', label: 'Factura Diferida', },
]

const initialValues = {
  transactional_document_type_id: [TransactionalDocumentTypeI.FACTURA],
  /*
  1: FACTURA,
  2: NOTA_DEBITO,
  3: NOTA_CREDITO,
  4: REMITO
  */
  //business_unit_name: '',
  id: '',
  comprobante: '',
  date_from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
  date_to: moment().format('YYYY-MM-DD'),
  with_nc: 0, // = 0
  text: '',
  sales_order_id: '',
  out_order_id: '',
  status: [],
  /*
    SUCCESS,
    SUCCESS_WITH_OBSERVATIONS,
  */
}

const Filter = ({ setData, setFilter, queryMD, setQueryMD, setTotalCount, exportData  }) => {
  const { loading, handleGetDocuments } = useTransactionalDocuments()

  const filterData = async (values) => {
    try {
      let params = {
        ...queryMD,
        ...values,
        id: values?.id ? values.id : undefined,
        transactional_document_type_id: values?.transactional_document_type_id && values.transactional_document_type_id.length > 0 ? values.transactional_document_type_id : undefined,
        comprobante: values?.comprobante ? values.comprobante : undefined,
        date_from: values?.date_from ? values.date_from : undefined,
        date_to: values?.date_to ? values.date_to : undefined,
        with_nc: values?.with_nc ? values.with_nc : undefined,
        text: values?.text ? values.text : undefined,
        sales_order_id: values?.sales_order_id ? values.sales_order_id : undefined,
        out_order_id: values?.out_order_id ? values.out_order_id : undefined,
        status: values?.status && values.status.length > 0 ? values.status : undefined,
        //business_unit_name: values?.business_unit_name || undefined,
      }

      const response = await handleGetDocuments(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.estado),
        status_display: statusDisplay(d.estado),
      }))

      setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleChangeType = (ev, setFieldValue) => {
    if (ev.value != voucherTypes.filter(v => v.label == 'Factura')[0].value) {
      setFieldValue('with_nc', false)
    }
  }

  //Nro de pedido, estado, si tiene o no nc asociada, tipo (multiple), fecha (por defecto 7 días para atras hasta hoy),
  //sacar filtro letra, cambiar punto de venta por un input libre, id, nro, cliente
  //-04-30&punto_venta=1&sales_order_id=225

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterData}
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-1'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='transactional_document_type_id'
                name='transactional_document_type_id'
                options={voucherTypes}
                handleChange={(ev) => handleChangeType(ev, setFieldValue)}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Tipo</p>
            </div>

            <div className='col-3'>
              <Input id='comprobante' name='comprobante' disabled={loading} />
              <p className='text-muted'>Letra / Pto. venta / Comprobante / CAE</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' disabled={loading} />
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' disabled={loading} />
              <p className='text-muted'>Hasta</p>
            </div>

            <div className="col-2 pt-3">
              <div className="d-flex">
                <SwitchField
                  id='with_nc'
                  name='with_nc'
                  disabled={loading || values.transactional_document_type_id != voucherTypes.filter(v => v.label == 'Factura')[0]?.value}
                />
                <span style={{ fontSize: '1.1rem' }}>con NC</span>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-3'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>Cliente</p>
            </div>

            <div className='col-2'>
              <Input id='sales_order_id' name='sales_order_id' placeholder='Ej: 1234, 4321' disabled={loading} />
              <p className='text-muted'>ID Pedido Venta</p>
            </div>

            <div className='col-2'>
              <Input id='out_order_id' name='out_order_id' placeholder='Ej: 1234, 4321' disabled={loading} />
              <p className='text-muted'>ID O. Egreso</p>
            </div>

            {values.transactional_document_type_id != TransactionalDocumentTypeI.REMITO &&
              <div className='col-3'>
                <SelectField
                  id='status'
                  name='status'
                  options={status}
                  placeholder='Seleccionar estado'
                  marginTop={'-20px'}
                  isMulti
                  disabled={loading}
                />
                <p className='text-muted'>Estado</p>
              </div>
            }
              {/*
              <div className='col-2'>
                <SelectField
                  id='business_unit_name'
                  name='business_unit_name'
                  options={optionsBusinessUnits}
                  marginTop={'-20px'}
                  disabled={loading}
                />
                <p className='text-muted'>Unidad de negocio</p>
              </div>
            */}
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => exportData()}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-file-earmark-excel mb-1" />
                Exportar
              </Button>

              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm({ values: initialValues })
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className='bi bi-zoom-out mb-1 ' />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter