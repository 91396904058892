import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AssignmentOutlined, ImportContactsOutlined, Diversity3Outlined, SellOutlined, MonetizationOnOutlined, GroupsOutlined, FileOpenOutlined, GroupOutlined } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useCustomerSuppliers } from 'hooks'

import { PageTitle } from '_metronic/layout/core'
import { DetailContainer, TabsContainer } from 'containers'

import { initialValues, schema, dataAdapter, updateDataAdapter } from './constants'
import { Actions, GeneralTab, ShipmentAddressesTab, CustomersTab, SellingsTab, TabCreditos, SuppliersTab, PatientsTab, FilesTab } from './partials'

import { formatInputDate, replaceNullsWithEmptyString } from 'utilities'
import routes from 'app/routing/routes'
import { RoleI } from 'interfaces'

const CustomerSupplierDetail = () => {
  const { id } = useParams()
  const { currentUser } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()

  const initialTab = location.state?.tab || 'general'
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const { handleGetCustomerSupplier, handleUpdateCustomerSupplier } = useCustomerSuppliers()

  const [loading, setLoading] = useState(false)
  const [customerSupplier, setCustomerSupplier] = useState(initialValues)
  const [editing, setEditing] = useState(false)

  const getCustomerSupplier = useCallback(async () => {
    try {
      setLoading(true)

      const response = await handleGetCustomerSupplier(id, 'token')
      let data = dataAdapter(response.result)
      setCustomerSupplier(replaceNullsWithEmptyString(data))
    } catch (error) {
      toast.error(error.message)
      navigate(routes.CUSTOMER_SUPPLIERS)
    } finally {
      setLoading(false)
    }
  }, [id, handleGetCustomerSupplier])

  const handleUpdate = async (values) => {
    if (Boolean(values.is_customer) && values.seller_id == '') {
      toast.error('Por favor, seleccione un vendedor en la pestaña "Ventas"')
      return
    }
    if (Boolean(values.is_customer) && (values.billing_type_id == '' || values.price_update_id == '')) {
      toast.error('Por favor, seleccione el tipo de facturación y/o actualización de precios en la pestaña Clientes')
      return
    }

    try {
      setEditing(true)

      let data = updateDataAdapter(values)

      const response = await handleUpdateCustomerSupplier(id, data, 'token')
      toast.success(response.message)
      setEditing(false)
    } catch (error) {
      toast.error(error.message)
      setEditing(true)
    } finally {
      getCustomerSupplier()
    }
  }

  const handleEdit = () => {
    setEditing(true)
  }

  useEffect(() => {
    getCustomerSupplier()
  }, [getCustomerSupplier])

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Relaciones comerciales/', path: routes.DASHBOARD, isSeparator: false, isActive: true },
          { title: 'Clientes proveedores/', path: routes.CUSTOMER_SUPPLIERS, isSeparator: false, isActive: true },
          { title: `Detalle del cliente/proveedor #${id}`, path: `${routes.CUSTOMER_SUPPLIERS}/${id}`, isActive: false }
        ]}
        description={`Detalle del cliente/proveedor #${id}`}
      />
      <DetailContainer
        title={`Detalle del cliente/proveedor #${id}`}
        buttons={<Actions values={customerSupplier} editing={editing} handleEdit={handleEdit} submitRef={submitRef} cancelRef={cancelRef} loading={loading} />}
      >
        <Formik
          initialValues={{
            ...customerSupplier,
            enrollment_date: formatInputDate(customerSupplier.enrollment_date),
          }}
          validationSchema={schema}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({ values, errors, handleReset, handleSubmit }) => {
            return (
              <Form className='d-flex flex-column'>
                <TabsContainer
                  initialValue={initialTab}
                  tabs={[
                    { value: 'general', label: intl.formatMessage({ id: 'TABS.CUSTOMER_SUPPLIERS.GENERAL' }), icon: <AssignmentOutlined />, show: true },
                    { value: 'shipment-addresses', label: intl.formatMessage({ id: 'TABS.CUSTOMER_SUPPLIERS.SHIPMENT_ADDRESSES' }), icon: <ImportContactsOutlined />, show: true },
                    { value: 'customers', label: 'Clientes', icon: <Diversity3Outlined />, show: Boolean(values.is_customer), errors: errors.billing_type_id || errors.price_update_id },
                    { value: 'sellings', label: intl.formatMessage({ id: 'TABS.CUSTOMER_SUPPLIERS.SELLINGS' }), icon: <SellOutlined />, show: (values.is_customer || (values.is_customer && values.is_supplier)), errors: errors.seller_id },
                    { value: 'creditos', label: 'Créditos', icon: <MonetizationOnOutlined />, show: Boolean(values.is_customer), errors: Boolean(values.is_customer) && values.seller_id == ''},
                    { value: 'suppliers', label: 'Proveedores', icon: <GroupOutlined />, show: values.is_distributor },
                    { value: 'patients', label: intl.formatMessage({ id: 'TABS.CUSTOMER_SUPPLIERS.PATIENTS' }), icon: <GroupsOutlined />, show: true },
                    { value: 'files', label: intl.formatMessage({ id: 'TABS.CUSTOMER_SUPPLIERS.FILES' }), icon: <FileOpenOutlined />, show: true },
                  ]}
                  tabsContent={[
                    <GeneralTab editing={editing} handleSubmit={handleUpdate} />,
                    <ShipmentAddressesTab editing={editing} handleEditing={setEditing} handleGetCustomerSupplier={getCustomerSupplier} />,
                    Boolean(values.is_customer) && <CustomersTab editing={editing} />,
                    (values.is_customer || (values.is_customer && values.is_supplier)) && <SellingsTab editing={editing} />,
                    (values.is_customer || (values.is_customer && values.is_supplier)) && <TabCreditos actualizando={editing} />,
                    values.is_distributor && <SuppliersTab handleGetCustomerSupplier={getCustomerSupplier} />,
                    <PatientsTab editing={editing} handleEditing={setEditing} handleGetCustomerSupplier={getCustomerSupplier} />,
                    <FilesTab editing={editing} handleEditing={setEditing} handleGetCustomerSupplier={getCustomerSupplier} />
                  ]}
                />

                {currentUser.roles.some(r => r.id != RoleI.COORDINACION) && editing && (
                  <>
                    <div className='separator my-8'></div>

                    <div className='d-flex flex-row justify-content-end'>
                      <Button
                        ref={cancelRef}
                        variant='secondary'
                        type='button'
                        className='me-3'
                        onClick={() => {
                          setEditing(false)
                          handleReset()
                        }}
                      >
                        <i className="bi bi-slash-circle" />
                        Cancelar
                      </Button>

                      <Button ref={submitRef} variant='primary' type='submit' disabled={loading}><i className='bi bi-save me-2' />Guardar</Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default CustomerSupplierDetail