import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useSalesOrders } from 'hooks'
import { Input, SelectField } from 'components'

import { SalesOrderStatusI } from 'interfaces'

import { labelColor } from '../..'

const initialValues = {
  id_text: '',
  text: '',
  article_text: '',
  from_date: '',
  to_date: '',
  amount_min: '',
  sales_order_status_id_filter: [SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO],
}

const Filter = ({ query, setQuery, loading }) => {
  const [formikInitialValues] = useState(initialValues)
  const { loading: loadingSO, handleGetSalesOrders, handleGetSalesOrderStatus } = useSalesOrders()
  const [status, setStatus] = useState([])

  const filterData = (values) => {
    let params = {
      ...values,
      ...query,
      id_text: values?.id_text ? values.id_text : undefined,
      text: values?.text ? values.text : undefined,
      article_text: values?.article_text ? values.article_text : undefined,
      amount_min: values?.amount_min ? values.amount_min : undefined,
      sales_order_status_id_filter: values.sales_order_status_id_filter?.length > 0 ? values.sales_order_status_id_filter : undefined,
      getDetail: true,
    }

    setQuery({ ...params, pageNumber: 1, pageSize: 10 })
  }

  const getSalesOrderStatus = useCallback(async () => {
    try {
      const response = await handleGetSalesOrderStatus()
      const data = response.data.result.map(d => ({ value: d.id, label: d.label }))

      setStatus(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetSalesOrderStatus])

  useEffect(() => {
    getSalesOrderStatus()
  }, [getSalesOrderStatus])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' />
              <p className='text-muted'>ID Pedido</p>
            </div>

            <div className='col-6'>
              <Input id='text' name='text' /><p className='text-muted'>CUIT / Cliente / Paciente / Vendedor / Dirección</p>
            </div>

            <div className='col-2'>
              <Input id='from_date' name='from_date' type='date' /><p className='text-muted'>Desde</p>
            </div>

            <div className='col-2'>
              <Input id='to_date' name='to_date' type='date' /><p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-8'>
              <Input id='article_text' name='article_text' />
              <p className='text-muted'>Artículo / Droga</p>
            </div>

            <div className='col-4'>
              <Input
                id='amount_min'
                name='amount_min'
                onChange={(e) => setFieldValue('amount_min', e.target.value.replace(/\D/g, ''))}
              />
              <p className='text-muted'>Monto mayor a</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <SelectField
                id='sales_order_status_id_filter'
                name='sales_order_status_id_filter'
                options={status}
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
                isMulti
              />
              <p className='text-muted'>Estado</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-9'></div>

            <div className='col-3 text-end'>
              <Button variant="primary" type='submit' disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter