import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap';
import moment from 'moment';

import { currencyFormat } from 'utilities';

import '../reports.css'

let sistemaLabel = (sistema) => {
  if (!sistema) return ''

  switch (sistema) {
    case 'riga_farma':
      return 'Farma'
    case 'mi_test':
      return 'Mi test'
    default:
      return 'Sin sistema'
  }
}

let sistemaLabelColor = (sistema) => {
  if (!sistema) return ''

  switch (sistema) {
    case 'riga_farma':
      return 'estado-celeste'
      return 'estado-azul'
    case 'mi_test':
      return 'estado-amarillo'
    default:
      return 'estado-gris'
  }
}

const useGetColumns = (mostrarModalAgrupadas) => {
  const COLUMNS = [
    {
      Header: <p className='m-0 p-0'>ID</p>,
      id: 'id_comprobante',
      label: 'ID',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.id_comprobante}
        </p>
      ),
      hasOrder: true,
      visible: true,
      notHide: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">Sistema</p>,
      id: 'sistema',
      label: 'Sistema',
      accessor: (row) => (
        <span className={`badge ${sistemaLabelColor(row.sistema)} w-100 justify-content-center fw-normal`}>
          {sistemaLabel(row.sistema)}
        </span>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">IDs Pedido</p>,
      id: 'ids_pedidos',
      label: 'IDs Pedido',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.ids_pedidos && row.ids_pedidos.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  mostrarModalAgrupadas({
                    title: 'Pedidos de venta',
                    ids: row.ids_pedidos.split(','),
                    esPedido: true,
                  })
                }}
              >
                Pedidos agrupados
              </span>
            : <span>{row.ids_pedidos}</span>
          }
        </p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">IDs O. Egreso</p>,
      id: 'ids_ordenes',
      label: 'IDs O. Egreso',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.ids_ordenes && row.ids_ordenes.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  mostrarModalAgrupadas({
                    title: 'Órdenes de egreso',
                    ids: row.ids_ordenes.split(','),
                    esPedido: false,
                  })
                }}
              >
                Órdenes agrupadas
              </span>
            : <span>{row.ids_ordenes}</span>
          }
        </p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">IDs Remito</p>,
      id: 'ids_remitos',
      label: 'IDs Remito',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.ids_remitos && row.ids_remitos.split(',').length > 1
            ?
              <span
                className='text-primary cursor-pointer'
                onClick={() => {
                  mostrarModalAgrupadas({
                    title: 'Remitos',
                    ids: row.ids_remitos.split(','),
                    esPedido: false,
                  })
                }}
              >
                Remitos agrupados
              </span>
            : <span>{row.ids_remitos}</span>
          }
        </p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">Fecha</p>,
      id: 'fecha_emision',
      label: 'Fecha',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.fecha_emision ? moment(row.fecha_emision).format("DD/MM/YYYY") : '-'}
        </p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">Tipo</p>,
      id: 'tipo_comprobante_afip',
      label: 'Tipo',
      className: 'col-comprobante',
      accessor: (row) => (
        <p className="m-0 p-0 break-word text-center">
          {row.tipo_comprobante_afip}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">Comprobante</p>,
      id: 'nro_completo',
      label: 'Comprobante',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.nro_completo}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-center">CAE</p>,
      id: 'cae',
      label: 'CAE',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.cae}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0'>Cliente</p>,
      id: 'cliente',
      label: 'Cliente',
      accessor: (row) => (
        <strong className='m-0 p-0'>{row.cliente}</strong>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className="m-0 p-0 text-end">Total</p>,
      id: 'importe_total',
      label: 'Total',
      accessor: (row) => (
        <p className='m-0 p-0 text-end'>
          {currencyFormat.format(row?.importe_total || 0)}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>Estado</p>,
      id: 'estado',
      label: 'Estado',
      accessor: (row) => (
        <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.status_display}
        </span>
      ),
      visible: true,
    },
    {
      Header: '',
      id: 'comprobante_cancelacion_id',
      accessor: (row) => (
        <div className='text-center'>
          {row?.comprobante_cancelacion && row.comprobante_cancelacion?.id &&
            <OverlayTrigger
              placement='top'
              overlay={
                <TooltipBS id={`tooltip-${row.id}`}>
                  <div className='text-start'>
                    <p className='m-0 p-0'>ID: {row.comprobante_cancelacion.id}</p>
                    <p className='m-0 p-0 mt-2'>Comprobante: {row?.comprobante_cancelacion.nro_completo}</p>
                    {row?.comprobante_cancelacion.tipo_comprobante_agrupado !== 1
                      ? <p className='m-0 p-0 mt-2'>Tipo: {row?.comprobante_cancelacion?.tipo_comprobante_agrupado_desc || '-'}</p>
                      : <p className='m-0 p-0 mt-2'>Motivo: {row?.comprobante_cancelacion?.motivo || '-'}</p>
                    }
                  </div>
                </TooltipBS>
              }
            >
              <i className="bi bi-code-square text-danger ms-2 fs-3" />
            </OverlayTrigger>
          }
        </div>
      ),
      visible: true,
      notHide: true,
    }
  ]

  return {
    COLUMNS,
  }
}

export default useGetColumns