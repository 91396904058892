import { Link } from 'react-router-dom'

import { Tooltip } from 'components'

import { currencyFormat } from 'utilities'
import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: "ID Artículo",
    id: 'product_var_id',
    label: 'ID Artículo',
    accessor: (row) => (
      <Tooltip text='Ver artículo'>
        <Link to={`${routes.ARTICLES}/${row.product_var_id}`} state={{ product_id: row.product_id }}>
          {row.product_var_id}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
    visible: true,
    notHide: true,
  },
  {
    Header: "Artículo",
    id: 'product_var_name',
    label: 'Artículo',
    accessor: (row) => (
      <>
        {row.product_var_name}
      </>
    ),
    hasOrder: true,
    visible: true,
    notHide: true,
  },
  {
    Header: "PVP",
    id: 'pvp',
    label: 'PVP',
    accessor: (row) => (
      <>{currencyFormat.format(row.pvp)}</>
    ),
    visible: true,
  },
  {
    Header: "PSL",
    id: 'psl',
    label: 'PSL',
    accessor: (row) => (
      <>{currencyFormat.format(row.psl)}</>
    ),
    visible: true,
  },
  {
    Header: "Bonif. (%)",
    id: 'initial_cost_percent',
    label: 'Bonif. (%)',
    accessor: (row) => (
      <>{row.initial_cost_percent? parseFloat(row.initial_cost_percent).toFixed(5):''}</>
    ),
    visible: true,
  },
  {
    Header: "C. inicial",
    id: 'initial_cost',
    label: 'C. inicial',
    accessor: (row) => (
      <>{currencyFormat.format(row.initial_cost)}</>
    ),
    visible: true,
  },
  {
    Header: "C. asoc. (%)",
    id: 'add_cost_percent',
    label: 'C. asoc. (%)',
    accessor: (row) => (
      <>
        {row.add_cost_percent?parseFloat(row.add_cost_percent).toFixed(5):''}
      </>
    ),
    visible: true,
  },
  {
    Header: "C. asoc.",
    id: 'add_cost',
    label: 'C. asoc.',
    accessor: (row) => (
      <>
        {currencyFormat.format(row.add_cost)}
      </>
    ),
    visible: true,
  },
  {
    Header: "SIRCREB",
    id: 'sircreb_amount',
    label: 'SIRCREB',
    accessor: (row) => (
      <>
        {currencyFormat.format(row.sircreb_amount)}
      </>
    ),
    visible: true,
  },
  {
    Header: "Costo total",
    id: 'total_cost',
    label: 'Costo total',
    accessor: (row) => (
      <>
        {currencyFormat.format(row.total_cost)}
      </>
    ),
    visible: true,
  },
]

export {
  COLUMNS
}