import { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, usePedidos, useSalesOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter, AuthModal } from './partials'
import { COLUMNS } from './columns'

import routes from 'app/routing/routes'
import { SalesOrderStatusI, OrderTypeI, RoleI, BillingTypesI, AutorizacionCreditoI } from 'interfaces'

import { useActions } from '../DetalleV2/services/actions'

import { CancelarPedidoModal } from 'pages/Sells/common/components'
import { orderTypeColor, salesOrderStatusColor } from 'pages/Sells/common/utils'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  to_date: moment().format('YYYY-MM-DD'),
  sales_order_status_id_filter: [SalesOrderStatusI.BORRADOR],
}

const Pedidos = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const [columns, setColumns] = useState(COLUMNS)

  const [modal, setModal] = useState(false)
  const [idPedido, setIdPedido] = useState(null)
  const [especial, setEspecial] = useState(false)
  const [parcial, setParcial] = useState(false)
  const [anticipada, setAnticipada] = useState(false)
  const [cancelarModal, setCancelarModal] = useState(false)

  const { handleUpdateSalesOrderStatus, handleCreateOutOrder } = useSalesOrders()
  const { loading, handleObtenerPedidos, handleObtenerDetallePedido } = usePedidos()
  const [pedidos, setPedidos] = useState([])

  const {
    cancelarPedido,
    autorizarRentabilidadPedido,
    autorizarCreditoPedido,
    noAutorizarPedido,
    crearOrdenEgreso,
    crearOrdenEgresoParcial,
    cancelacionEspecialPedido,
    cancelacionParcialPedido,
    facturaAnticipadaPedido,
    imprimirPedido,
    clonarPedido,
  } = useActions()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const obtenerPedidos = useCallback(async () => {
    try {
      let params = {
        user_id_filter: [currentUser.user_id],
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.sales_orders) {
        const { sales_orders } = currentUser

        params = {
          ...params,
          id_text: sales_orders?.id_text || '',
          order_type_id_filter: sales_orders?.order_type_id_filter || [],
          purchase_order: sales_orders?.purchase_order || '',
          out_order_id: sales_orders?.out_order_id || '',
          from_date: sales_orders?.from_date || '',
          to_date: sales_orders?.to_date || '',
          nombre: sales_orders?.nombre || '',
          documento: sales_orders?.documento || '',
          customer_supplier_id: sales_orders?.customer_supplier_id || [],
          from_auth_date: sales_orders?.from_auth_date || '',
          to_auth_date: sales_orders?.to_auth_date || '',
          paciente: sales_orders?.paciente || '',
          paciente_documento: sales_orders?.paciente_documento || '',
          patient_id: sales_orders?.patient_id || [],
          seller_id_filter: sales_orders?.seller_id_filter || [],
          article_text: sales_orders?.article_text || '',
          user_id_filter: sales_orders?.user_id_filter ? sales_orders.user_id_filter : [],
          grouped_status_id: sales_orders?.grouped_status_id || [],
          sales_order_status_id_filter: sales_orders?.sales_order_status_id_filter ? sales_orders.sales_order_status_id_filter : [],
        }
      }

      const response = await handleObtenerPedidos(params)
      const data = response.data.result.map(d => ({
        ...d,
        order_type_color: orderTypeColor(d.order_type_id),
        sales_order_status_color: salesOrderStatusColor(d.sales_order_status_id)
      }))

      setPedidos(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setPedidos([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleObtenerPedidos, query, currentUser])

  const obtenerDetallePedido = async (idPedido) => {
    try {
      const response = await handleObtenerDetallePedido(idPedido)
      const data = response.data.result

      return data
    } catch (error) {
      return []
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: 'Ir a detalle',
        icon: 'bi bi-eye text-primary',
        action: () => navigate(`${routes.VENTAS_PEDIDOS}/${currentRow.id}`),
      })

      if (
        [SalesOrderStatusI.BORRADOR].includes(currentRow?.sales_order_status_id) &&
        [OrderTypeI.PEDIDO_VENTA].includes(currentRow?.order_type_id) &&
        currentRow?.customer_supplier_id_autorizacion_credito !== AutorizacionCreditoI.DESHABILITADO_CREDITOS
      ) {
        actions.push({
          label: "Enviar a autorizar",
          icon: "bi bi-box-arrow-in-down-right text-success",
          action: async () => {
            try {
              const detalleData = await obtenerDetallePedido(currentRow.id)

              if (detalleData.length > 0) {
                setModal(true)
              } else {
                toast.warning(`El Pedido #${currentRow.id} no tiene artículos`)
              }
            } catch (error) {
              toast.error(error.message)
            }
          }
        })
      }

      if (
        [SalesOrderStatusI.BORRADOR].includes(currentRow?.sales_order_status_id) &&
        [OrderTypeI.PEDIDO_EGRESO].includes(currentRow?.order_type_id)
      ) {
        actions.push({
          label: 'Enviar a Operaciones',
          icon: 'bi bi-box-arrow-in-down-right text-success',
          action: async () => {
            if (
              currentRow?.address_id_traza == '' ||
              !currentRow?.address_id_traza ||
              currentRow?.address_id_ship == '' ||
              !currentRow?.address_id_ship
            ) {
              toast.error(`El Pedido #${currentRow?.id} no tiene direccion(es) cargada(s)`)
              return
            }

            if (Boolean(currentRow?.to_patient) && !currentRow?.patient_id) {
              toast.error(`No se seleccionó el paciente en el Pedido #${currentRow?.id}`)
              return
            }

            if (Boolean(currentRow?.remito_para_terceros) && !currentRow?.id_tercero_para_remitir) {
              toast.error(`No se seleccionó un tercero para remitir en el Pedido #${currentRow?.id}`)
              return
            }

            try {
              const detalleData = await obtenerDetallePedido(currentRow?.id)

              if (detalleData.length > 0) {
                const resp = await Swal.fire({
                  title: 'Advertencia',
                  html: `Enviar el <strong>Pedido #${currentRow?.id}</strong> a Operaciones?`,
                  icon: 'warning',
                  showDenyButton: true,
                  denyButtonText: 'No, cancelar',
                  confirmButtonText: 'Sí, enviar',
                  customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
                  reverseButtons: true,
                })

                if (resp.isConfirmed) {
                  await handleUpdateSalesOrderStatus(currentRow?.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA)
                  await handleUpdateSalesOrderStatus(currentRow?.id, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
                  const response = await handleCreateOutOrder(currentRow?.id)

                  toast.success(`Orden de egreso #${response.data.id} generada correctamente`)
                  navigate(routes.VENTAS_PEDIDOS)
                }
              } else {
                toast.warning(`El Pedido #${currentRow?.id} no tiene artículos`)
              }
            } catch (error) {
              // Nada
            }
          }
        })
      }

      if (
        [
          SalesOrderStatusI.BORRADOR,
          SalesOrderStatusI.NO_AUTORIZADO,
          SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA,
          SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO,
          SalesOrderStatusI.AUTORIZADO
        ].includes(currentRow.sales_order_status_id)
      ) {
        actions.push({
          label: "Cancelar",
          icon: "bi bi-trash text-danger",
          action: () => {
            setIdPedido(currentRow.id)
            setCancelarModal(true)
            setEspecial(false)
            setParcial(false)
            setAnticipada(false)
          }
        })
      }

      if ([SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA].includes(currentRow.sales_order_status_id)) {
        let allowedRoles = [RoleI.ADMIN, RoleI.CONTROL_RENTAS]

        if (currentUser.roles.some(r => allowedRoles.includes(r.id))) {
          actions.push({
            label: 'Autorizar rentabilidad',
            icon: 'bi bi-check-all text-success',
            action: async () => {
              await autorizarRentabilidadPedido(currentRow.id)

              if (
                currentRow.customer_supplier_id_autorizacion_credito == AutorizacionCreditoI.AUTORIZADO_SIEMPRE ||
                (
                  currentRow.customer_supplier_id_autorizacion_credito == AutorizacionCreditoI.AUTOMATICA_CONDICION_PAGO &&
                  currentRow.payment_condition_id == currentRow.customer_supplier_payment_condition_id
                )
              ) {
                await handleCreateOutOrder(currentRow.id)
                toast.success(`Crédito del Pedido #${currentRow.id} autorizado correctamente`)
              }

              await obtenerPedidos()
            }
          })

          actions.push({
            label: 'No autorizar',
            icon: 'bi bi-x-circle text-danger',
            action: async () => {
              await noAutorizarPedido(currentRow, true)
              await obtenerPedidos()
            }
          })
        }
      }

      if (
        [SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(currentRow.sales_order_status_id)
      ) {
        let allowedRoles = [RoleI.ADMIN, RoleI.CONTROL_CRED]

        if (currentUser.roles.some(r => allowedRoles.includes(r.id))) {
          if (currentRow?.customer_supplier_id_autorizacion_credito !== AutorizacionCreditoI.DESHABILITADO_CREDITOS) {
            actions.push({
              label: 'Autorizar crédito',
              icon: 'bi bi-check-all text-success',
              action: async () => {
                await autorizarCreditoPedido(currentRow.id)
                await obtenerPedidos()
              }
            })
          }

          actions.push({
            label: 'No autorizar',
            icon: 'bi bi-x-circle text-danger',
            action: async () => {
              await noAutorizarPedido(currentRow.id, false)
              await obtenerPedidos()
            }
          })
        }
      }

      if ([SalesOrderStatusI.AUTORIZADO].includes(currentRow.sales_order_status_id)) {
        actions.push({
          label: "Crear OE",
          icon: "bi bi-box-arrow-up-right text-success",
          action: async () => {
            await crearOrdenEgreso(currentRow.id, currentRow)
            await obtenerPedidos()
          }
        })
      }

      if (
        [
          SalesOrderStatusI.FALTA_STOCK,
          SalesOrderStatusI.RESERVA_PARCIAL,
          SalesOrderStatusI.EN_REVISION,
          SalesOrderStatusI.COORDINACION_ENTREGA,
          SalesOrderStatusI.PENDIENTE_PREPARACION,
        ].includes(currentRow.sales_order_status_id)
      ) {
        if (currentRow.sales_order_status_id !== SalesOrderStatusI.EN_REVISION) {
          actions.push({
            label: 'Crear OE parcial',
            icon: 'bi bi-box-arrow-up-right text-success',
            action: async () => {
              await crearOrdenEgresoParcial(currentRow.id, currentRow)
              await obtenerPedidos()
            }
          })
        }

        if (currentRow.ids_egreso_no_canceladas.split(',').length == 1) { // Cant. órdenes de egreso == 1
          actions.push({
            label: 'Cancelar',
            icon: 'bi bi-x-circle text-danger',
            action: () => {
              setIdPedido(currentRow.id)
              setEspecial(true)
              setParcial(false)
              setAnticipada(currentRow?.billing_type_id == BillingTypesI.ANTICIPADA)
              setCancelarModal(true)

              // Viejo:
              // await cancelacionEspecialPedido(currentRow.id, currentRow?.billing_type_id == BillingTypesI.ANTICIPADA)
              // await obtenerPedidos()
            }
          })
        }

        if (currentRow.ids_egreso_no_canceladas.split(',').length > 1) { // Cant. órdenes de egreso > 1
          actions.push({
            label: 'Cancelar parcialmente',
            icon: 'bi bi-x-circle text-danger',
            action: () => {
              setIdPedido(currentRow.id)
              setEspecial(false)
              setParcial(true)
              setAnticipada(currentRow?.billing_type_id == BillingTypesI.ANTICIPADA)
              setCancelarModal(true)

              // Viejo:
              // await cancelacionParcialPedido(currentRow.id, currentRow?.billing_type_id == BillingTypesI.ANTICIPADA)
              // await obtenerPedidos()
            }
          })
        }
      }

      if (
        currentUser.roles?.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE || r.id == RoleI.OPERACIONES_ADMIN) &&
        currentRow?.billing_type_id == BillingTypesI.ANTICIPADA &&
        ![
          SalesOrderStatusI.BORRADOR,
          SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA,
          SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO,
          SalesOrderStatusI.CANCELADO,
          SalesOrderStatusI.NO_AUTORIZADO,
        ].includes(currentRow.sales_order_status_id)
      ) {
        actions.push({
          label: 'Facturar',
          icon: 'bi bi-receipt text-primary',
          action: async () => {
            try {
              await facturaAnticipadaPedido(currentRow.id)
              await obtenerPedidos()
            } catch (error) {
              // Ya se encarga la función
            }
          }
        })
      }

      actions.push({
        label: 'Descargar',
        icon: 'bi bi-download text-success',
        action: async () => {
          try {
            const detalleData = await obtenerDetallePedido(currentRow.id)

            if (detalleData.length > 0) {
              await imprimirPedido(currentRow.id)
            } else {
              toast.error('El pedido no tiene artículos cargados')
            }
          } catch (error) {
            toast.error(error.message)
          } finally {
            await obtenerPedidos()
          }
        }
      })

      actions.push({
        label: "Clonar",
        icon: "bi bi-clipboard-check",
        action: async () => {
          await clonarPedido(currentRow.id)
          await obtenerPedidos()
        }
      })
    }

    return actions
  }
  /* Fin menu contextual */

  const cols = useMemo(() => {
    const c = [...columns]

    const hasActionsColumns = columns.some(c => c.id == 'actions')

    if (!hasActionsColumns) {
      c.push({
        Header: '',
        id: 'actions',
        label: '',
        className:'col-icons',
        accessor: (row) => (
          <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
        ),
        visible: true,
        notHide: true,
      })
    }

    return c
  }, [columns, handleContextMenu, threeDotsRef])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    obtenerPedidos()
  }, [obtenerPedidos])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Pedidos'}
        dropdownDisabled={loading}
        dropdownActions={[
          {
            label: "Pedido de Venta",
            href: routes.VENTAS_PEDIDOS_CREAR,
            icon: 'currency-dollar text-success',
          },
          {
            label: "Almacenamiento en depósito propio",
            href: routes.VENTAS_PEDIDOS_ALMACENAMIENTO_PROPIO,
            icon: 'bi bi-box text text-primary',
          },
          {
            label: "Pedido de egreso",
            href: routes.VENTAS_PEDIDOS_EGRESO,
            icon: 'bi bi-box-arrow-up text text-warning',
          },
        ].filter(Boolean)}
        filter={<Filter loading={loading} query={query} handleQuery={setQuery} />}
        filterColumns
        columns={cols}
        setColumns={setColumns}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }} />}

        <CustomTable
          columns={cols.filter(c => c.visible !== false)}
          data={pedidos || []}
          paginationOptions={paginationOptions}
          queryMD={query}
          setQueryMD={setQuery}
          handleContextMenu={handleContextMenu}
        />
      </TableContainer>

      <AuthModal show={modal} onClose={() => setModal(false)} salesOrder={currentRow} handleGetSalesOrders={obtenerPedidos} />

      <CancelarPedidoModal
        show={cancelarModal}
        onHide={async () => {
          setIdPedido(null)
          setEspecial(false)
          setParcial(false)
          setAnticipada(false)
          setCancelarModal(false)
          await obtenerPedidos()
        }}
        idPedido={idPedido}
        especial={especial}
        parcial={parcial}
        anticipada={anticipada}
      />

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default Pedidos