import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from "moment";

import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

const useGetColumns = () => {
  const COLUMNS = [
    {
      Header: <p className='m-0 p-0'>Nro.</p>,
      id: 'id',
      label: 'Nro.',
      accessor: (row) => (
        <Link to={`${row.order_type_id == 1 ? routes.PURCHASE_REQUEST_DETAIL : routes.INCOME_REQUEST_DETAIL}/${row.id}`}>
          {row.id}
        </Link>
      ),
      hasOrder: true,
      visible: true,
      notHide: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>Tipo</p>,
      id: 'order_type_id',
      label: 'Tipo',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>{row.order_type_label}</p>
      ),
      hasOrder: true,
      visible: true,
    },
    {
      Header: <p className='m-0 p-0'>Proveedor</p>,
      id: 'supplier_id',
      label: 'Proveedor',
      accessor: (row) => (
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.supplier_id}`}>
          [{row.supplier_id}] {row.supplier_name}
        </Link>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0'>Distribuidor</p>,
      id: 'distributor_id',
      label: 'Distribuidor',
      accessor: (row) => (
        <div>
          {row?.distributor_id
            ?
              <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.distributor_id}`}>
                [{row.distributor_id}] {row.distributor_name}
              </Link>
            : <i>Sin distribuidor</i>
          }
        </div>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0'>Condición</p>,
      id: 'payment_condition_label',
      label: 'Condición',
      accessor: (row) => row.payment_condition_label,
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>F. pago</p>,
      id: 'payment_estimated_in_date',
      label: 'F. pago',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.payment_estimated_in_date ? moment(row.payment_estimated_in_date).format("DD/MM/YYYY") : '-'}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>F. creación</p>,
      id: 'date',
      label: 'F. creación',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {moment(row.created_at).format("DD/MM/YYYY")}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>F. ingreso estimado</p>,
      id: 'estimated_in_date',
      label: 'F. ingreso estimado',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.estimated_in_date ? moment(row.estimated_in_date).format("DD/MM/YYYY") : '-'}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>F. ingreso</p>,
      id: 'in_order_created_at',
      label: 'F. ingreso',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.in_order_created_at ? moment(row.in_order_created_at).format("DD/MM/YYYY") : '-'}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>Artículos</p>,
      id: 'product_qty',
      label: 'Artículos',
      accessor: (row) => (
        <div className='text-center'>
          {row.product_qty}

          {row?.detail &&
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-${row.id}`}>
                  <div>
                    {row.detail.split(';').map((item, index) => (
                      <div key={index}>{item.trim()}</div>
                    ))}
                  </div>
                </Tooltip>
              }
            >
              <i className='bi bi-info-circle text-warning ms-2' />
            </OverlayTrigger>
          }
        </div>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-end'>Total</p>,
      id: 'total',
      label: 'Total',
      accessor: (row) => (
        <p className='m-0 p-0 text-end'>
          {currencyFormat.format(row.total)}
        </p>
      ),
      visible: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>Estado</p>,
      id: 'order_status_id',
      label: 'Estado',
      className:'col-status',
      accessor: (row) =>(
        <span className={`badge ${row.label_color} w-100 justify-content-center`}>
          {row.order_status_label}
        </span>
      ),
      visible: true,
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns