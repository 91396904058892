import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'
import { TransactionalDocumentTypeI } from 'interfaces'
import { currencyFormat } from 'utilities'

/** 
const colorUnidadNegocio = (business_unit_name) => {
  switch (business_unit_name) {
    case 'Farma':
      return 'text-primary'
    case 'Shop':
      return 'text-warning'
    default:
      return 'text-muted'
  }
}*/

const types = {
  [TransactionalDocumentTypeI.FACTURA]: 'Factura',
  [TransactionalDocumentTypeI.NOTA_DEBITO]: 'Nota de débito',
  [TransactionalDocumentTypeI.NOTA_CREDITO]: 'Nota de crédito',
  [TransactionalDocumentTypeI.REMITO]: 'Remito',
}

const COLUMNS = [
  /**{
  {
    Header: <p className='m-0 p-0 text-center'>Uni. de negocio</p>,
    id: 'business_unit_id',
    label: 'Uni. de negocio',
    accessor: (row) => (
      <p className={`m-0 p-0 ${colorUnidadNegocio(row?.business_unit_name)} fw-bolder ${row?.business_unit_name ? '' : 'fst-italic'} text-center`}>
        {row?.business_unit_name ? row.business_unit_name : 'Sin unidad de negocio'}
      </p>
    ),
    hasOrder: true
  },*/
  {
    Header: <p className='m-0 p-0'>ID</p>,
    id: 'id_real',
    accessor: (row) => (
      <p className='m-0 p-0'>
        {row.id}
      </p>
    ),
    hasOrder: true
  },
  {
    Header: <p className='m-0 p-0 text-center'>Fecha</p>,
    id: 'fecha',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row?.fecha ? moment(row.fecha).format("DD/MM/YYYY") : '-'}
      </p>
    ),
    hasOrder: true
  },
  {
    Header: <p className='m-0 p-0'>Tipo</p>,
    id: 'tipo_comprobante',
    accessor: (row) => (
      <p className='m-0 p-0'>
        {row.tipo_comprobante}
      </p>
    )
  },
  {
    Header: <p className='m-0 p-0 text-center'>Número</p>,
    id: 'nro_completo',
    accessor: (row) => (
      <p className='m-0 p-0 text-center'>
        {row?.nro_completo ? row.nro_completo : '-'}
      </p>
    ),
    hasOrder: true
  },
  {
    Header: <p className='m-0 p-0'>Cliente / Proveedor</p>,
    id: 'razon_social',
    accessor: (row) => (
      <div>
        {row.razon_social?.split(';')?.length > 1
          ?
            <OverlayTrigger
              placement='right'
              overlay={
                <TooltipBS id={`${row.id_comprobante}-tooltip-clientes`}>
                  <div>
                    {row.razon_social.split(';').map((item, index) => (
                      <div key={index} className='text-start'>{item.trim()}</div>
                    ))}
                  </div>
                </TooltipBS>
              }
            >
              <i className='bi bi-info-circle text-warning ms-2' />
            </OverlayTrigger>
          : <strong>{row.razon_social}</strong>
        }
      </div>
    ),
    hasOrder: true
  },
]

export {
  COLUMNS,
}