import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'

const DeshabilitadoCreditosModal = ({ show, onHide, pedido }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Deshabilitado créditos</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className='fs-5'>El cliente <strong>{pedido?.customer_supplier_business_name}</strong> tiene la opción "Deshabilitado créditos" en la pestaña "Créditos" de su perfil.</p>
        <p className='fs-5'>Si quiere continuar con los pedidos de este cliente, deberá actualizar ese dato.</p>
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className='col-12 text-end'>
            <Button type='button' onClick={onHide}>
              <i className='bi bi-slash-circle me-1' />
              Cerrar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DeshabilitadoCreditosModal