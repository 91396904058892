import { currencyFormat } from 'utilities'

export const COLUMNS = [
  {
    Header: <div className="text-center">ID</div>,
    id: 'id',
    label: 'ID',
    accessor: (row) => (
      <div className='text-center'>
        {row.id}
      </div>
    ),
    hasOrder: true,
    visible: true,
    notHide: true,
  },
  {
    Header: <div className="text-center">SKU</div>,
    id: 'sku',
    label: 'SKU',
    accessor: (row) => (
      <div className='text-center'>
        {row.sku}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className="">Nombre</div>,
    id: 'name',
    label: 'Nombre',
    accessor: (row) => (
      <div className=''>
        {row.name}
      </div>
    ),
    hasOrder: true,
    visible: true,
  },
  {
    Header: <div className="">Droga</div>,
    id: 'droga',
    label: 'Droga',
    accessor: (row) => (
      <div className=''>
        {row.droga}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Laboratorio</div>,
    id: 'laboratorio',
    label: 'Laboratorio',
    accessor: (row) => (
      <div className='text-center'>
        {row.laboratorio}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Trazable</div>,
    id: 'trazable',
    label: 'Trazable',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.trazable) ? 'success' : 'danger'}`}>
          {Boolean(row?.trazable) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Frío</div>,
    id: 'Frio',
    label: 'Frío',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.Frio) ? 'success' : 'danger'}`}>
          {Boolean(row?.Frio) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>TA</div>,
    id: 'TA',
    label: 'TA',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row?.TA) ? 'success' : 'danger'}`}>
          {Boolean(row?.TA) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Stock disp.</div>,
    id: 'Disponible',
    label: 'Stock disp.',
    accessor: (row) => (
      <div className='text-center'>
        {row.Disponible}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>PVP</div>,
    id: 'pvp',
    label: 'PVP',
    accessor: (row) => (
      <div className='text-center'>
        {row?.pvp ? currencyFormat.format(row.pvp) : '-'}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Costo de lista unitario</div>,
    id: 'CostoLista',
    label: 'Costo de lista unitario',
    accessor: (row) => (
      <div className='text-center'>
        {row?.CostoLista ? currencyFormat.format(row.CostoLista) : '-'}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Lote</div>,
    id: 'Lote',
    label: 'Lote',
    accessor: (row) => (
      <div className='text-center'>
        {row.Lote}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Vencimiento</div>,
    id: 'ProxVencimiento',
    label: 'Vencimiento',
    accessor: (row) => (
      <div className='text-center'>
        {row.ProxVencimiento}
      </div>
    ),
    visible: true,
  },
  {
    Header: <div className='text-center'>Días vencimiento</div>,
    id: 'DiasProxVencimiento',
    label: 'Días vencimiento',
    accessor: (row) => (
      <div className='text-center'>
        {row.DiasProxVencimiento}
      </div>
    ),
    visible: true,
  },
]