import { useTransactionalDocuments, useVatConditions } from 'hooks'
import { useState, useCallback, useEffect } from 'react'
import { Button, Form, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import Select from 'react-select';
import { toast } from 'react-toastify';

const vatOptions = [
    { value: 1, label: 'No Gravado', percent: 0 },
    { value: 2, label: 'Exento', percent: 0 },
    { value: 3, label: '0%', percent: 0 },
    { value: 9, label: '2.5%', percent: 2.5 },
    { value: 8, label: '5%', percent: 5 },
    { value: 4, label: '10.5%', percent: 10.5 },
    { value: 5, label: '21%', percent: 21 },
    { value: 6, label: '27%', percent: 27 }
  ]

const customStyles = {
    control:  (base, state) => ({
      ...base,
      boxShadow: "none",
      '&:hover': { border: '1px solid #bbbbbb' },
      border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
      minHeight: '38px',
      height: '38px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Arial, sans-serif',
      fontSize: '1.1rem',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: 'Arial, sans-serif',
      fontSize: '1.1rem',
      fontWeight: '500'
    }),
    multiValue: (base) => ({
        ...base,
        fontSize: '1.1rem',
    }),
  };

const initialValues={
    codigoMtx:null, 
    alicuotaIVA:null,
    monto:"", 
    observaciones:"",
    motivo:"",
    errors: {},
};

const FinantialCreditNoteModal = ({ show, onHide, currentRow }) => {
    const [codigosGenericos, setCodigosGenericos] = useState("")
    const [observaciones, setObservaciones] = useState("")
    const [motivo, setMotivo] = useState("")
    const [fceAnulacion, setFceAnulacion] = useState(false)
    const {handleGetCodigosGenericosNC} = useTransactionalDocuments()
    const [rows, setRows] = useState([initialValues]);
  
    const addRow = () => {
      setRows([
        ...rows,
        { id: rows.length, codigoMtx: null, descripcion: "", monto: "", alicuotaIVA: null, errors: {} },
      ]);
    };

    const fetchAuxData = async()=>{
        let _resp = await handleGetCodigosGenericosNC();
        if(_resp?.data?.result){
            const _tipos = _resp.data.result.map(t=>({...t, value:t.id, label:t.descripcion}))
            setCodigosGenericos(_tipos)
        }
    }
    
    const handleInputChange = (id, field, value) => {
        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, [field]: value, errors: { ...row.errors, [field]: false } } : row
        );
        setRows(updatedRows);
    };
    const handleDeleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id);
        setRows(updatedRows);
    };

    const validateRows = () => {
        let isValid = true;
    
        const validatedRows = rows.map((row) => {
            const errors = {};
            if (!row.codigoMtx) errors.codigoMtx = true;
            if (!row.descripcion) errors.descripcion = true;
            if (!row.monto) errors.monto = true;
            if (!row.alicuotaIVA) errors.alicuotaIVA = true;
            if (Object.keys(errors).length > 0) isValid = false;
        
            return { ...row, errors };
        });

        setRows(validatedRows); 
        
        return isValid;
    };

    const onSave=()=>{

        if (!validateRows()) {
            return;
        }

        const _sumaNC = rows.reduce((acc, item)=>(+acc + +item.monto), 0)
        if(_sumaNC > +currentRow.total){
            toast.error("La suma de los ítems no puede ser mayor al monto de la factura")
            return
        }

        const data = {
            observaciones: observaciones,
            motivo: motivo,
            fceAnulacion:fceAnulacion,
            items:rows
        }

        onHide(data)
    }

    useEffect(()=>{
        fetchAuxData();
        setRows([initialValues])
        setFceAnulacion(false);
        setObservaciones("");
        setMotivo("");
        console.log(currentRow)
    }, [show])

    return (
        <Modal show={show} onHide={onHide} backdrop='static' centered size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Generar nota de crédito financiera</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row mb-8">
                <div className="col text-end">
                    <Button variant='secondary' onClick={addRow}>Agregar concepto</Button>
                </div>
            </div>
            <div  style={{minHeight:"200px"}}>
                <Table striped bordered hover>
                <thead>
                    <tr>
                    {/* <th className=' text-muted'>Línea</th> */}
                    <th className=' text-muted' style={{width:"30%"}}>Concepto</th>
                    <th className="text-center text-muted">Descripción</th>
                    <th className="text-center text-muted" style={{width:"10%"}}>Monto</th>
                    <th className=' text-muted' style={{width:"15%"}}>Alícuota IVA</th>
                    <th style={{width:"5%"}}></th>
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, index) => (
                    <tr key={index}>
                        {/* <td className="align-middle">{row.id}</td> */}
                        <td>
                            <Select
                                options={codigosGenericos}
                                value={row.codigoMtx}
                                onChange={(selectedOption) =>
                                    handleInputChange(row.id, "codigoMtx", selectedOption)
                                }                            
                                styles={customStyles}
                                className={row.errors.codigoMtx?"border border-danger":""}
                            />
                        </td>

                        <td className='align-middle text-start'>
                            <input 
                                className={`form-control ${row.errors.descripcion? ' border border-danger' : ''}`}
                                type="text"
                                value={row.descripcion}
                                onChange={(e) => handleInputChange(row.id, "descripcion", e.target.value)} />
                        </td>

                        <td className='align-middle text-end'>
                            <input className={`form-control ps-2 pe-2  ${row.errors.monto? ' border border-danger' : ''}`}
                                type="text"
                                value={row.monto}
                                onChange={(ev) => {
                                    const value = ev.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                    handleInputChange(row.id, "monto", value)}
                                } />
                        </td>

                        <td >
                            <Select
                                options={vatOptions}
                                value={ row.alicuotaIVA }
                                onChange={(selectedOption) =>
                                    handleInputChange(row.id, "alicuotaIVA", selectedOption)
                                }                            
                                styles={customStyles}
                                className={` ${row.errors.alicuotaIVA? ' border border-danger' : ''}`}
                            />
                        </td>
                        <td className="text-center align-middle">
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Eliminar concepto</Tooltip>}>
                                <span>
                                    <i className="bi bi-trash text-danger fs-3" style={{ cursor: 'pointer' }} onClick={() => handleDeleteRow(row.id)} />
                                </span>
                            </OverlayTrigger>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </Table>
            </div>
            <div className='row mb-8'>
                <div className="col">
                    <label htmlFor='notes' className='form-label'>Observaciones</label>
                    <input type='text' id='notes' className={`form-control ` } rows={3} 
                        value={observaciones} 
                        onChange={(e=> {
                            setObservaciones(e.target.value);
                        })} />
                </div>
            </div>
            <div className='row mb-8'>
                <div className="col">
                    <label htmlFor='notes' className='form-label'>Motivo (uso interno)</label>
                    <input type='text' id='notes' className={`form-control ` } rows={3} 
                        value={motivo} 
                        onChange={(e=> {
                            setMotivo(e.target.value);
                        })} />
                </div>
            </div>
            <div className='row'>
                <div className="d-flex">                        
                    <Form.Group>
                        <Form.Check
                            type="switch"
                            className="text-dark"
                            checked={fceAnulacion}
                            onChange={()=>setFceAnulacion(!fceAnulacion)}
                            />
                    </Form.Group>
                    <label className='form-label pb-2'>Nota de crédito responde a un rechazo de la FCEA? (Sólo válido en factura de crédito electrónica)</label> 
                </div>
            </div> 

        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={()=>onHide(null)}>Cancelar</Button>
            <Button variant='primary' onClick={onSave}>Generar NC</Button>
        </Modal.Footer>
        </Modal>
    )
}

export default FinantialCreditNoteModal