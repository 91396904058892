import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, usePatients } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'
import { Filter } from './partials'

import routes from 'app/routing/routes'
import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const Patients = () => {
  const { currentUser } = useAuth()

  const { loading, handleGetPatients, handleUpdatePatientStatus } = usePatients()
  const [data, setData] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getPatients = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      if (currentUser && currentUser?.patients) {
        const { patients } = currentUser

        params = {
          ...params,
          id: patients?.id || '',
          nombre: patients?.nombre || '',
          documento: patients?.documento || '',
          is_enabled: patients?.is_enabled || '',
          sin_documento: patients?.sin_documento || 0,
          sin_nro_afiliado: patients?.sin_nro_afiliado || 0,
          cliente_asociado: patients?.cliente_asociado || '',
        }
      }

      const response = await handleGetPatients('', '', params)
      setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetPatients, query, currentUser])

  /* Menu contextual */
  const navigate = useNavigate()
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: `Ver`,
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.PATIENTS}/${currentRow?.id}`)
    })

    actions.push({
      label: `${Boolean(currentRow?.is_enabled) ? 'Deshabilitar' : 'Habilitar'}`,
      icon: `${Boolean(currentRow?.is_enabled) ? 'bi-x-circle text-danger' : 'bi-check2-circle text-success'}`,
      action: () => enableDisablePatient(currentRow)
    })

    return actions
  }

  const enableDisablePatient = async (patient) => {
    try {
      let status = Boolean(patient.is_enabled) ? 'deshabilitar' : 'habilitar'
      let msg = Boolean(patient.is_enabled) ? 'deshabilitado' : 'habilitado'

      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere ${status} al paciente #${patient.id} - ${patient.first_name} ${patient.last_name}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, ${status}`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const data = {
          is_enabled: !Boolean(patient.is_enabled),
        }

        await handleUpdatePatientStatus(patient.id, data)
        toast(`Paciente ${msg} correctamente`, { type: Boolean(data.is_enabled) ? 'success' : 'info' })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getPatients()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getPatients()
  }, [getPatients])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Pacientes'}
        goTo={
          currentUser.roles.some(r => [RoleI.ADMIN, RoleI.VENTAS_GTE, RoleI.VENTAS_SUPERVISOR, RoleI.VENTAS].includes(r.id)) &&
          routes.PATIENTS_NEW
        }
        filter={<Filter handleData={setData} query={query} handleQuery={setQuery} handleTotalCount={setTotalCount} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default Patients