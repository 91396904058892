export interface DocumentTypeI {
  id: number | string;
  description: string;
  icon: string;
}

export interface GetDocumentTypesI {
  result: DocumentTypeI[];
}

export interface GetDocumentTypeI {
  result: DocumentTypeI;
}

export type CreateDocumentTypeI = Omit<DocumentTypeI, 'id'>;
export type UpdateDocumentTypeI = Omit<DocumentTypeI, 'id'>;
export type DeleteDocumentTypeI = DocumentTypeI['id'];

export enum DocumentTypeEntitiesI {
  ClienteProveedor = 'Cliente / Proveedor',
  PedidoVenta = 'Pedido de venta',
  DireccionEntrega = 'Dirección de entrega'
}

export const documentTypeEntitiesSelect = [
  { value: '', label: 'Seleccione una entidad' },
  { value: 'ClienteProveedor', label: 'Cliente / Proveedor' },
  { value: 'PedidoVenta', label: 'Pedido de venta' },
  { value: 'DireccionEntrega', label: 'Dirección de entrega' },
]